<template>
  <div>
    <h4 class="page-title">展示详情</h4>
    <a-row class="rows">
      <!-- <a-col class="left required" span="3">移动端</a-col> -->
      <a-col class="left required" span="3">课程方图</a-col>
      <a-col class="right" span="8">
        <!-- <div class="tips">用于商城列表、证书课程、收藏列表、学习(我的课程)</div> -->
        <div>
          <ImageUpload 
            bucket="ueditor/product/" 
            :value="formData.mobileListPicture" 
            :isCropp="true" 
            :autoCropWidth="750"
            :autoCropHeight="750"
            fileScale="1:1"
            filePixel="750*750"
            dialogWidth="850px"
            :fileSize=2
            :isShowTip="true"
            @input="handleBanner($event,'mobileListPicture')" />
        </div>
      </a-col>
      <!-- <a-col class="left required" span="3">移动端</a-col>
      <a-col class="right" span="8">
        <div class="tips">用于商品详情、精选中套餐包含商品列表</div>
        <div>
          <span class="ant-upload-picture-card-wrapper" v-if="formData.mobileDetailPicture">
            <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img style="max-height:100px;max-width:100px;" :src="formData.mobileDetailPicture" alt="avatar" />
                  <div class="icon">
                    <a :href="formData.mobileDetailPicture" target="_blank" rel="noopener noreferrer">
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon @click="formData.mobileDetailPicture = ''" type="delete" class="i" />
                  </div>
                </div>
              </span>
            </div>
          </span>
          <a-upload v-else
            name="mobileDetailPicture"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <div>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </div>
        <div class="Tips">建议尺寸：750*530px，最多传一张</div>
      </a-col> -->
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">课程长图</a-col>
      <!-- <a-col class="left required" span="3">移动端</a-col> -->
      <a-col class="right" span="8">
        <!-- <div class="tips">用于首页重磅课程、热门课程、免费课程</div> -->
        <div>
          <ImageUpload 
            bucket="ueditor/product/" 
            :value="formData.mobileChiefPicture" 
            :isCropp="true" 
            :autoCropWidth="450"
            :autoCropHeight="258"
            fileScale="2:1.5"
            filePixel="450*258"
            dialogWidth="550px"
            :fileSize=2
            :isShowTip="true"
            @input="handleBanner($event,'mobileChiefPicture')" />
        </div>
      </a-col>
    </a-row>
    <!-- <a-row class="rows">
      <a-col class="left required" span="3">PC端</a-col>
      <a-col class="right" span="8">
        <div class="tips">用于商城列表图、首页列表图、收藏列表图、重磅课程</div>
        <div>
          <span class="ant-upload-picture-card-wrapper" v-if="formData.pcListPicture">
            <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img style="max-height:100px;max-width:100px;" :src="formData.pcListPicture" alt="avatar" />
                  <div class="icon">
                    <a :href="formData.pcListPicture" target="_blank" rel="noopener noreferrer">
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon @click="formData.pcListPicture = ''" type="delete" class="i" />
                  </div>
                </div>
              </span>
            </div>
          </span>
          <a-upload v-else
            name="pcListPicture"
            list-type="picture-card"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <div>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </div>
        <div class="Tips">建议尺寸360*313，最多传1张</div>
      </a-col>
      <a-col class="left required" span="3">PC端</a-col>
      <a-col class="right" span="8">
        <div class="tips">用于商品详情、热门推荐</div>
        <div>
          <span class="ant-upload-picture-card-wrapper" v-if="formData.pcDetailPicture">
            <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img style="max-height:100px;max-width:100px;" :src="formData.pcDetailPicture" alt="avatar" />
                  <div class="icon">
                    <a :href="formData.pcDetailPicture" target="_blank" rel="noopener noreferrer">
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon @click="formData.pcDetailPicture = ''" type="delete" class="i" />
                  </div>
                </div>
              </span>
            </div>
          </span>
          <a-upload v-else
            name="pcDetailPicture"
            list-type="picture-card"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <div>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </div>
        <div class="Tips">建议尺寸750*750，最多传1张</div>
      </a-col>
    </a-row> -->
    <a-row class="rows">
      <a-col class="left" span="3">二维码</a-col>
      <a-col class="right" span="8">
        <div class="tips">用于购买商品后添加客服</div>
        <div>
          <ImageUpload 
            bucket="ueditor/product/" 
            :value="formData.customerCode" 
            :isCropp="true" 
            :autoCropWidth="160"
            :autoCropHeight="160"
            fileScale="1:1"
            filePixel="160*160"
            :fileSize=2
            :isShowTip="true"
            @input="handleBanner($event,'customerCode')" />
        </div>
      </a-col>
      <a-col class="right" span="8">
        <div class="tips">客服介绍</div>
        <a-textarea placeholder="请输入客服介绍" v-model="formData.customerIntroduce" />
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">相关证书</a-col>
      <a-col class="right" span="20">
        <div v-for="(item,index) in formData.courseRelevantCertificate" :key="'courseRelevantCertificate'+index" style="overflow: hidden;">
          <div class="ant-upload ant-upload-select ant-upload-select-picture-card" v-if="item.picture">
            <span role="button" tabindex="0" class="ant-upload">
              <div class="pictures">
                <img style="max-height:100px;max-width:100px;" :src="item.picture" alt="avatar" />
                <div class="icon">
                  <a :href="item.picture" target="_blank" rel="noopener noreferrer">
                    <a-icon type="eye" class="i" />
                  </a>
                  <a-icon @click="item.picture = ''" type="delete" class="i" />
                </div>
              </div>
            </span>
          </div>
          <a-upload v-else
            :name="'courseRelevantCertificate-'+index"
            list-type="picture-card"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
            style="width:auto;"
          >
            <div>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
          <a-input style="width:240px;vertical-align: top;" v-model="item.name" placeholder="请输入证书名称"/>
          <span style="vertical-align: top;line-height: 32px;margin-left: 5px;font-size: 14px;">
            <!-- <a v-if="index==0" @click="addCourseRelevantCertificate()">添加+</a>
            <a v-else @click="removeCourseRelevantCertificate(item.uid)">删除</a> -->
            <a  @click="removeCourseRelevantCertificate(item.uid)">删除</a>
          </span>
        </div>
        <a-button type="primary" @click="addCourseRelevantCertificate()">添加+</a-button>
        <div class="Tips" style="margin-top:10px">可传多张证书图片，考试证书/课程证书</div>
      </a-col>
    </a-row>
    <a-row class="rows">
        <a-col class="left required" span="3">移动端课程详情</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor1"
            :initContent="initMobileDetail"
            @input="getContent($event,'mobileDetail')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="3">PC端课程详情</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor2"
            :initContent="initPcDetail"
            @input="getContent($event,'pcDetail')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="3">移动端学习指导</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor3"
            :initContent="initMobileStudentInstructions"
            @input="getContent($event,'mobileStudentInstructions')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="3">PC端学习指导</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor4"
            :initContent="initPcStudentInstructions"
            @input="getContent($event,'pcStudentInstructions')"
          />
        </a-col>
      </a-row>
    <a-row class="rows">
      <a-col span="20" offset="4">
        <a-button
          v-if="$route.query.status == 1"
          @click="onStorageClick"
          :loading="loadingBtn"
          type="primary"
          style="margin-right: 30px"
          >暂存</a-button
        >
        <a-button
          @click="onSaveClick"
          :loading="loadingBtn"
          >保存</a-button
        >
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '../CourseDetails/config.vue'
import { uuid } from '@/unit/fun.js'
export default {
  mixins: [ config ],
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loadingBtn:false,
      uploadLoading: false,
      initMobileDetail: '',
      initPcDetail: '',
      initMobileStudentInstructions: '',
      initPcStudentInstructions: '',
      formData: {
        courseId:0, // 课程id
        // mobileDetailPicture: '', // 移动端详情图
        mobileListPicture: '', // 移动端列表图
        mobileChiefPicture: '', // 移动端商品图
        // pcListPicture: '', // PC端列表图
        // pcDetailPicture: '', // PC端详情图
        customerCode: '', // 客服二维码
        customerIntroduce: '', // 客服介绍
        courseRelevantCertificate: [], // 相关证书,示例值([ "name", "picture" ])	
        mobileDetail: '', // 移动端课程详情
        pcDetail: '', // PC端课程详情
        mobileStudentInstructions: '', // 移动端学员须知
        pcStudentInstructions: '', // PC端学员须知
        saveType: 1, // 保存类型(0-未填写，1-暂存，2-提交)
      },
      saveType: false, // 提交状态  ture: 提交过 false:未提交过
    };
  },
  // 事件处理器
  methods: {
    handleBanner(url ,name){
      this.$set(this.formData, name, url)
    },
    // 文件上传前钩子上传图片
    beforeUpload(file){
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传
    customRequest(fileData) {
      this.uploadLoading = true
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          if(fileData.filename.indexOf('courseRelevantCertificate')!=-1){
            // 相关证书图片上传
            const index = fileData.filename.split('-')[1]
            let arr = this.formData.courseRelevantCertificate
            arr[index].picture = url
            this.$set(this.formData,'courseRelevantCertificate',arr)
          }else{
            this.$set(this.formData, fileData.filename, url)
          }
        } else {
          this.$message.error("上传失败");
        }
        this.uploadLoading = false
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 富文本内容有变化时触发
    getContent(txt,name) {
      this.$set(this.formData, name, txt);
    },

    // 暂存校验
    fun_StorageVerific(){
      const data = this.formData
      // 验证相关证书
      if(data.courseRelevantCertificate.length){
        if (data.courseRelevantCertificate.some((val) => val.name== '' || val.picture== '' )) {
          return Promise.reject({msg:'请完善相关证书的图片或证书名称信息'})
        }
      }

      // 移动端列表图
      if(!data.mobileListPicture){
        return Promise.reject({msg:'请上传课程方图'})
      }

      return Promise.resolve()
    },

    // 检查Form表单并返回object
    fun_verific(){
      const data = this.formData

      // 移动端列表图
      if(!data.mobileListPicture){
        return Promise.reject({msg:'请上传课程方图'})
      }

      // 移动端详情图
      // if(!data.mobileDetailPicture){
      //   return Promise.reject({msg:'请上传移动端详情图'})
      // }

      // 移动端商品图
      if(!data.mobileChiefPicture){
        return Promise.reject({msg:'请上传课程长图'})
      }

      // PC端列表图
      // if(!data.pcListPicture){
      //   return Promise.reject({msg:'请上传PC端列表图'})
      // }

      // PC端详情图
      // if(!data.pcDetailPicture){
      //   return Promise.reject({msg:'请上传PC端详情图'})
      // }

      // 验证相关证书
      if(data.courseRelevantCertificate.length){
        if (data.courseRelevantCertificate.some((val) => val.name== '' || val.picture== '' )) {
          return Promise.reject({msg:'请完善相关证书的图片或证书名称信息'})
        }
      }

      // 移动端课程详情
      if(!data.mobileDetail){
        return Promise.reject({msg:'请输入移动端课程详情'})
      }

      // PC端课程详情
      if(!data.pcDetail){
        return Promise.reject({msg:'请输入PC端课程详情'})
      }

      return Promise.resolve()
    },

    // 暂存
    onStorageClick(){
      this.loadingBtn = true
      Promise.all([
        this.fun_StorageVerific()
      ]).then(res=>{
        this.formData.saveType = 1 // 保存类型 暂存
        this.onSubmitClick()
      }).catch(err=>{
        if(err){
          this.$message.warning(err.msg)
        }else{
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    // 提交
    onSaveClick(){
      this.loadingBtn = true
      Promise.all([
        this.fun_verific()
      ]).then(res=>{
        this.formData.saveType = 2 // 保存类型 提交
        this.onSubmitClick()
      }).catch(err=>{
        if(err){
          this.$message.warning(err.msg)
        }else{
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    onSubmitClick(){
      this.loadingBtn = true
      let url = ''
      if(this.saveType){
        url = "/hxclass-management/course/updateCourseShowInfo"
      } else {
        url = "/hxclass-management/course/saveCourseShowInfo"
      }
      this.$message.loading({ content: "课程信息正在存储...", duration: 0 });
      this.$ajax({
        url: url,
        method: "POST",
        params: this.formData,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success( (this.formData.saveType == 1 ? '暂存' : '保存') + '成功!')
          this.loadingBtn = false
          this.saveType = true
          // 向父页面更新目录修改状态
          this.$parent.onChildInit('showInfo', this.formData.saveType);
        } else {
          this.loadingBtn = false
          this.$message.error(res.message);
        }
      });
    },

    // 查询详情
    getFormDetail(){
      this.loadingBtn = true
      this.$message.loading({ content: "正在查询详情...", duration: 0 });
      this.$ajax({
        url: "/hxclass-management/course/getCourseShowInfo",
        params: {
          courseId: this.formData.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data.courseRelevantCertificate.length){
            res.data.courseRelevantCertificate.forEach((item) => {
              item.uid = uuid();
            });
          }
          // 移动端课程详情
          this.initMobileDetail = res.data.mobileDetail
          // PC端课程详情
          this.initPcDetail = res.data.pcDetail
          // 移动端学员须知
          this.initMobileStudentInstructions = res.data.mobileStudentInstructions
          // PC端学员须知
          this.initPcStudentInstructions = res.data.pcStudentInstructions
          this.formData = res.data;
          this.saveType = res.data.saveType ? true : false
          
          this.$message.success("查询成功!");
        } else {
          this.$message.error(res.message);
        }
        this.loadingBtn = false
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.formData.courseId = Number(this.$route.query.id)
      this.getFormDetail();
    });
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./style.less";
</style>
