var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("作业管理")]),_vm._v(" / 学员配置 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['exam:homework:assignuser']),expression:"['exam:homework:assignuser']"}],staticClass:"all_boder_btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.isModalShow=true}}},[_vm._v("新建")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('h4',[_vm._v("已发布人员"),_c('span',[_vm._v("（当前已发布"+_vm._s(_vm.total)+"人）")])]),_c('a-table',{staticClass:"table-template",attrs:{"loading":_vm.loading,"rowKey":function (item){ return item.userId; },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total:_vm.total,
      current:_vm.queryParams.pageNum,  
      defaultPageSize:_vm.queryParams.pageSize, 
      showSizeChanger: true,
      showTotal: function(total, range){
      return ("共" + total + "条")}}},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + i + 1)+" ")])]}}])})],1),_c('a-modal',{attrs:{"align":"center","width":"1000px","title":"选择学员"},model:{value:(_vm.isModalShow),callback:function ($$v) {_vm.isModalShow=$$v},expression:"isModalShow"}},[_c('div',{staticClass:"all_content_box"},[_c('div',{staticClass:"flex"},[_c('a-input',{staticClass:"input",attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.studentParams.userName),callback:function ($$v) {_vm.$set(_vm.studentParams, "userName", $$v)},expression:"studentParams.userName"}}),_c('a-input',{staticClass:"input",attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.studentParams.mobile),callback:function ($$v) {_vm.$set(_vm.studentParams, "mobile", $$v)},expression:"studentParams.mobile"}}),_c('a-input',{staticClass:"input",attrs:{"placeholder":"请输入班期"},model:{value:(_vm.studentParams.className),callback:function ($$v) {_vm.$set(_vm.studentParams, "className", $$v)},expression:"studentParams.className"}}),_c('a-range-picker',{staticClass:"input",attrs:{"valueFormat":"YYYY-MM-DD","placeholder":['考试开始日期','考试结束日期']},model:{value:(_vm.pickerData),callback:function ($$v) {_vm.pickerData=$$v},expression:"pickerData"}}),_c('a-button',{staticClass:"all_boder_btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.studentParams.pageNum = 1, _vm.getNoStudentList()}}},[_vm._v("搜索")])],1),_c('a-table',{staticClass:"table-template",attrs:{"loading":_vm.sutdentLoad,"rowKey":function (item){ return item.userId; },"columns":_vm.columns1,"data-source":_vm.UnStudentList,"row-selection":{
        selectedRowKeys: _vm.selectedSignUpRowKeys,
        onChange: _vm.onSelect,
      },"pagination":{
        total:_vm.UnStudentTotal,
        current:_vm.studentParams.pageNum,  
        defaultPageSize:_vm.studentParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return ("共" + total + "条")}}},on:{"change":_vm.onUnStudentPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.studentParams.pageNum - 1) * _vm.studentParams.pageSize + i + 1)+" ")])]}},{key:"className",fn:function(text){return [_c('span',[_vm._v(_vm._s(text?text:'-'))])]}},{key:"examDate",fn:function(text){return [_c('span',[_vm._v(_vm._s(text?text:'-'))])]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSignUpRowKeys.length),expression:"selectedSignUpRowKeys.length"}],staticClass:"grey"},[_vm._v(" 已选中学员"+_vm._s(_vm.selectedSignUpRowKeys.length)+"人： "),_vm._l((_vm.selectSutdenList),function(item,index){return _c('span',{key:item.userId + 'key'},[_vm._v(_vm._s(item.name)),_c('a-icon',{staticClass:"icon",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.onDel(item.userId, index)}}})],1)})],2)],1),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.sutdentLoad},on:{"click":function($event){return _vm.onAdd()}}},[_vm._v("添加")]),_c('a-button',{on:{"click":function($event){_vm.isModalShow=false}}},[_vm._v("取消")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }