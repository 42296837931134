import { render, staticRenderFns } from "./NewProduct.vue?vue&type=template&id=2c3b47bc&scoped=true&"
import script from "./NewProduct.vue?vue&type=script&lang=js&"
export * from "./NewProduct.vue?vue&type=script&lang=js&"
import style0 from "./NewProduct.vue?vue&type=style&index=0&id=2c3b47bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c3b47bc",
  null
  
)

export default component.exports