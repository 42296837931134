<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 机构认证审核 </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="paramsObj.orgName" allowClear />
        <a-button
          v-hasPermi="['org:list:query']"
          type="primary"
          class="btn"
          :loading="loading"
          @click="search()"
          >搜索</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs :defaultActiveKey="defaultActiveKey" @change="tabSearch" >
        <a-tab-pane :key="0" tab="全部"></a-tab-pane>
        <a-tab-pane :key="1" tab="未审核"></a-tab-pane>
        <a-tab-pane :key="2" tab="已审核"></a-tab-pane>
        <a-tab-pane :key="3" tab="已驳回"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :rowKey="(item) => item.orgId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <!-- 地区 -->
        <template slot="address" slot-scope="item">
          {{item.orgProvice + '-' + item.orgCity + '-' + item.orgArea}}
        </template>

        <!-- 审核状态 -->
        <template slot="auditStatus" slot-scope="item">
        <span :class="item == 1 ? '' : item == 2 ? 'state_color_green' : 'state_color_red'">
          {{ item == 1 ? "未审核" : item == 2 ? "已审核" : item == 3 ? "已驳回" : '' }}
        </span>
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="blueText">
            <span v-if="record.auditStatus == 1" @click="changitem(record)" v-hasPermi="['org:audit:updatestatus']">审核</span>
            <span v-if="record.auditStatus != 1" @click="changitem(record)" v-hasPermi="['org:detail:query']">详情</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "orgName",
  },
  {
    title: "邮箱",
    align: "center",
    dataIndex: "email",
  },

  {
    title: "地区",
    align: "center",
    scopedSlots: { customRender: "address" },
  },
  {
    title: " 联系人",
    align: "center",
    dataIndex: "contactName",
  },
  {
    title: "联系电话",
    align: "center",
    dataIndex: "contactTell",
  },
  {
    title: " 联系人职位",
    align: "center",
    dataIndex: "contactPost",
  },
  {
    title: "申请认证时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "审核状态",
    align: "center",
    dataIndex: "auditStatus",
    scopedSlots: { customRender: "auditStatus" },
  },

  {
    title: "操作",
    align: "center",
    width: "220px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import { mapMutations } from 'vuex';
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0,
      pageNum:1,
      pageSize:10,
      paramsObj: {
        auditStatus: undefined,
        orgName: '',
      },
      defaultActiveKey: undefined, // tabs默认值
    };
  },
  // 事件处理器
  methods: {
    ...mapMutations(['setSearchConditions']),
    // tab切换
    tabSearch(e) {
      this.paramsObj.auditStatus = e || undefined;
      this.search();
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getdataList();
    },
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.setVuex()
    },
    // 获取数据
    getdataList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/organization/info/certification/audit/List",
        method: "get",
        params: this.paramsObj,
      }).then((res) => {
        this.loading = false;
        if(res.code == 200 && res.success){
          this.total = res.data.length;
          this.tableData = res.data;
        } else {
          this.total = 0;
          this.tableData = [];
        }
      });
      this.setVuex()
    },
    setVuex(){
      // vueX存储查询条件
      const obj = {
        modulesName:'OrganAudit',
        params: {
          orgName: this.paramsObj.orgName,
          auditStatus: this.paramsObj.auditStatus,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        }
      }
      this.setSearchConditions(obj);
    },
    // 跳转详情
    changitem(e) {
      this.$router.push({ path: "/admin/Mechanism/agencyDetails", query: { userId: e.userId } })
    },
    // 审核确认
    onCheck(){

    },
    // 通过或驳回
    seloperation() {
      this.$ajax({
        url: "/hxclass-management/user-certif/manage/personal/audit-reject",
        method: "put",
        params: {
          // auditStatus: this.examine.examineValue,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getdataList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    const Name = 'historyOrganAudit'
    let obj = this.$store.state.historyTokeep[Name]
    if(obj){
      this.pageNum = obj.pageNum
      this.pageSize = obj.pageSize
      this.paramsObj.auditStatus = obj.auditStatus || undefined
      this.defaultActiveKey = obj.auditStatus || undefined
      this.paramsObj.orgName = obj.orgName
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // 是否是运营首页进入
    if(this.$route.query.type == 'OperationsHome') {
      this.defaultActiveKey = 1;
      this.paramsObj.auditStatus = 1;
    }
    this.getdataList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

</style>
