// 基础配置
export default [
  // 偏好设置
  {
    path: 'Preferences',
    component: () => import('@/views/admin/BasicConfig/Preferences.vue')
  },
  // 工作单位
  {
    path: 'WorkUnit',
    component: () => import('@/views/admin/BasicConfig/WorkUnit.vue')
  },
  // 地址管理
  {
    path: 'AddressManage',
    component: () => import('@/views/admin/BasicConfig/AddressManage.vue')
  },
  // 运费模版
  {
    path: 'FreightTemplate',
    component: () => import('@/views/admin/BasicConfig/FreightTemplate.vue')
  },
  // 运费模版详情
  {
    path: 'FreightTemplateDetails',
    component: () => import('@/views/admin/BasicConfig/FreightTemplateDetails.vue')
  },
  // 发票配置
  {
    path: 'InvoiceConfig',
    component: () => import('@/views/admin/BasicConfig/InvoiceConfig.vue')
  },
  // 职务/职称
  {
    path: 'OfficialCapacity',
    component: () => import('@/views/admin/BasicConfig/OfficialCapacity.vue')
  },
  // 成绩设置
  {
    path: 'ScoreSetting',
    component: () => import('@/views/admin/BasicConfig/ScoreSetting.vue')
  },
  // 证书模版
  {
    path: 'CertificateTemplate',
    component: () => import('@/views/admin/BasicConfig/CertificateTemplate.vue')
  },
  // 模版配置
  {
    path: 'CertificateTemplateList',
    component: () => import('@/views/admin/BasicConfig/CertificateTemplateList.vue')
  },
  // 模版配置详情
  {
    path: 'CertificateTemplateDetail',
    component: () => import('@/views/admin/BasicConfig/CertificateTemplateDetail.vue')
  },
  // 证书配置
  {
    path: 'CertificateConfigList',
    component: () => import('@/views/admin/BasicConfig/CertificateConfigList.vue')
  },
  // 证书配置详情
  {
    path: 'CertificateConfigDetail',
    component: () => import('@/views/admin/BasicConfig/CertificateConfigDetail.vue')
  },
  // 人工生成
  {
    path: 'CertificateHandTemplateList',
    component: () => import('@/views/admin/BasicConfig/CertificateHandTemplateList.vue')
  },
  // 证书物流
  {
    path: 'CertificateLogisticsTemplateList',
    component: () => import('@/views/admin/BasicConfig/CertificateLogisticsTemplateList.vue')
  },
  // 课程证书查询(new)
  {
    path: 'CertificateManagerList',
    component: () => import('@/views/admin/BasicConfig/CertificateManagerList.vue')
    // component: () => import('@/views/admin/BasicConfig/LearningProofManagerList.vue')
  },
  // 学习证明查询(new)  暂时不用，目前与课程证书查询(new)中的学习证明列表功能一致，如果需要拆分的话在使用
  {
    path: 'LearningProofManagerList',
    component: () => import('@/views/admin/BasicConfig/LearningProofManagerList.vue')
  },
  // 康复机构管理 - 经办机构
  {
    path: 'Agency',
    component: () => import('@/views/admin/BasicConfig/Agency.vue')
  },
  // 康复机构管理 - 定点服务机构
  {
    path: 'DesignatedService',
    component: () => import('@/views/admin/BasicConfig/DesignatedService.vue')
  },
  // 教师管理
  {
    path: 'TeacherManagement',
    component: () => import('@/views/admin/BasicConfig/TeacherManagement.vue')
  },
  // 教师管理详情
  {
    path: 'TeacherManagementDetails',
    component: () => import('@/views/admin/BasicConfig/TeacherManagementDetails.vue')
  },
  // 参数设置
  {
    path: 'ParameterSettings',
    component: () => import('@/views/admin/BasicConfig/ParameterSettings.vue')
  },
  // 手工生成证书管理-> 修改
  {
    path: 'CertificateHandTemplateDetail',
    component: () => import('@/views/admin/BasicConfig/CertificateHandTemplateDetail.vue')
  },
  // 手工生成证书管理-> 新增人员
  {
    path: 'CertificateHandTemplateAdd',
    component: () => import('@/views/admin/BasicConfig/CertificateHandTemplateAdd.vue')
  },
// 证书管理-> 重新生成（详情）
{
  path: 'CertificateManagerDetail',
  component: () => import('@/views/admin/BasicConfig/CertificateManagerDetail.vue')
},
  // 证书管理-> 新增人员
  {
    path: 'CertificateManagerAdd',
    component: () => import('@/views/admin/BasicConfig/CertificateManagerAdd.vue')
  },
]