<script>
import { uuid } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      preferenceList: [], // 关联偏好数组
      getInstructorName: '', // 查询授课老师
      InstructorList: [], // 授课老师数组
      checkedInstructorList: [], // 选中的授课老师
      CategorySelectList: [], // 课程分类数据
      viewCondition1: false,
      viewCondition2: false,
      examCertifTempList: [], // 考证证书模板
      curriculumCertifTempList: [], // 课程证书模板
      productCategoryList: [], // 商品分类
      productTagList: [], // 商品标签
      setRelevantProductList: [], // 关联商品集合
      setRelevantGiftList: [], // 关联赠品集合
      SelectCourseList: [], // 选择的课程
      addressValue: [],  // 地址回显
      FixedPlaceTrainingList: [], // 培训场次固定
      NonFixedPlaceTrainingList: [], // 培训场次固定
    }
  },
  // 事件处理器
  methods: {
    // 隐藏导入反馈组件
    modelhandleCancel() {
      this.importVisible = false;
    },

    // 是否在终端展示
    changeSetOriginal(e) {
      this.formData.setOriginal = (e.target.checked ? '1' : '0')
      // console.log(this.formData.setOriginal)
    },

    // 商品是否在终端展示
    changeDisplayFlag(e) {
      this.formData.displayFlag = (e.target.checked ? '1' : '0')
    },

    // 商品是否可重复购买
    changeBuyLimitNum(e) {
      this.formData.buyLimitNum = (e.target.checked ? '1' : '0')
    },

    // 获取省市区编码
    FunProvince(data) {
      this.addressValue = data
    },

    // 查询关联偏好
    getPreference() {
      this.$ajax({
        url: '/hxclass-management/like/all'
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.preferenceList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 查询授课老师
    getInstructor(e) {
      this.$ajax({
        url: '/hxclass-management/teacher/select',
        parame: {
          // name: e || ''
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.InstructorList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 改写授课老师搜索条件
    filterInstructorList(inputValue, option) {
      return option.key.indexOf(inputValue) != -1
    },

    // 查询课程分类
    getCategorySelect() {
      this.$ajax({
        url: '/hxclass-management/course/category/select',
      }).then(res => {
        if (res.code == 200 && res.success) {
          res.data.forEach(item => {
            if (item.childList.length > 0) {
              item.disabled = true
            } else {
              delete item.disabled
            }
          })
          this.CategorySelectList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 根据老师id转换老师姓名
    funTeacherIdList(arr) {
      let array = []
      let data = []
      arr.forEach(item => {
        data.push(item.id)
      })
      arr = data
      this.InstructorList.forEach(item => {
        if (arr.indexOf(item.teacherId) != -1) {
          array.push(item.name)
        }
      })
      return array.join('、') || '-'
    },

    // 弹窗选择回调
    onModal(obj) {
      if (obj.type == 'newDirectory') { // 新建目录
        this.modalNewDirectory(obj.data)
      } else if (obj.type == 'uploadVideo') { // 上传视频
        this.modalUploadVideo(obj.data)
      } else if (obj.type == 'newChildrenDirectory') { // 新建子目录
        this.modalNewChildrenDirectory(obj.data)
      } else if (obj.type == 'SectionTest') { // 小节自测目录
        this.modalSectionTest(obj.data)
      } else if (obj.type == 'SelectChapter') { // 免费试看添加章节
        this.modalSelectChapter(obj.data)
      } else if (obj.type == 'SelectChapterValidDay') { // 免费试看添加章节-课程有效期
        this.modalSelectChapterVD(obj.data)
      } else if (obj.type == 'SelectiveExam') { // 选择考试
        this.modalSelectiveExam(obj.data)
      } else if (obj.type == 'SelectiveCertificate') { // 选择证书
        this.modalSelectiveCertificate(obj.data)
      } else if (obj.type == 'SelectOpenClass') { // 设置开班时间
        this.modalSelectOpenClass(obj.data)
      } else if (obj.type == 'SelectCourse') { // 选择课程
        this.modalSelectCourse(obj.data)
      } else if (obj.type == 'FixedPlaceTraining') { // 新增场次
        this.modalFixedPlaceTraining(obj.data)
      } else if (obj.type == 'NonFixedPlaceTraining') { // 新增场次-不同地点
        // this.modalNonFixedPlaceTraining(obj.data)
        this.modalFixedPlaceTraining(obj.data)
      }
    },

    // 更新课程目录上下移动状态
    funMoveCatalogueState() {
      function fors(arr) {
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (index == 0) {
            item.first = 1
          } else {
            item.first = 0
          }
          if (arr.length - 1 == index) {
            item.last = 1
          } else {
            item.last = 0
          }
        })
        return arr
      }
      let data = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = data
    },

    // 删除课程视频
    removeVideo(row) {
      this.$set(row, 'videoFileId', null)
      this.$set(row, 'duration', 0)
      this.$set(row, 'teacherIdList', [])
    },

    // 删除课程数据
    removeCatalogue(row, uid) {
      // console.log(row)
      // 新建章节
      if (!row.sectionId) {
        function fors(arr) {
          let data = arr.filter(item => {
            return item.uid != uid
          })
          data.forEach(item => {
            if (item.childList && item.childList.length > 0) {
              item.childList = fors(item.childList)
            }
          })
          return data
        }
        this.formData.courseCatalogueList = fors(this.formData.courseCatalogueList)
        return
      }
      // 有子级
      if (row.childList && row.childList.length > 0) {
        this.$message.warning('当前章节下包含子目录，请先删除该章节下所有的子目录')
        return
      } else {
        // 校验章节是否可删除
        // this.getIsDeleteCatalogue(row.sectionId)
        this.$ajax({
          url: "/hxclass-management/course/manage/is-possible-to-del/" + row.sectionId,
          method: "get",
        }).then((res) => {
          // console.log(res)
          // 可以删除
          if (res.data) {
            // 要提交的数据
            function fors(arr) {
              let data = JSON.parse(JSON.stringify(arr))
              data.forEach(item => {
                if (item.uid == uid) {
                  item.deleted = 1
                }
                if (item.childList && item.childList.length > 0) {
                  item.childList = fors(item.childList)
                }
              })
              return data
            }
            let courseCatalogueList = fors(JSON.parse(JSON.stringify(this.formData.courseCatalogueList)))
            this.formData.courseCatalogueList = JSON.parse(JSON.stringify(courseCatalogueList))
          }
          // 不可以删除
          else {
            this.$message.warning('当前章节已有学员进行答题、视频学习或者关联证书无法进行删除')
          }
        });
      }

      this.funMoveCatalogueState() // 更新课程目录上下移动状态
    },

    // 课程类别变化
    changeCategoryId(e) {
      let offline = false
      let courseFree = 0
      function fors(arr) {
        arr.forEach(item => {
          if (item.childList && item.childList.length > 0) {
            fors(item.childList)
          }
          if (item.courseCategoryId == e) {
            offline = item.offline
            courseFree = item.free
          }
        })
      }
      fors(this.CategorySelectList)
      if (courseFree == 1) {
        this.formData.couponPrice = 0
      }
      this.courseFree = courseFree
      this.offline = offline == 1
    },

    // 课程目录上下移动
    moveCatalogue(uid, move) {
      function fors(arr) {
        let i = null
        let data = null
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (item.uid == uid) {
            i = index
          }
        })
        if (i != null) {
          if (move == 1) {
            data = arr[i - 1]
            arr[i - 1] = arr[i]
          } else if (move == 2) {
            data = arr[i + 1]
            arr[i + 1] = arr[i]
          }
          arr[i] = data
          i = null
        }
        return arr
      }
      let data = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = data
      this.funMoveCatalogueState() // 更新课程目录上下移动状态
    },

    // 观看条件更新
    changeViewCondition() {
      if (this.viewCondition1 && this.viewCondition2) { // 二者皆可
        this.formData.watchLimitType = 3
      } else if (this.viewCondition1) { // 必须通过以下课程的考试
        this.formData.watchLimitType = 1
      } else if (this.viewCondition2) { // 拥有以下课程的考试证书
        this.formData.watchLimitType = 2
      } else {
        this.formData.watchLimitType = null
      }
    },

    // 删除开班时间
    removeSelectOpenClass(uid) {

      this.$confirm({
        content: '删除开班时间后，课程有效期计算节点将同步取消按开班时间计算，确认删除该班期吗？',
        onOk: () => {
          this.$ajax({
            method: 'PUT',
            url: "/hxclass-management/course/delOpenTime/" + uid,
          }).then(res => {
            if (res.code == 200 && res.success) {
              this.$message.success('删除成功');
              this.onSaveClick();
            } else {
              this.$message.warning(res.message);
            }
          })
        }
      })

      // // 已删除班期需暂存
      // let openTimeItem = this.formData.courseOpenTimeList.filter(item => {
      //   return item.uid == uid
      // })
      // openTimeItem[0].operation = 3
      // this.delSelectOpenClassList.push(openTimeItem[0])

      // if (this.formData.courseOpenTimeList && this.formData.courseOpenTimeList.length <= 1 && this.formData.validStartType == '2') {
      //   this.$confirm({
      //     content: '删除开班时间后，课程有效期计算节点将同步取消按开班时间计算',
      //     onOk: () => {
      //       this.formData.courseOpenTimeList = this.formData.courseOpenTimeList.filter(item => {
      //         return item.uid != uid
      //       })
      //       this.formData.validStartType = '0'
      //     }
      //   })
      // } else {
      //   this.formData.courseOpenTimeList = this.formData.courseOpenTimeList.filter(item => {
      //     return item.uid != uid
      //   })
      // }
    },

    // 删除小节自测
    removeSectionTest(uid) {
      if (this.returnSectionTestLength() <= 1 && this.formData.validStartType == '1') {
        this.$confirm({
          content: '删除小节自测后，课程有效期计算节点将同步取消按小节自测开始计算',
          onOk: () => {
            function fors(arr) {
              let i = null
              arr.forEach((item, index) => {
                if (item.childList && item.childList.length > 0) {
                  item.childList = fors(item.childList)
                }
                if (item.uid == uid) {
                  i = index
                }
              })
              if (i != null) {
                arr[i].questionIdList = []
                arr[i].questionList = []
                i = null
              }
              return arr
            }
            let list = fors(this.formData.courseCatalogueList)
            this.formData.courseCatalogueList = []
            this.formData.courseCatalogueList = list
            // this.funMoveCatalogueState() // 更新课程目录上下移动状态
            this.formData.validStartType = '0'
            this.SectionTestLength = this.returnSectionTestLength()
          }
        })
      } else {
        function fors(arr) {
          let i = null
          arr.forEach((item, index) => {
            if (item.childList && item.childList.length > 0) {
              item.childList = fors(item.childList)
            }
            if (item.uid == uid) {
              i = index
            }
          })
          if (i != null) {
            arr[i].questionIdList = []
            arr[i].questionList = []
            i = null
          }
          return arr
        }
        let list = fors(this.formData.courseCatalogueList)
        this.formData.courseCatalogueList = []
        this.formData.courseCatalogueList = list
        this.SectionTestLength = this.returnSectionTestLength()
        // this.funMoveCatalogueState() // 更新课程目录上下移动状态
      }
    },

    // 导入基础信息
    ImportBasicInfo(e) {
      this.$ajax({
        url: '/hxclass-management/course/import-course-basic',
        method: 'post',
        headers: [
          { type: 'file' }
        ],
        params: {
          file: e.file,
          name: 'file'
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          const data = res.data
          let obj = JSON.parse(JSON.stringify(this.formData))
          // 课程名称
          obj.courseName = data.courseName
          // 课程简介
          obj.introduction = data.introduction
          // 客服介绍
          obj.customerIntroduce = data.customerIntroduce
          // 总课时
          obj.classHour = data.classHour
          // 必修视频数量
          obj.compulsClassHour = data.compulsClassHour;
          // 移动端课程详情
          this.initMobileDetail = data.mobileDetail
          // PC端课程详情
          this.initPcDetail = data.pcDetail
          // 移动端学员须知
          this.initMobileStudentInstructions = data.mobileStudentInstructions
          // PC端学员须知
          this.initPcStudentInstructions = data.pcStudentInstructions
          // 移动端报名指南
          // this.initMobileRegistrationGuide = data.mobileRegistrationGuide
          // PC端报名指南
          // this.initPcRegistrationGuide = data.pcRegistrationGuide
          // 相关证书
          obj.courseRelevantCertificate = [{
            uid: uuid(),
            name: '',
            picture: ''
          }]
          // 授课老师
          obj.courseTeacherIdList = []
          // 课程偏好
          obj.courseLikeIdList = []

          this.formData = obj
          this.$message.success('导入成功')
        } else {
          this.$message.error('数据导入失败，请重新导入')
        }
      }).catch(err => {
        console.error(err)
        this.$message.error('数据导入失败，请重新导入')
      })
    },

    // 导入题目模板
    ImportTopicTemplate(e, uid) {
      this.$ajax({
        url: '/hxclass-management/exam/library/import',
        method: 'post',
        headers: [
          { type: 'file' }
        ],
        params: {
          file: e.file,
        }
      }).then(res => {
        if (res.code == 200 && res.success && res.data.successList.length > 0) {
          let arr = new Array()
          res.data.successList.forEach(res => {
            let obj = new Object()
            obj.typeText = (res.type == '单选题' ? '单选' : res.type == '多选题' ? '多选' : res.type == '判断题' ? '判断' : '')
            obj.type = (res.type == '单选题' ? 1 : res.type == '多选题' ? 2 : res.type == '判断题' ? 3 : 0)
            obj.title = res.title
            obj.answer = res.answer
            obj.category = 3
            obj.isFixed = (res.isFixed == '不可打乱选项' ? 1 : res.isFixed == '可打乱选项' ? 0 : '')
            obj.difficulty = (res.difficulty == '简单' ? 1 : res.difficulty == '一般' ? 2 : res.difficulty == '困难' ? 3 : 0)
            obj.optionList = []
            obj.Import = 1
            let ABC = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            ABC.forEach(i => {
              if (res[i]) {
                obj.optionList.push({
                  name: res[i],
                  isCorrect: res.correct.indexOf(i) != -1 ? 1 : 0
                })
              }
            })
            arr.push(obj)
          })
          function fors(e) {
            e.forEach((item, index) => {
              if (item.childList && item.childList.length > 0) {
                item.childList = fors(item.childList)
              }
              if (item.uid == uid) {
                item.questionList = arr
              }
            })
            return e
          }
          let list = fors(this.formData.courseCatalogueList)
          this.formData.courseCatalogueList = []
          this.formData.courseCatalogueList = list
          this.FunSelfTest()
          this.SectionTestLength = this.returnSectionTestLength()
          this.$message.success('导入成功')
        } else {
          this.$message.error('数据导入失败，请重新导入')
        }
      }).catch(err => {
        console.error(err)
        this.$message.error('数据导入失败，请重新导入')
      })
    },

    // 导入目录模板
    ImportCatalogTemplate(e) {
      this.$ajax({
        url: '/hxclass-management/course/import-course-section',
        method: 'post',
        headers: [
          { type: 'file' }
        ],
        params: {
          file: e.file,
        }
      }).then(res => {
        if (res.code == 200 && res.success && res.data.length > 0) {
          function funObj(item) {
            return {
              uid: uuid(),
              sectionName: item.sectionName,
              videoUrl: null,
              videoFileId: item.videoFileId,
              teacherIdList: item.teacherIdList || [],
              duration: 0,
              questionIdList: item.questionIdList,
              questionList: item.questionList,
              first: item.first,
              last: item.last,
            }
          }
          function fors(arr) {
            let arrs = new Array()
            arr.forEach(item => {
              let obj = {
                ...funObj(item),
                childList: item.childList && item.childList.length > 0 ? fors(item.childList) : []
              }
              arrs.push(obj)
            })
            return arrs
          }
          this.formData.courseCatalogueList = fors(res.data)
          this.$message.success('导入成功')
        } else {
          this.$message.error('数据导入失败，请重新导入')
        }
      }).catch(err => {
        // console.log(err)
        this.$message.error('数据导入失败，请重新导入')
      })
    },

    // 导入功能
    fileAction(e) {
      if (e.filename == 'BasicInfo') {
        // 导入基础信息
        this.ImportBasicInfo(e)
      } else if (e.filename.indexOf('TopicTemplate') != -1) {
        let uid = e.filename.split(',')[1]
        // 导入题目模板
        this.ImportTopicTemplate(e, uid)
      } else if (e.filename.indexOf('CatalogTemplate') != -1) {
        // 导入目录模板
        this.ImportCatalogTemplate(e)
      }
    },

    // 添加章节-打开章节弹窗
    onAddChapter() {
      this.$refs.Modal.open({
        title: '添加章节',
        width: '400px',
        type: 'SelectChapter',
        list: this.formData.courseCatalogueList,
        checked: this.getTryChapter()
      })
    },

    // 添加章节-课程有效期选择
    onAddChapterValidDay() {
      // console.log(this.formData.validStartSectionId)
      this.$refs.Modal.open({
        title: '选择章节',
        width: '400px',
        type: 'SelectChapterValidDay',
        list: this.formData.courseCatalogueList,
        checked: [this.formData.validStartSectionId]
      })
    },

    // 查询可免费试看章节
    getTryChapter() {
      let list = []
      function fors(arr) {
        if (!arr) {
          return
        }
        arr.forEach((item) => {
          if (item.childList && item.childList.length > 0) {
            fors(item.childList)
          }
          if (item.freeTrial == 1) {
            list.push(item)
          }
        })
      }
      fors(this.formData.courseCatalogueList)
      return list
    },

    // 删除可免费试看章节
    removeTryChapter(data) {
      function fors(arr) {
        let i = null
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (item.uid == data.uid) {
            i = index
          }
        })
        if (i != null) {
          arr[i].freeTrial = 0
          i = null
        }
        return arr
      }
      let list = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = list
    },

    // 添加课程证书获得条件
    addCourseCertificate() {
      this.formData.courseCertificateList.push({
        uid: uuid(),
        certifId: '',
        courseCertificateId: '',
        courseId: '',
        sectionId: undefined
      })
    },

    // 筛选课程视频
    funFilterVideo(list) {
      function fors(arr) {
        arr.forEach((item) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (!!!item.videoFileId) {
            item.disabled = true
          } else {
            delete item.disabled
          }
        })
        return arr
      }
      return fors(list)
    },

    // 查询证书模板下拉框
    getCertificateTemplate() {
      this.$ajax({
        url: '/hxclass-management/certif-temp/select/option',
        params: {
          // type: 1
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.examCertifTempList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 查询课程证书模板下拉框
    getCurriculumCertifTemplate() {
      this.$ajax({
        url: '/hxclass-management/certif-temp/select/option',
        params: {
          // type: 2
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.curriculumCertifTempList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 删除课程证书
    removeCourseCertificate(e) {
      if (e.uid) {
        this.formData.courseCertificateList = this.formData.courseCertificateList.filter(item => {
          return item.uid != e.uid
        })
      } else if (e.courseCertificateId) {
        this.formData.courseCertificateList = this.formData.courseCertificateList.filter(item => {
          return item.courseCertificateId != e.courseCertificateId
        })
      }
    },

    // 查询商品分类
    getProductCategory() {
      this.$ajax({
        url: '/hxclass-management/product-category/manage/list'
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.productCategoryList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 查询商品标签
    // // 查看子项
    // getProductTags() {
    //   this.$ajax({
    //     url: '/hxclass-management/dict/get_by_p',
    //     params: {
    //       p: 'product_tags'
    //     }
    //   }).then(res => {
    //     if (res.code == 200 && res.success) {
    //       this.productTagList = res.data;
    //       let arr = []
    //       this.productTagList.forEach((item) => {
    //         arr.push({
    //           tagId: Number(item.dictCode),
    //           tagName: item.dictValue
    //         });
    //       });
    //       this.productTagList = arr;
    //     } else {
    //       this.$message.error(res.message)
    //     }
    //   })
    // },

    // 删除证书/考试
    removeCourseWatchLimitList(id, type) {
      this.formData.courseWatchLimitList = this.formData.courseWatchLimitList.filter(item => {
        return !(item.dataId == id && item.type == type)
      })
    },

    // 查询观看条件对应的数据
    getCourseWatchLimitList(arr, type) {
      if (!arr) {
        return [];
      }
      return arr.filter(item => {
        return item.type == type
      })
    },

    // 新增课程延期
    addCourseDelayList() {
      this.formData.courseDelayList.push({
        uid: uuid(),
        afterIsSame: '0',
        days: '',
        examNumber: '',
        price: '',
        sort: ''
      })
    },

    // 删除课程延期
    removeCourseDelayList(id) {
      this.formData.courseDelayList = this.formData.courseDelayList.filter(item => {
        return item.uid != id
      })
    },

    // 课程有效期选择
    changeAfterIsSame(e, item) {
      item.afterIsSame = e.target.checked ? '1' : '0'
    },

    // 课程有效期选择
    changeTermValidity(e) {
      if (e.target.checked) {
        this.formData.validDayType = '2'
      } else {
        this.formData.validDayType = '1'
      }
    },

    // 弹窗回调 - 新建目录
    modalNewDirectory(data) {
      if (!!data.uid) { // 编辑
        function fors(arr) {
          arr.forEach((item) => {
            if (item.childList && item.childList.length > 0) {
              item.childList = fors(item.childList)
            }
            if (item.uid == data.uid) {
              item.sectionName = data.name
            }
          })
          return arr
        }
        this.formData.courseCatalogueList = fors(this.formData.courseCatalogueList)
      } else { // 新增
        let uid = uuid() // 生成一段唯一标识id
        let obj = {
          uid: uid,
          sectionName: data.name, // 章节名称
          videoFileId: null, // 视频播放地址
          teacherIdList: [], // 授课老师的id
          duration: 0, // 视频时长
          questionList: [], // 小节自测集合
          questionIdList: [], // 小节自测id集合
          childList: [], // 子集集合
        }
        this.formData.courseCatalogueList.push(obj)
        this.funMoveCatalogueState() // 更新课程目录上下移动状态
      }
    },

    // 弹窗回调 - 新建子目录
    modalNewChildrenDirectory(data) {
      let uid = uuid() // 生成一段唯一标识id
      let obj = {
        uid: uid,
        sectionName: data.name, // 章节名称
        videoFileId: null, // 视频播放地址
        teacherIdList: [], // 授课老师的id
        duration: 0, // 视频时长
        questionList: [], // 小节自测集合
        questionIdList: [], // 小节自测id集合
        childList: [], // 子集集合
      }
      function fors(arr) {
        arr.forEach(item => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (item.uid == data.uid) {
            item.childList.push(obj)
          }
        })
        return arr
      }
      let list = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = list
      this.funMoveCatalogueState() // 更新课程目录上下移动状态
    },

    // 弹窗回调 - 视频操作
    modalUploadVideo(data) {
      function fors(arr) {
        let i = null
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (item.uid == data.uid) {
            i = index
          }
        })
        if (i != null) {
          arr[i].videoFileId = data.videoFileId
          arr[i].duration = data.videoDuration
          let arrs = []
          data.checked.forEach(item => {
            arrs.push({
              id: item
            })
          })
          arr[i].teacherIdList = arrs
          i = null
        }
        return arr
      }
      let list = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = list
      // this.funMoveCatalogueState() // 更新课程目录上下移动状态
    },

    // 时间转换 - 秒转时分秒
    formatSeconds(value) {
      if (!value || value == '-') return '00:00'
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

      let res = '';
      if (h !== '00') res += `${h}:`;
      res += `${m}:`;
      res += `${s}`;
      return res;
    },

    // 弹窗回调 - 小节自测
    modalSectionTest(data) {
      function fors(arr) {
        let i = null
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (item.uid == data.uid) {
            i = index
          }
        })
        if (i != null) {
          arr[i].questionList = data.checked2
          arr[i].questionIdList = data.checked
          i = null
        }
        return arr
      }
      let list = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = list
      this.FunSelfTest()
      this.SectionTestLength = this.returnSectionTestLength()
      // this.funMoveCatalogueState() // 更新课程目录上下移动状态
    },

    // 章节目录判断是否存在小节自测
    returnSectionTestLength() {
      let i = 0
      function fors(arr) {
        if (!arr) {
          return
        }
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            fors(item.childList)
          }
          if (item.questionList && item.questionList.length > 0) {
            i++
          }
        })
        return arr
      }
      fors(this.formData.courseCatalogueList)
      return i
    },

    // 弹窗回调 - 添加章节
    modalSelectChapter(data) {
      function fors(arr) {
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList)
          }
          if (data.checked.indexOf(item.uid) != -1) {
            item.freeTrial = 1
          }
        })
        return arr
      }
      let list = fors(this.formData.courseCatalogueList)
      this.formData.courseCatalogueList = []
      this.formData.courseCatalogueList = list
    },

    // 弹窗回调 - 选择章节
    modalSelectChapterVD(data) {
      this.formData.validStartSectionId = data.checkedVD[0]
      this.validStartSectionName = data.name
    },

    // 弹窗回调 - 选择考试
    modalSelectiveExam(data) {
      this.$ajax({
        url: '/hxclass-management/exam/config/batch/get',
        params: {
          configId: data.checked.join(',')
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          let arr = []
          res.data.forEach(item => {
            arr.push({ type: 1, dataId: item.configId, name: item.name, courseWatchLimitId: null })
          })
          this.formData.courseWatchLimitList = arr
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 弹窗回调 - 选择证书
    modalSelectiveCertificate(data) {
      this.$ajax({
        url: '/hxclass-management/certif-temp/names',
        params: {
          tempIds: data.checked.join(',')
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          let arr = []
          res.data.forEach(item => {
            arr.push({ type: 2, dataId: item.tempId, name: item.name, courseWatchLimitId: null })
          })
          this.formData.courseWatchLimitList = arr
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 弹窗回调 - 设置开班时间
    modalSelectOpenClass(data) {
      // if (!data.name) {
      //   this.$message.warning('请填写班级名称')
      //   return
      // } else if (data.checked.length == 0) {
      //   this.$message.warning('请选择开班时间')
      //   return
      // } else if (data.total == 0) {
      //   this.$message.warning('请填写班级名额')
      //   return
      // }
      let params = {
        className: data.name,
        startTime: data.checked[0],
        endTime: data.checked[1],
        number: data.total,
        courseId: data.courseId,
        type: 1
      };
      if (data.editItem && data.editItem.openTimeId) {
        params.openTimeId = data.editItem.openTimeId;
      }

      this.$ajax({
        url: '/hxclass-management/course/insertOrUpdateOpenTime',
        method: "POST",
        params: params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success((params.openTimeId ? '新增' : '编辑') + '成功!')
          this.onSaveClick();
          // this.getFormDetail();
        } else {
          this.$message.error(res.message);
        }
      });

      // // 添加数据
      // let list = JSON.parse(JSON.stringify(this.formData.courseOpenTimeList))
      // if (!!data.uid) {
      //   list.forEach(item => {
      //     if (item.uid == data.uid) {
      //       item.className = data.name
      //       item.startTime = data.checked[0]
      //       item.endTime = data.checked[1]
      //       item.number = data.total
      //     }
      //   })
      // } else {
      //   let obj = {
      //     uid: uuid(), // 生成一段唯一标识id
      //     className: data.name,
      //     startTime: data.checked[0],
      //     endTime: data.checked[1],
      //     number: data.total
      //   }
      //   list.push(obj)
      // }

      // // 数据排序
      // list.sort((a, b) => {
      //   return this.$moment(a.startTime).diff(b.startTime)
      // })

      // // 数据赋值
      // this.formData.courseOpenTimeList = list
    },

    // 弹窗回调 - 选择课程
    modalSelectCourse(data) {
      this.SelectCourseList = data.checked
    },

    // 弹窗回调 - 新增场次
    modalFixedPlaceTraining(data) {
      // console.log('-------', JSON.stringify(data), this.formData.offlineSameAddr);

      let params = {
        className: data.name,
        address: data.videoFileId,
        startTime: data.checked[0],
        endTime: data.checked[1],
        number: data.total,
        courseId: data.courseId,
        type: 2
      };
      if (data.editItem && data.editItem.openTimeId) {
        params.openTimeId = data.editItem.openTimeId;
      }
      if (this.formData.offlineSameAddr == '1') { // 同一地点
        if (!this.addressValue[0]) {
          return Promise.reject({ msg: '请选择培训场次地点' })
        } else if (!this.ProvinceAddress) {
          return Promise.reject({ msg: '请输入培训场次详细地址' })
        }
        //  else if (this.FixedPlaceTrainingList && !this.FixedPlaceTrainingList.length) {
        //   return Promise.reject({ msg: '请添加培训场次' })
        // } 
        else {
          params.province = this.addressValue[0]
          params.city = this.addressValue[1]
          params.district = this.addressValue[2]
          params.address = this.ProvinceAddress
        }
      } else {
        // if (this.NonFixedPlaceTrainingList && !this.NonFixedPlaceTrainingList.length) {
        //   return Promise.reject({ msg: '请添加培训场次' })
        // }
        params.province = data.addressValue[0]
        params.city = data.addressValue[1]
        params.district = data.addressValue[2]
      }
      // return
      this.$ajax({
        url: '/hxclass-management/course/insertOrUpdateOpenTime',
        method: "POST",
        params: params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success((params.openTimeId ? '新增' : '编辑') + '成功!');
          this.formData.setOpenClass = 1;
          this.onSaveClick();
          // this.getFormDetail();
        } else {
          this.$message.error(res.message);
        }
      });

      // if (data.state == 1) {
      //   let obj = {
      //     uid: uuid(), // 生成一段唯一标识id
      //     className: data.name,
      //     address: data.videoFileId,
      //     startTime: data.checked[0],
      //     endTime: data.checked[1],
      //     number: data.total,
      //     type: 2,
      //   }
      //   // 所在场次在同一地点
      //   this.FixedPlaceTrainingList.push(obj)
      // } else if (data.state == 2) {
      //   this.FixedPlaceTrainingList.forEach(item => {
      //     if (item.uid == data.uid) {
      //       item.className = data.name
      //       item.startTime = data.checked[0]
      //       item.endTime = data.checked[1]
      //       item.number = data.total
      //     }
      //   })
      // }
    },

    // 弹窗回调 - 新增场次
    modalNonFixedPlaceTraining(data) {
      // if (data.state == 1) {
      //   let obj = {
      //     uid: uuid(), // 生成一段唯一标识id
      //     className: data.name,
      //     address: data.videoFileId,
      //     startTime: data.checked[0],
      //     endTime: data.checked[1],
      //     number: data.total,
      //     type: 2,
      //     addressValue: data.addressValue,
      //     Province: data.Province + (data.Province ? ('/' + data.videoFileId) : '-'),
      //   }
      //   // 所在场次在同一地点
      //   this.NonFixedPlaceTrainingList.push(obj)
      // } else if (data.state == 2) {
      //   this.NonFixedPlaceTrainingList.forEach(item => {
      //     if (item.uid == data.uid) {
      //       item.className = data.name
      //       item.startTime = data.checked[0]
      //       item.endTime = data.checked[1]
      //       item.number = data.total
      //       item.addressValue = data.addressValue
      //       item.Province = data.Province + (data.Province ? (' ' + data.videoFileId) : '-')
      //     }
      //   })
      // }
    },

    // 删除场次选择
    removeFixedPlaceTraining(name, id) {
      this.$confirm({
        content: '确认删除该班期吗？',
        onOk: () => {
          this.$ajax({
            method: 'PUT',
            url: "/hxclass-management/course/delOpenTime/" + id,
          }).then(res => {
            if (res.code == 200 && res.success) {
              this.$message.success('删除成功');
              this.onSaveClick();
            } else {
              this.$message.warning(res.message);
            }
          })
        }
      })
      // 改为实时编辑、删除
      // this[name].forEach(item => {
      //   if (item.uid == id) {
      //     this.$set(item, 'operation', 3) // 1.新增 2.修改 3.删除
      //   }
      // })
      // 因后台原因，不做真实删除
      // this[name] = this[name].filter(res=>{
      //   return res.uid != id
      // })
    },

    // 删除选择课程
    removeSelectCourseList(row) {
      this.SelectCourseList = this.SelectCourseList.filter(item => {
        return item.courseId != row.courseId
      })
    },

    // 弹窗回调 - 商品
    onModalProduct(e) {
      if (e.type == 'setRelevantProduct') {
        this.setRelevantProductList = JSON.parse(JSON.stringify(e.data))
      } else if (e.type == 'setRelevantGift') {
        this.setRelevantGiftList = JSON.parse(JSON.stringify(e.data))
      }
    },

    // 删除关联商品
    removeRelevantProduct(id) {
      this.setRelevantProductList = this.setRelevantProductList.filter(item => {
        return item.productId != id
      })
    },

    // 删除赠品商品
    removesetRelevantGift(id) {
      this.setRelevantGiftList = this.setRelevantGiftList.filter(item => {
        return item.productId != id
      })
    },

    // 暂存
    // onStorage(){
    //   localStorage.setItem('CourseDetails_data',JSON.stringify(this._data))
    // },

    // 添加相关证书
    addCourseRelevantCertificate() {
      this.formData.courseRelevantCertificate.push({
        uid: uuid(),
        name: '',
        picture: ''
      })
    },

    // 删除相关证书
    removeCourseRelevantCertificate(id) {
      this.formData.courseRelevantCertificate = this.formData.courseRelevantCertificate.filter(item => {
        return item.uid != id
      })
    },

    // 小节自测显隐更新
    FunSelfTest() {
      let show = false
      function fors(arr) {
        arr.forEach((item, index) => {
          if (item.childList && item.childList.length > 0) {
            fors(item.childList)
          }
          if (item.questionList && item.questionList.length > 0) {
            show = true
          }
        })
      }
      fors(this.formData.courseCatalogueList)
      this.formData.setSectionTest = show ? 1 : 0
    },

    // 过滤id
    funkeyId(e) {
      let arr = []
      e.forEach(item => {
        arr.push(item.productId)
      })
      return arr
    },

    // 设置禁用时间于当前时间
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      return current && current < this.$moment().subtract(1, "day");
    },
    disabledDateTime(dateTime) {
      if (this.$moment(dateTime).isSame(this.$moment(new Date()), "day")) {
        return {
          disabledHours: () => this.range(0, this.$moment(new Date()).hour()),
          disabledMinutes: () =>
            this.$moment(dateTime).isSame(this.$moment(new Date()), "hour")
              ? this.range(0, this.$moment(new Date()).minute())
              : []
        };
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.path.indexOf('GeneralView') < 0) {
      this.getPreference() // 关联偏好查询
      this.getInstructor() // 查询授课老师
      this.getCategorySelect() // 查询课程分类
      this.getProductCategory() // 查询所属商品类别
      this.getProductTags() // 查询商品标签
      this.getCertificateTemplate() // 查询证书模板下拉框
      this.getCurriculumCertifTemplate() // 查询课程证书模板下拉框
    }
    if (this.$route.query.state != '2') {
      let data = JSON.parse(localStorage.getItem('CourseDetails_data'))
      if (!!data) {
        for (let key in data) {
          this.$set(this, key, data[key])
        }
        // console.log(data)
        // 移动端课程详情
        this.initMobileDetail = data.formData.mobileDetail
        // PC端课程详情
        this.initPcDetail = data.formData.pcDetail
        // 移动端学员须知
        this.initMobileStudentInstructions = data.formData.mobileStudentInstructions
        // PC端学员须知
        this.initPcStudentInstructions = data.formData.pcStudentInstructions
        // 移动端报名指南
        // this.initMobileRegistrationGuide = data.formData.mobileRegistrationGuide
        // PC端报名指南
        // this.initPcRegistrationGuide = data.formData.pcRegistrationGuide
      }
      // 初始化一条相关证书的数据
      this.formData.courseRelevantCertificate = []
      this.formData.courseRelevantCertificate.push({
        uid: uuid(),
        name: '',
        picture: ''
      })
    }
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {
    funtabIndex() {
      let i = 0
      if (!this.offline) {
        i = this.tabIndex
      } else {
        if (this.tabIndex > 1) {
          i = this.tabIndex - 1
        } else {
          i = this.tabIndex
        }
      }
      return i
    },
    examCertifTempImage() {
      let id = this.formData.examCertifTempId
      let text = ''
      this.examCertifTempList.forEach(item => {
        if (item.tempId == id) {
          text = item.picture
        }
      })
      return text
    },
    returnFixedPlaceTrainingList() {
      return this.FixedPlaceTrainingList.filter(res => {
        return res.operation != 3
      })
    },
    returnNonFixedPlaceTrainingList() {
      return this.NonFixedPlaceTrainingList.filter(res => {
        return res.operation != 3
      })
    }
  },
  // 自定义的侦听器
  watch: {
    viewCondition1() {
      this.changeViewCondition()
    },
    viewCondition2() {
      this.changeViewCondition()
    },
    tabIndex(e) {
      if (e == 0) {
        // 进入第一页
        const data = this.formData
        if (data.mobileDetail) { // 移动端课程详情
          this.initMobileDetail = data.mobileDetail
        }
        if (data.pcDetail) { // PC端课程详情
          this.initPcDetail = data.pcDetail
        }
        if (data.mobileStudentInstructions) { // 移动端学员须知
          this.initMobileStudentInstructions = data.mobileStudentInstructions
        }
        if (data.pcStudentInstructions) { // PC端学员须知
          this.initPcStudentInstructions = data.pcStudentInstructions
        }
      }
    }
  }
}
</script>
