var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 课程购买审核 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字","allowClear":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"format":"YYYY-MM-DD HH:mm:ss"},on:{"change":_vm.changeStartTime}}),_c('a-select',{attrs:{"placeholder":"请选择审核状态","allowClear":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("待审核")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("已通过")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("已驳回")])],1),_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['payaudit:list:query']),expression:"['payaudit:list:query']"}],staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("搜索")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.courseBuyAuditId; },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"status",fn:function(item){return [_vm._v(" "+_vm._s(item == 1 ? "待审核" : item == 4 ? "已驳回" : "已通过")+" ")]}},{key:"remark",fn:function(item){return [_c('a-popover',[_c('template',{slot:"content"},[_c('p',{staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(item))])]),_c('div',{staticClass:"mallHidden",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item)+" ")])],2)]}},{key:"operation",fn:function(item){return [_c('div',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['payaudit:detail:query']),expression:"['payaudit:detail:query']"}],staticClass:"btn_router_link",staticStyle:{"display":"inline-block"}},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.toDetail(item.courseBuyAuditId)}}},[_vm._v("详情")])],1),_c('span',[_vm._v(" | ")]),_c('span',{staticClass:"btn_router_link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showModal(item)}}},[_vm._v("备注")])]}}])})],1),_c('a-modal',{staticStyle:{"width":"200px"},attrs:{"visible":_vm.visible,"title":"备注信息","confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-input',{attrs:{"placeholder":"请输入备注信息"},model:{value:(_vm.editItem.remark),callback:function ($$v) {_vm.$set(_vm.editItem, "remark", $$v)},expression:"editItem.remark"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }