<template>
  <div>
    <h4 class="page-title">基础信息</h4>
    <a-row class="rows">
      <a-col class="left required" span="3">课程名称</a-col>
      <a-col class="right" span="8">
        <a-input v-model="formData.courseName" :maxLength="50" placeholder="请输入课程名称" />
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">关联偏好(可多选)</a-col>
      <a-col class="right" span="19">
        <a-checkbox-group class="radioGroup" v-model="formData.likeIdList">
          <a-checkbox :value="item.likeId" v-for="item in preferenceList" :key="'likeId' + item.likeId">{{
            item.likeLabelName }}</a-checkbox>
        </a-checkbox-group>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">课程简介</a-col>
      <a-col class="right" span="8">
        <a-textarea placeholder="请输入课程简介" v-model="formData.introduction" />
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">顺序观看</a-col>
      <a-col span="4">
        <div>
          <!-- <a-checkbox :disabled="userStudy == '1'" v-model="formData.watchByCatalogueType">必须按目录顺序进行观看</a-checkbox> -->
          <a-radio-group class="radioGroup" v-model="formData.watchByCatalogueOrder" :disabled="userStudy == '1'">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">视频数量</a-col>
      <a-col class="right" span="4">
        <a-input-number v-model="formData.classHour" :precision="0" :min="formData.watchByCatalogueOrder == 1 ? 2 : 0"
          :max="1000" placeholder="视频数量" style="width: 100px" />
        <span style="font-size: 14px; color: #333; margin-left: 12px">个</span>
      </a-col>
      <a-col class="left required" span="2">必修视频数量</a-col>
      <a-col class="right" span="4">
        <a-input-number v-model="formData.compulsClassHour" :precision="0" :min="formData.watchByCatalogueOrder == 1 ? 1 : 0"
          :max="1000" placeholder="必修数量" style="width: 100px" />
        <span style="font-size: 14px; color: #333; margin-left: 12px">个</span>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">课程类别</a-col>
      <a-col class="right" span="8">
        <a-tree-select style="width: 100%" :tree-data="CategorySelectList" v-model="formData.courseCategoryId"
          placeholder="请选择课程类别" :replaceFields="{
            children: 'childList',
            title: 'name',
            key: 'courseCategoryId',
            value: 'courseCategoryId',
          }" :treeDefaultExpandAll="true">
          <a-icon slot="suffixIcon" type="caret-down" />
        </a-tree-select>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">课程类型</a-col>
      <a-col class="right" span="3">
        <a-select :disabled="formData.courseId != 0" placeholder="请选择商品编码" style="width: 100%"
          v-model="formData.courseClass">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">线上</a-select-option>
          <a-select-option :value="2">线下</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="3">商品编码</a-col>
      <a-col class="right" span="3">
        <a-select placeholder="请选择商品编码" style="width: 100%" v-model="formData.codeType">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">自产A</a-select-option>
          <a-select-option :value="2">外销B</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">授课老师</a-col>
      <a-col class="right" span="8">
        <a-select v-model="formData.courseTeacherIdList" style="width: 100%" mode="multiple"
          :filterOption="filterInstructorList" placeholder="请选择授课老师">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="item.teacherId" v-for="item in InstructorList" :key="'InstructorList' + item.name">{{
            item.name }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col span="20" offset="4">
        <a-button v-hasPermi="['class:list:query']" v-if="$route.query.status == 1" @click="onStorageClick"
          :loading="loadingBtn" type="primary" style="margin-right: 30px">暂存</a-button>
        <a-button v-hasPermi="['class:info:update']" @click="onSaveClick" :loading="loadingBtn">保存</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '../CourseDetails/config.vue'
export default {
  mixins: [config],
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      preferenceList: [], // 关联偏好
      CategorySelectList: [], // 课程分类
      InstructorList: [], // 授课老师
      loadingBtn: false,

      formData: {
        courseId: 0, // 课程id
        courseName: '', // 课程名称
        likeIdList: [], // 关联偏好
        introduction: '', // 课程简介
        classHour: '', // 视频数量
        compulsClassHour: '', // 必修视频数量
        courseCategoryId: undefined, // 	课程分类
        codeType: 1, // 商品编码类型(1.自产2.外采)
        courseClass: undefined, // 商品类型(1.线上2.线下)
        courseTeacherIdList: [], // 授课老师
        saveType: 1, // 保存类型(0-未填写，1-暂存，2-提交)
        watchByCatalogueOrder: undefined, // 是否按顺序播放 1:是  0：否
        // watchByCatalogueType: 1, // 是否按顺序播放
      },
    };
  },
  // 事件处理器
  methods: {
    // 暂存校验
    fun_StorageVerific() {
      const data = this.formData

      // 验证课程名称
      if (!data.courseName) {
        return Promise.reject({ msg: '请输入课程名称' })
      }

      // 课程类型
      if (!data.courseClass) {
        return Promise.reject({ msg: '请选择课程类型' })
      }

      return Promise.resolve()
    },

    // 检查Form表单并返回object
    fun_verific() {
      const data = this.formData

      // 验证课程名称
      if (!data.courseName) {
        return Promise.reject({ msg: '请输入课程名称' })
      }

      // 关联偏好
      if (!data.likeIdList.length) {
        return Promise.reject({ msg: '请关联课程偏好' })
      }

      // 课程简介
      if (!data.introduction) {
        return Promise.reject({ msg: '请输入课程简介' })
      }

      // 总课时
      if (data.classHour === null || data.classHour === '') {
        console.log('=======', data, '------');

        return Promise.reject({ msg: '请输入视频数量' })
      }

      // 必修视频数量
      if (data.compulsClassHour === null || data.compulsClassHour === '') {
        return Promise.reject({ msg: '请输入必修视频数量' })
      }

      // 课程类别
      if (!data.courseCategoryId) {
        return Promise.reject({ msg: '请选择课程类别' })
      }

      // 课程类型
      if (!data.courseClass) {
        return Promise.reject({ msg: '请选择课程类型' })
      }

      // 商品编码
      if (!data.codeType) {
        return Promise.reject({ msg: '请选择商品编码' })
      }

      // // 授课老师
      // if(!data.courseTeacherIdList || data.courseTeacherIdList.length == 0){
      //   return Promise.reject({msg:'请选择授课老师'})
      // }

      return Promise.resolve()
    },

    // 暂存
    onStorageClick() {
      this.loadingBtn = true
      Promise.all([
        this.fun_StorageVerific()
      ]).then(res => {
        this.formData.saveType = 1 // 保存类型 暂存
        this.onSubmitClick()
      }).catch(err => {
        if (err) {
          this.$message.warning(err.msg)
        } else {
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    // 提交
    onSaveClick() {
      this.loadingBtn = true
      Promise.all([
        this.fun_verific()
      ]).then(res => {
        this.formData.saveType = 2 // 保存类型 提交
        this.onSubmitClick()
      }).catch(err => {
        if (err) {
          this.$message.warning(err.msg)
        } else {
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    onSubmitClick() {
      this.loadingBtn = true
      let url = ''
      if (this.formData.courseId) {
        url = "/hxclass-management/course/updateCourseBasicInfo"
      } else {
        url = "/hxclass-management/course/saveCourseBasicInfo"
      }
      // this.formData.watchByCatalogueOrder = this.formData.watchByCatalogueType ? 1 : 0;
      this.$message.loading({ content: "课程信息正在存储...", duration: 0 });
      this.$ajax({
        url: url,
        method: "POST",
        params: this.formData,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success((this.formData.saveType == 1 ? '暂存' : '保存') + '成功!')
          this.loadingBtn = false
          // 向父页面更新目录修改状态
          this.$parent.onChildInit('basicInfo', this.formData.saveType);
          if (res.data) {
            this.formData.courseId = res.data
            // 向父页面传递创建的课程id
            this.$parent.onChildCourseId(res.data);
          }
        }
      });
    },

    // 查询详情
    getFormDetail() {
      this.loadingBtn = true
      this.$message.loading({ content: "正在查询详情...", duration: 0 });
      this.$ajax({
        url: "/hxclass-management/course/getCourseBasicInfo",
        params: {
          courseId: this.formData.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // res.data.watchByCatalogueType = res.data.watchByCatalogueOrder == 1 ? true : false;
          if (res.data.watchByCatalogueOrder == 1) {
            res.data.classHour = (res.data.classHour < 2 && res.data.classHour != null) ? 2 : res.data.classHour;
            res.data.compulsClassHour = (res.data.compulsClassHour < 1 && res.data.compulsClassHour != null) ? 1 : res.data.compulsClassHour;
          }
          this.formData = res.data;
          this.$message.success("查询成功!");
        } else {
          this.$message.error(res.message);
        }
        this.loadingBtn = false
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getPreference() // 关联偏好查询
    this.getInstructor() // 查询授课老师
    this.getCategorySelect() // 查询课程分类
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.formData.courseId = this.$route.query.id
        this.getFormDetail();
      }
    });
  },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./style.less";
</style>
