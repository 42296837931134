<template>
  <div class="component-upload-image">
    <span class="ant-upload-picture-card-wrapper" v-if="imageUrl">
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
        <span role="button" tabindex="0" class="ant-upload">
          <div class="pictures">
            <img style="max-height:100px;max-width:180px;" :src="imageUrl" alt="avatar" />
            <div class="icon">
              <a :href="imageUrl" target="_blank" rel="noopener noreferrer">
                <a-icon type="eye" class="i" />
              </a>
              <a-icon @click="uploadedSuccessfully('')" type="delete" class="i" />
            </div>
          </div>
        </span>
      </div>
    </span>
    <a-upload
      v-else
      accept=".jpg,.png,.jpeg" 
      list-type="picture-card"
      :showUploadList="false"
      :progress="progress"
      :beforeUpload="beforeUpload"
      :customRequest="customRequest"
    >
      <img v-if="imageUrl" :src="imageUrl" style="max-width:180px" alt="avatar" />
      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">
          上传图片
        </div>
      </div>
    </a-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" slot="tip" v-if="showTip">
      为避免变形或上传失败，请使用
      <template v-if="fileScale"><b style="color: #f56c6c">{{ fileScale }}宽高比</b> 且</template>
      <template v-if="fileSize"> 小于 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
      的图片
      <template v-if="filePixel"> （推荐 <b style="color: #f56c6c">{{ filePixel }}px</b> ）</template>
    </div>
    <a-modal v-model="dialogCropperVisible"  :width="dialogWidth" :footer="null">
      <!-- 功能区裁剪 -->
      <div class="cropp-btn">
        <a-button class="btn" @click="changeScale(1)">放大</a-button>
        <a-button class="btn" @click="changeScale(-1)">缩小</a-button>
        <a-button class="btn" @click="rotateLeft">↺ 左旋转</a-button>
        <a-button class="btn" plain @click="rotateRight">↻ 右旋转</a-button>
        <a-button class="btn" @click="uploadImg()" type="primary" :loading="loading">提交</a-button>
      </div>
      <!--裁剪功能-->
      <div class="cropper-box" :style="{width: autoCropWidth + 50 + 'px', height: autoCropHeight + 50 + 'px'}">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="autoCropWidth"
          :autoCropHeight="autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="mode"
          @realTime="realTime"
        ></vueCropper>
      </div>
      <!--预览效果图-->
      <div class="show-preview">
        <p>裁剪效果：</p>
        <div class="cropper-box" :style="previews.div" style="overflow: hidden;">
          <img :src="previews.url" :style="previews.img">
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  // 可用组件的哈希表
  components: {VueCropper},
  // 接收传值
  props: {
    // 图片回显
    value: {
      type: String,
      default: '',
    },
    // 上传路径
    bucket: {
      type: String,
      default: '',
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 10,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: false
    },
    // 比例限制提示
    fileScale: {
      type: String,
      default: '16:9',
    },
    // 像素比例限制提示
    filePixel: {
      type: String,
      default: '400×400',
    },
    // 是否裁剪
    isCropp: {
      type: Boolean,
      default: false
    },
    // 剪裁弹窗宽度
    dialogWidth: {
      type: String,
      default: '500px'
    },
    // 生成截图框宽度
    autoCropWidth: {
      type: Number,
      default: 400
    },
    // 生成截图框高度
    autoCropHeight: {
      type: Number,
      default: 400
    },
  },
  // 数据对象
  data () {
    return {
      loading:false,
      imageUrl:'',
      progress:0,
      dialogCropperVisible:false,
      option:{
        img: '',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: true,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        fixed: false,         //是否开启截图框宽高固定比例
        fixedNumber: [1.53, 1], //截图框的宽高比例
        full: false,         //false按原比例裁切图片，不失真
        fixedBox: false,      //固定截图框大小，不允许改变
        canMove: false,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: false,     //上传图片按照原始比例渲染
        centerBox: false,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 5000,    //限制图片最大宽度和高度
        enlarge: 1,          //图片根据截图框输出比例倍数
      },
      previews: {},
    }
  },
  // 事件处理器
  methods: {
    // 上传前钩子上传图片
    beforeUpload(file){
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`);
        return false;
      }

      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传图片大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }

      if(this.isCropp){
        let _URL = window.URL || window.webkitURL
        this.option.img = _URL.createObjectURL(file)
        this.dialogCropperVisible = true
        return false
      }
    },

    //实时预览函数
    realTime (data) {
      this.previews = data
    },
    //图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    //向左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    //上传图片
    uploadImg(){
      //获取截图的blob数据
      this.$refs.cropper.getCropBlob(async (data) => {
        const blob = data;
        const fileName = "image.png"; // 文件名
        const contentType = "image.png"; // MIME类型
        const file = new File([blob], fileName, { type: contentType, lastModified: Date.now() });
        const obj = {
          file: file
        }
        this.customRequest(obj)
      })
    },

    // 图文件上传
    customRequest(fileData) {
      this.loading = true;
      this.$message.loading({ content: "正在上传...", duration: 0 });
      const bucket = this.bucket // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.imageUrl = url
          this.uploadedSuccessfully(url);
          this.$message.success("上传成功");
        } else {
          this.$message.error("上传失败");
        }
        this.dialogCropperVisible = false
        this.loading = false;
      });
    },

    // 上传结束处理
    uploadedSuccessfully(url) {
      this.$emit("input", url);
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      this.progress = progress
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
    // 裁剪模板默认大小
    mode(){
      return `${this.autoCropWidth + 50}px, ${this.autoCropHeight + 50}px`
    }
  },
  // 自定义的侦听器
  watch: {
    value: {
      handler(val) {
        this.imageUrl = val
      },
    }
  }
}
</script>

<style lang="less" scoped>
.cropp-btn{
  margin-bottom: 20px;
  .btn{
    margin-right: 5px;
  }
}
.cropper-box{
  margin: 0 auto;
}
.show-preview{
  margin-top: 10px;
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    .icon {
      opacity: 1;
    }
  }

  .icon {
    transition: all 0.3s;
    opacity: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      margin: 0 5px;
      font-size: 18px;
    }
  }
}
</style>
