<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 用户管理 </template>
      <template #input>
        <span class="tag">注册时间: </span>
        <a-range-picker
          @change="onTimeChange"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['注册开始时间', '注册结束时间']"
          :showTime="{
            hideDisabledOptions: true,
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }"
        />
        <span class="template" v-if="queryParams.user_type != 3">
          <a-select default-value="1" style="width: 120px" @change="changeType">
            <a-select-option value="1">姓名</a-select-option>
            <a-select-option value="2">手机号</a-select-option>
            <a-select-option value="3">身份证号</a-select-option>
            <a-select-option value="4">学历</a-select-option>
            <a-select-option value="5">用户名</a-select-option>
          </a-select>
          <a-input
            v-model="keyValue"
            :placeholder="keyPlaceholder"
            allowClear
          />
        </span>
        <span class="template" v-else>
          <a-input v-model="orgName" placeholder="请输入机构名称" allowClear />
          <a-input v-model="code" placeholder="请输入机构码" allowClear />
        </span>
        <a-button type="primary" @click="onSearch" class="btn" v-hasPermi="['user:manage:querylist']">搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="家长"></a-tab-pane>
        <a-tab-pane key="2" tab="从业者"></a-tab-pane>
        <a-tab-pane key="0" tab="普通用户"></a-tab-pane>
        <a-tab-pane key="3" tab="机构"></a-tab-pane>
      </a-tabs>

      <a-table
        class="table-template"
        :rowKey="(item) => item.userId"
        :columns="queryParams.user_type != 3 ? columns : orgOlumns"
        :data-source="tableData"
        :loading="loading"
        :scroll="{ x: 1200 }"
        @change="onPage"
        :pagination="{
          total: total,
          current: queryParams.pageNum,
          defaultPageSize: queryParams.pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>
        
        <!-- 手机号 -->
        <template slot="mobile" slot-scope="text">
          {{ text ? $encryption('empty','phone',text) : '-' }}
        </template>
        <!-- 身份证号 -->
        <template slot="idNo" slot-scope="text">
          {{ text ? $encryption('empty','idCard',text) : '-' }}
        </template>

        <template slot="userType" slot-scope="item">
          {{ userType(item) }}
        </template>

        <!-- 机构类型 -->
        <template slot="orgType" slot-scope="item">
          {{ item == 1 ? "企业" : item == 2 ? "非企业" : "" }}
        </template>

        <!-- 地区 -->
        <template slot="address" slot-scope="item, row">
          {{ row.orgProvice }} {{ row.orgCity }} {{ row.orgArea
          }}{{ row.orgAddress }}
        </template>

        <!-- 用户来源 -->
        <template slot="tripartUser" slot-scope="text">
          {{
            text == 0
              ? "华夏"
              : text == 2
              ? "恩启"
              : text == 3
              ? "ALSOLIFE"
              : text == 4
              ? "机构"
              : ""
          }}
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link" v-if="queryParams.user_type != 0">
            <a @click="goDetails(item)" v-if="item.institutionCode"
              >详情</a
            >
            <span v-if="item.institutionCode">|</span>
            <template v-if="queryParams.user_type != 3">
              <a @click="showModal(item)" v-hasPermi="['user:idcard:edit']">修改身份证号码</a>
              <span v-hasPermi="['user:idcard:edit']">|</span>
            </template>
            <a @click="showmvisible(item)" v-hasPermi="['user:phone:edit']">修改手机号</a>
            <span v-hasPermi="['user:phone:edit']">|</span>
            <a-popconfirm
              title="确认注销?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(item.userId)"
            >
              <a href="#" v-hasPermi="['user:info:updatestatus']">注销</a>
            </a-popconfirm>
            <span v-hasPermi="['user:info:updatestatus']">|</span>
            <a v-hasPermi="['user:password:edit']" @click=" (userId = item.userId), (mobile = item.mobile),(isResetPswShow = true)" >
            重置密码
            </a>
            <a @click="openAddressModal(item)" v-hasPermi="['user:address:edit']">| 修改地址单位</a>
          </div>
          <!-- 普通用户只有这三个操作 -->
          <div class="btn_router_link" v-else>
            <a v-hasPermi="['user:password:edit']" @click="(userId = item.userId),(mobile = item.mobile),(isResetPswShow = true)">
              重置密码
            </a>
            <span v-hasPermi="['user:password:edit']">|</span>
            <a @click="showmvisible(item)" v-hasPermi="['user:phone:edit']">修改手机号</a>
            <span v-hasPermi="['user:phone:edit']">|</span>
            <a-popconfirm
              title="确认注销?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(item.userId)"
            >
              <a href="#" v-hasPermi="['user:info:updatestatus']">注销</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      title="修改身份证号"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="list">
          <span>姓名：</span>
          <a-input
            class="input"
            v-model="formData.name"
            placeholder="输入姓名"
          />
        </div>
        <div class="list">
          <span>身份证号：</span>
          <a-input
            class="input"
            v-model="formData.idNo"
            placeholder="输入身份证号"
          />
        </div>
      </div>
    </a-modal>
    <!-- 重置密码 -->
    <a-modal
      v-model="isResetPswShow"
      align="center"
      @ok="onResetPsw()"
      title="重置密码"
      width="360px"
    >
      <span>是否确认重置该账号密码</span>
    </a-modal>


    <!-- 修改手机号 -->
    <a-modal
      title="修改手机号"
      :visible="mvisible"
      :confirm-loading="confirmLoading"
      @ok="changmobile"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="list">
          <span>手机号：</span>
          <a-input
            class="input"
            v-model="formData.mobile"
            placeholder="输入手机号"
          />
        </div>
      </div>
    </a-modal>

    <!-- 修改地址信息 -->
    <a-modal
      title="修改地址单位"
      :visible="addressVisible"
      :confirm-loading="confirmLoading"
      @ok="onModifyAddress"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="list">
          <span>常住地址：</span>
          <Province ref="Province" v-if="addressVisible" style="width:100%;" @regionCity='FunProvince' :value="addressValue"></Province>
        </div>
        <div class="list">
          <span>工作单位：</span>
          <a-auto-complete
            style="width: 100%"
            v-model="addressForm.work"
            placeholder="输入搜索关键字"
            option-label-prop="value"
            @search="onSearchWork"
            :defaultActiveFirstOption="false"
            :allowClear="true"
          >
            <a-spin
              v-if="fetching"
              class="auto-spin"
              slot="notFoundContent"
              size="small"
            />
            <template slot="dataSource">
              <a-select-option
                @click="onSelectRole(item)"
                v-for="(item) in workList"
                :key="item.workUnitId"
                :value="item.name"
              >
                <a-row type="flex" justify="space-between" align="middle">
                  <a-col>{{ item.name }}</a-col>
                </a-row>
              </a-select-option>
            </template>
          </a-auto-complete>
        </div>
        <div class="list">
          <span>学历：</span>
          <a-select 
            placeholder="请选择学历"
            style="width: 100%"
            v-model="addressForm.degree"
            @change="onDegreeChange">
            <a-icon slot="suffixIcon" type="caret-down"/>
            <a-select-option v-for="item in degreeList" :key="item.code" :value="item.name" >{{item.name}}</a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Province from '@/components/provinceCity'
import moment from "moment";
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "身份",
    align: "center",
    dataIndex: "userType",
    scopedSlots: { customRender: "userType" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idNo",
    scopedSlots: { customRender: "idNo" },
  },
  {
    title: "所在地",
    align: "center",
    dataIndex: "locationName",
  },
  {
    title: "学历",
    align: "center",
    dataIndex: "degree",
  },
  {
    title: "用户来源",
    align: "center",
    dataIndex: "tripartUser",
    scopedSlots: { customRender: "tripartUser" },
  },
  {
    title: "注册时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
const orgOlumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "orgName",
  },
  {
    title: "用户账号",
    align: "center",
    dataIndex: "email",
  },
  {
    title: "机构类型",
    align: "center",
    dataIndex: "orgType",
    scopedSlots: { customRender: "orgType" },
  },
  {
    title: "统一社会信用代码",
    align: "center",
    dataIndex: "institutionCode",
  },
  {
    title: "地区",
    align: "center",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "联系人",
    align: "center",
    dataIndex: "contactName",
  },
  {
    title: "联系方式",
    align: "center",
    dataIndex: "contactTell",
  },
  {
    title: "注册时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Province },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      orgName: "", // 机构名称
      code: "", // 机构码
      columns, // 表头
      orgOlumns, // 机构表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      keyType: 1, //查询类型 1姓名 2手机号 3身份证号 4学历 5用户名
      keyPlaceholder: "请输入姓名", //默认提示
      keyValue: "",
      queryParams: {
        pageNum: 1, //页码
        pageSize: 10, // 页数
        start_time: "",
        end_time: "",
        keyword: "", //用户名
        name: "", //姓名
        mobile: "", //手机号
        idNo: "", //身份证号
        degree: "", //学历
        user_type: "1", // 用户类型  1.家长，2从业者，3.机构，4.家长+从业者
      },
      // 弹窗内数据
      visible: false,
      isResetPswShow: false,
      confirmLoading: false,
      formData: {
        mobile: "", //手机号
        userId: "", //id
      },
      userId: "",
      mobile: "",
      mvisible: false,
      addressVisible: false,  // 修改地址单位弹窗
      addressValue:[],

      // 修改工作、学历、地址表单
      addressForm:{
        userId: '',
        addressProvincial: '', // 省
        addressCity: '',  // 市
        addressArea: '',  // 区
        degree: '', // 学历名称
        degreeCode: '', // 学历code
        work: '', // 单位名称
        workCom: undefined, // 单位code
      },
      workList: [], // 工作单位数据
      degreeList: [], // 学历数据
      fetching: false,
    };
  },
  // 事件处理器
  methods: {
    moment,
    // 改变查询条件
    changeType(e) {
      this.keyType = e;
      this.queryParams.keyword = ""; //用户名
      this.queryParams.name = ""; //姓名
      this.queryParams.mobile = ""; //手机号
      this.queryParams.idNo = ""; //身份证号
      this.queryParams.degree = ""; //学历
      this.keyPlaceholder = "";
      // 姓名
      if (this.keyType == 1) {
        this.keyPlaceholder = "请输入姓名";
      }
      // 手机号
      else if (this.keyType == 2) {
        this.keyPlaceholder = "请输入手机号";
      }
      // 身份证号
      else if (this.keyType == 3) {
        this.keyPlaceholder = "请输入身份证号";
      }
      // 学历
      else if (this.keyType == 4) {
        this.keyPlaceholder = "请输入学历";
      }
      // 用户名
      else if (this.keyType == 5) {
        this.keyPlaceholder = "请输入用户名";
      }
    },
    // 分页
    onPage(e) {
      this.queryParams.pageNum = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.getManageList();
    },
    userType(e) {
      if (e == 0) {
        return "普通用户";
      } else {
        let userTypeArry = [
          "家长",
          "从业者",
          "机构",
          "家长+从业者",
          "家长+机构",
          "从业者+机构",
          "家长+从业者+机构",
        ];
        return userTypeArry[e - 1];
      }
    },
    // tab切换
    callback(e) {
      this.queryParams.pageNum = 1;
      this.queryParams.user_type = e;
      this.getManageList();
    },
    // 时间选择
    onTimeChange(date, dateString) {
      this.queryParams.start_time = dateString[0]; // 开始日期
      this.queryParams.end_time = dateString[1]; // 结束日期
    },
    // 跳详情页
    goDetails(e) {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { userId: e.userId, userType: this.queryParams.user_type },
      });
    },
    // 高级检索
    onSearch() {
      this.queryParams.pageNum = 1;
      this.getManageList();
    },
    getManageList() {
      if (this.queryParams.user_type == 3) {
        let data = {
          pageNum: this.queryParams.pageNum, // 起始页
          pageSize: this.queryParams.pageSize, // 条数
          code: this.code, // 机构码
          endTime: this.queryParams.end_time, // 结束时间
          startTime: this.queryParams.start_time, // 开始时间
          orgName: this.orgName, // orgName
        };
        this.loading = true;
        this.$ajax({
          method: "get",
          url: "/hxclass-management/organization/info/orgUser/list",
          params: data,
        })
          .then((res) => {
            this.loading = false;
            if (res.code == 200 && res.success) {
              this.tableData = res.data.records;
              this.total = res.data.total;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error) {
              this.$message.warning("系统错误");
            }
          });
      } else {
        // 1姓名 2手机号 3身份证号 4学历 5用户名
        if (this.keyType == 1) {
          this.queryParams.name = this.keyValue;
        } else if (this.keyType == 2) {
          this.queryParams.mobile = this.keyValue;
        } else if (this.keyType == 3) {
          this.queryParams.idNo = this.keyValue;
        } else if (this.keyType == 4) {
          this.queryParams.degree = this.keyValue;
        } else if (this.keyType == 5) {
          this.queryParams.keyword = this.keyValue;
        }
        this.loading = true;
        this.$ajax({
          method: "get",
          url: "/hxclass-management/user/info/list",
          params: this.queryParams,
        })
          .then((res) => {
            this.loading = false;
            if (res.code == 200 && res.success) {
              this.tableData = res.data.records;
              this.total = res.data.total;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error) {
              this.$message.warning("系统错误");
            }
          });
      }
    },
    // 打开弹窗
    showModal(e) {
      // console.log(e);
      // 使用这种方式能避免双向绑定
      for (const key in e) {
        this.formData[key] = e[key];
      }
      this.visible = true;
    },
    showmvisible(e) {
      for (const key in e) {
        this.formData[key] = e[key];
      }
      this.mvisible = true;
    },
    openAddressModal(e){
      this.addressForm.userId = e.userId
      this.addressForm.work = e.work
      this.addressForm.workCom = e.workCom
      this.addressForm.degree = e.degree
      this.addressForm.degreeCode = e.degreeCode
      this.addressForm.addressProvincial = e.addressProvincial
      this.addressForm.addressCity = e.addressCity
      this.addressForm.addressArea = e.addressArea
      this.addressValue = [e.addressProvincial, e.addressCity, e.addressArea]
      this.$nextTick(() => {
        this.addressVisible = true;
      });
    },
    // 重置密码
    onResetPsw() {
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/user/info/manage/update/userPassword?userId=" + this.userId +"&mobile=" +this.mobile,
        // params: {
        //   userId: this.userId,
        //   mobile: this.mobile,
        // }
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.isResetPswShow = false;
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 提交
    handleOk(e) {
      this.confirmLoading = true;
      // 非空校验 姓名
      if (!this.formData.name) {
        this.$message.warning("请输入姓名");
        return;
      }
      // 非空校验 用户名
      if (!this.formData.idNo) {
        this.$message.warning("请输入用户名");
        return;
      }
      let array = this.formData.idNo.split("");
      let idno = "";
      // 字符串匹配
      for (let index = 0; index < this.formData.idNo.length; index++) {
        if (array[index] == "x") {
          array[index] = "X";
        }
        idno += array[index];
      }
      // console.log(idno);

      this.$ajax({
        method: "post",
        url: "/hxclass-management/user/info/idCard/Update",
        params: {
          userId: this.formData.userId,
          idNo: idno,
          name: this.formData.name,
        },
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.visible = false;
            this.$message.success(res.message);
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
      setTimeout(() => {
        this.confirmLoading = false;
      }, 1000);
    },
    // 取消
    handleCancel(e) {
      this.visible = false;
      this.mvisible = false;
      this.addressVisible = false;
    },
    // 修改手机号
    changmobile() {
      // 非空校验 手机号
      if (!this.formData.mobile) {
        this.$message.warning("请输入手机号");
        return;
      } else if (!this.$regular.phone.reg.test(this.formData.mobile)) {
        this.$message.warning("请输入正确的手机号");
        return
      }
      this.$ajax({
        method: "put",
        url:
          "/hxclass-management/user/info/manageUpdateMobile?userId=" +
          this.formData.userId +
          "&mobile=" +
          this.formData.mobile +
          "&sysUser=" +
          (this.queryParams.user_type == 3 ? 2 : 0),
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.mvisible = false;
          this.getManageList();
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    // 修改地址学历
    onModifyAddress(){
      this.confirmLoading = true
      this.addressForm.work = this.addressForm.work.replace(/\s+/g,"")
      this.$ajax({
        method: "put",
        url: "/hxclass-management/user/info/updateUserAddress",
        params: this.addressForm,
      }).then((res) => {
        this.confirmLoading = false;
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.addressVisible = false;
          this.getManageList();
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    // 打印选中人员
    onSelectRole(e) {
      this.addressForm.work = e.name
      this.addressForm.workCom = e.workUnitId
    },

    // 选择学历
    onDegreeChange(name){
      let filter = this.degreeList.filter((item) => {
        return name == item.name;
      })[0];
      this.addressForm.degree = name
      this.addressForm.degreeCode = filter.code
    },

    // 检索工作单位
    onSearchWork(val) {
      this.fetching = false;
      this.workList = [];
      if (!val) {
        clearTimeout(this.tiemID);
        return;
      }
      this.$nextTick(function () {
        if (val !== "") {
          this.fetching = true;
          const _this = this;
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(function () {
            _this.selectUser = {};
            _this
              .$ajax({
                method: "get",
                url: "/hxclass-management/work/list",
                params: {
                  keyword: _this.addressForm.work,
                  state: this.states, // 	1停用 0 启用
                  pageNum: 1,
                  pageSize: 20,
                },
              })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  _this.workList = res.data.records;
                  _this.fetching = false;
                  _this.addressForm.workCom = undefined
                }
              });
          }, 400);
        }
      });
    },

    // 获取学历接口
    getDegreeData(){
      this.$ajax({
        url: "/hxclass-management/work/degree/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.degreeList = res.data
        }
      });
    },

    // 获取所选地址
    FunProvince(data){
      this.addressForm.addressProvincial = data[0];
      this.addressForm.addressCity = data[1];
      this.addressForm.addressArea = data.length > 2 ? data[2] : "";
      this.addressValue = data
    },

    // 注销
    confirm(e) {
      // console.log(e);
      this.$ajax({
        method: "put",
        url: "/hxclass-management/user/info/cancellation/account?userId=" + e,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getManageList();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.getManageList();
    this.getDegreeData()
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-template {
  margin-top: 0;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.content {
  margin: 10px auto;
  padding: 0 30px;
  .list {
    display: flex;
    margin: 10px 0;
    align-items: center;
    span {
      display: block;
      flex-shrink: 0;
      width: 80px;
    }
    .input {
      width: 300px;
    }
  }
}
</style>
