<template>
    <div class="all_main_wrap">
        <HeaderBox>
            <template #title> 线下发票管理 </template>
            <template #input>
                <div class="findItem">
                    <a-input style="margin-right: 20px" v-model="orderCode" placeholder="请输入订单编号" />
                    <!-- 开票状态 -->
                    <a-select placeholder="开票状态" style="width: 120px" @change="handleChange" v-model="status">
                        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
                        <!-- 开票状态：1.开票中/换开中 2.已开票/已换开 3.已作废 -->
                        <a-select-option :value="undefined"> 全部 </a-select-option>
                        <a-select-option :value="1"> 未开票 </a-select-option>
                        <a-select-option :value="2"> 已开票 </a-select-option>
                        <a-select-option :value="3"> 已作废 </a-select-option>
                    </a-select>

                    <!-- <a-button type="primary" :loading="loading" @click="visible = true">新增</a-button> -->
                    <a-button type="primary" :loading="loading" @click="search()"
                        v-hasPermi="['invoice:list:query']">搜索</a-button>
                    <a-button class="all_btn_border btnBorder" @click="reset()">重置</a-button>
                </div>
            </template>
        </HeaderBox>
        <!-- 表格列表 -->
        <div class="table-box">
            <a-table class="table-template" :columns="columns" :scroll="{ x: true }" :data-source="tableData"
                :rowKey="(item, index) => index" :loading="loading" @change="onPage" :pagination="{
                    hideOnSinglePage: true,
                    total: total,
                    current: pageNumber,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    showTotal: function (total, range) {
                        return `共${total}条`;
                    },
                }">
                <template slot="index" slot-scope="item, row, index">
                    {{ (pageNumber - 1) * pageSize + index + 1 }}
                </template>

                <template slot="status" slot-scope="item">
                    {{ item == 1 ? "未开票" : item == 2 ? "已开票" : item == 3 ? "已作废" : "-" }}
                </template>

                <template slot="operation" slot-scope="text, record">
                    <div v-hasPermi="['invoice:offline:edit']" class="btn_router_link">
                        <a @click="editInvoice(record)">登记信息</a>
                        <span v-if="record.id != null && record.status != 1 && record.status != 3">|</span>
                        <a v-if="record.id != null && record.status != 1 && record.status != 3"
                            @click="deleteAction(record)">作废</a>
                    </div>
                </template>
            </a-table>
        </div>

        <!-- 新建/编辑 -->
        <a-modal v-model="visible" :title="modelForm.id ? '编辑' : '新建'" @ok="addData()" :confirm-loading="modelLoading"
            @cancel="handleCancel" okText="确认" :maskClosable="false">
            <a-row class="rows">
                <a-col span="5" class="left">订单编号</a-col>
                <a-col span="19" class="right">
                    <a-input placeholder="请输入订单编号" v-model="modelForm.orderCode" />
                </a-col>
            </a-row>
            <!-- <a-row class="rows">
                <a-col span="5" class="left_no">交易号</a-col>
                <a-col span="19" class="right">
                    <a-input placeholder="请填写交易号" v-model="modelForm.tradeId" />
                </a-col>
            </a-row> -->
            <a-row class="rows">
                <a-col span="5" class="left">发票号码</a-col>
                <a-col span="19" class="right">
                    <a-input placeholder="请输入发票号码" v-model="modelForm.fphm" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left_no">开票金额</a-col>
                <a-col span="19" class="right">
                    <a-input-number id="inputNumber" placeholder="请填写开票金额" v-model="modelForm.price" :min="0.01" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left_no">税号</a-col>
                <a-col span="19" class="right">
                    <a-input placeholder="请输入税号" v-model="modelForm.taxNo" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left_no">发票抬头</a-col>
                <a-col span="19" class="right">
                    <a-input placeholder="请输入发票抬头" v-model="modelForm.title" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left_no">邮箱</a-col>
                <a-col span="19" class="right">
                    <a-input placeholder="请输入邮箱" v-model="modelForm.email" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left_no">开票日期</a-col>
                <a-col span="19" class="right">
                    <a-date-picker show-time format="yyyy-MM-DD HH:mm:ss" value-format="yyyy-MM-DD HH:mm:ss"
                        v-model="modelForm.kprq" class="datePicker" />
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>

<script>
const columns = [
    {
        title: "序号",
        align: "center",
        dataIndex: "index",
        fixed: "left",
        scopedSlots: {
            customRender: "index",
        },
    },
    {
        title: "订单编号",
        align: "center",
        dataIndex: "orderCode",
    },
    {
        title: "交易号",
        align: "center",
        dataIndex: "tradeId",
    },
    {
        title: "发票状态",
        align: "center",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status",
        },
    },
    {
        title: "开票金额",
        align: "center",
        dataIndex: "price",
    },
    {
        title: "发票号",
        align: "center",
        dataIndex: "fphm",
    },
    {
        title: "发票抬头",
        align: "center",
        dataIndex: "title",
    },
    {
        title: "开票时间",
        align: "center",
        dataIndex: "kprq",
    },
    {
        title: "录入时间",
        align: "center",
        dataIndex: "createTime",
    },
    {
        title: "税号",
        align: "center",
        dataIndex: "taxNo",
    },
    {
        title: "邮箱",
        align: "center",
        dataIndex: "email",
    },
    {
        title: "操作",
        align: "center",
        fixed: "right",
        width: "160px",
        scopedSlots: { customRender: "operation" },
    },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
    // 可用组件的哈希表
    components: { HeaderBox },
    // 接收传值
    props: {},
    // 数据对象
    data() {
        return {
            loading: false,
            columns,
            tableData: [], // 列表数据
            total: 0, // 总数量
            pageNumber: 1, //页码
            pageSize: 10, // 页数
            confirmLoading: false, // 按钮状态
            visible: false,

            orderCode: null, // 	关键字（订单编号）
            status: undefined, //开票状态
            time: [],
            modelForm: {
                orderCode: null,// 订单code
                email: null,
                fphm: null,// 发票号码
                kprq: null,// 开票时间
                price: null,// 开票金额
                status: null,// 开票状态
                taxNo: null,// 税号
                title: null,// 抬头
            },
            modelLoading: false,
        };
    },
    // 事件处理器
    methods: {
        // 编辑
        editInvoice(e) {
            this.visible = true;
            this.modelForm = JSON.parse(JSON.stringify(e));
        },
        // 删除
        deleteAction(e) {
            this.$confirm({
                title: "确定作废该发票吗?",
                centered: true,
                okText: "确认",
                onOk: () => {
                    this.$ajax({
                        url: "/hxclass-management/invoiceOffline/edit",
                        method: "POST",
                        params: {
                            id: e.id,
                            status: 3,
                        },
                    }).then((res) => {
                        if (res.code == 200 && res.success) {
                            this.getData();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                },
            });
        },
        // 抬头类型下拉
        handleChange(e) {
            // console.log(e);
        },
        // 分页
        onPage(e) {
            this.pageNumber = e.current;
            this.pageSize = e.pageSize;
            this.getData();
        },
        // 重置
        reset(e) {
            this.pageNumber = 1;
            this.pageSize = 10;
            this.status = null; // 发票状态
            this.orderCode = null; // 关键字
            this.getData();
        },
        // 添加、编辑
        addData() {
            if (!this.modelForm.orderCode) {
                return this.$message.error("请输入订单编号");
            }
            if (!this.modelForm.fphm) {
                return this.$message.error("请输入发票号码");
            }
            this.modelLoading = true
            this.$ajax({
                url: "/hxclass-management/invoiceOffline/edit",
                method: "POST",
                params: this.modelForm,
            }).then((res) => {
                this.modelLoading = false;
                if (res.code == 200 && res.success) {
                    this.$message.success("新增成功");
                    this.visible = false
                    this.getData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 弹框取消
        handleCancel() {
            this.visible = false;
            this.modelForm = {
                orderCode: null,// 订单code
                email: null,
                fphm: null,// 发票号码
                kprq: null,// 开票时间
                price: null,// 开票金额
                status: null,// 开票状态
                taxNo: null,// 税号
                title: null,// 抬头
                id: null,
            }
        },
        // 搜索
        search() {
            this.pageNumber = 1;
            this.getData();
        },
        // 获取数据
        getData() {
            this.loading = true;
            this.$ajax({
                url: "/hxclass-management/invoiceOffline/list",
                method: "get",
                params: {
                    pageNum: this.pageNumber,
                    pageSize: this.pageSize,
                    status: this.status,
                    orderCode: this.orderCode,
                    // orderCode: "102024062516302",
                },
            }).then((res) => {
                this.loading = false;
                if (res.code == 200 && res.success) {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                }
            });
        },
    },
    // 生命周期-实例创建完成后调用
    created() {
        this.getData();
    },
    // 生命周期-实例挂载后调用
    mounted() { },
    // 生命周期-实例销毁离开后调用
    destroyed() { },
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {},
};
</script>

<style lang="less" scoped>
.datePicker {
    flex: 1;
}

.findItem {
    padding-bottom: 24px;
    display: flex;
    align-items: center;

    /deep/.ant-select {
        width: 193px !important;
        height: 29px !important;
        margin-right: 0 !important;
        margin-left: 24px !important;
        // font-size: 12px !important;
        color: #999999 !important;
        line-height: 14px !important;
        margin: 0 !important;
        margin-right: 24px !important;
    }

    /deep/.ant-input {
        width: 240px !important;
        height: 29px !important;
        // font-size: 12px !important;
        color: #999999 !important;
        line-height: 14px !important;
        margin-bottom: 0 !important;
    }

    .ant-btn {
        margin-left: 24px;
    }
}

.table-box {
    /deep/ .ant-tabs-bar {
        border-bottom: none;
    }
}

/deep/.ant-modal-footer {
    text-align: center;
    border: 0;
}

.rows {
    margin-bottom: 20px;
    display: flex;

    &:last-child {
        margin-bottom: 0;
    }

    .left {
        text-align: right;
        height: 32px;
        line-height: 32px;
        min-width: 100px;

        &::before {
            content: '*';
            color: red;
            margin-right: 2px;
        }

        &::after {
            content: '：';
        }
    }

    .left_no {
        text-align: right;
        height: 32px;
        line-height: 32px;
        min-width: 100px;

        &::after {
            content: '：';
        }
    }

    .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 32px;

        input {
            flex: 1;
        }

        a {
            margin-left: 8px;
        }
    }

    .right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ant-input,
        .ant-select,
        .ant-input-number {
            flex: 1;
        }
    }
}
</style>