<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <div>退款管理</div>
      </template>
    </HeaderBox>

    <!-- 列表搜索条件 -->
    <div class="selects findItem">
      <a-input v-model="userName" placeholder="请输入姓名" />
      <a-input v-model="mobile" placeholder="请输入手机号" />
      <a-input v-model="orderCode" placeholder="请输入订单编号" />
      <a-range-picker style="width: 220px" :placeholder="['退款开始时间', '退款结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime
        v-model="findtime"></a-range-picker>
      <a-select placeholder="数据处理状态" style="width: 200px;height: 29px;" v-model="refundStatus" allowClear>
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 退款中 </a-select-option>
        <a-select-option value="2"> 已处理 </a-select-option>
      </a-select>
      <a-select placeholder="是否换课退款" style="width: 200px;height: 29px;" v-model="changeProductFlag" allowClear>
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 是 </a-select-option>
        <a-select-option value="0"> 否 </a-select-option>
      </a-select>
      <a-button type="primary" @click="search()" v-hasPermi="['refund:lIstanbul:query']">搜索</a-button>
      <a-button class="all_btn_border btnBorder" @click="$router.push('/admin/OrderConfig/addRefund')"
        v-hasPermi="['refund:info:add']">新建退款</a-button>
    </div>

    <!-- 列表 -->
    <a-table class="table-template" :rowKey="(item) => item.orderId" :loading="loadList" :columns="columns"
      :data-source="tableData" :scroll="{ x: true }" @change="onPage" :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }">
      <template slot="index" slot-scope="item, row, index">
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="mobile" slot-scope="text, item">
        {{ item.mobile ? $encryption('empty', 'phone', item.mobile) : "--" }}
      </template>
      <template slot="productName" slot-scope="text">
        <p class="productName" :title="text">{{ text ? text : "--" }}</p>
      </template>
      <template slot="refundTime" slot-scope="text">
        {{ text ? text : "--" }}
      </template>

      <template slot="refundStatus" slot-scope="text">
        {{ text == 1 ? "退款中" : text == 2 ? "已退款" : "--" }}
      </template>

      <template slot="changeProductFlag" slot-scope="text">
        {{ text == 1 ? "是" : "否" }}
      </template>

      <template slot="source" slot-scope="text">
        {{ text ? text : "--" }}
      </template>

      <template slot="tradeId" slot-scope="text">
        {{ text ? text : "--" }}
      </template>

      <template slot="changePrice" slot-scope="text">
        {{ text ? (text > 0 ? "+" + text : text) : "--" }}
      </template>

      <template slot="operation" slot-scope="text, record">
        <div class="btn_router_link">
          <a @click="godetail(record.orderId)">详情</a>
          <span v-if="text.refundStatus == 1" v-hasPermi="['refund:info:updatestatus']">|</span>
          <a v-if="text.num > 0" v-hasPermi="['refund:info:updatestatus']" @click="refundFeedBack(record)">处理反馈</a>
        </div>
      </template>
    </a-table>

    <!-- 处理反馈 -->
    <a-modal title="处理反馈" :width="449" :closable="false" :visible="visible" :confirm-loading="confirmLoading"
      @cancel="visible = false" @ok="freeback" okText="完成退款">
      <!-- <div class="option">
        <p>是否仅退款：</p>
        <a-select
          placeholder="数据处理状态"
          v-model="form.refundsOnly"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option value="true"> 仅退款 </a-select-option>
          <a-select-option value="false"> 退款退商品 </a-select-option>
        </a-select>
      </div> -->
      <div class="option">
        <p>退款时间：</p>
        <a-date-picker show-time valueFormat="YYYY-MM-DD HH:mm:ss" class="value" v-model="form.refundTime" />
      </div>
      <div class="option">
        <p>退款凭证：</p>
        <a-upload class="value" list-type="picture-card" accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
          :showUploadList="false" :beforeUpload="beforeUpload" :customRequest="customRequest">
          <img v-if="form.refundVoucher" :src="form.refundVoucher" style="max-width:180px" alt="avatar" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </div>
      <div class="warning" v-if="modelOrderType == 4">提示：确认退款后请前往“自主学习/班级学习”中将用户有效期进行更改！！！</div>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
// import { mapMutations } from 'vuex';
const columns = [
  {
    title: "序号",
    align: "center",
    fixed: "left",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名/机构名称",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "orderCode",
  },
  {
    title: "实付款",
    align: "center",
    dataIndex: "payPrice",
  },
  {
    title: "退款商品",
    align: "center",
    dataIndex: "productName",
    scopedSlots: { customRender: "productName" },
  },
  {
    title: "退款金额",
    align: "center",
    dataIndex: "refundPrice",
  },
  {
    title: "退款时间",
    align: "center",
    dataIndex: "refundTime",
    scopedSlots: { customRender: "refundTime" },
  },
  {
    title: "是否换课退款",
    align: "center",
    dataIndex: "changeProductFlag",
    scopedSlots: { customRender: "changeProductFlag" },
  },
  {
    title: "记录人",
    align: "center",
    dataIndex: "createName"
  },
  {
    title: "退款进度",
    align: "center",
    dataIndex: "refundStatus",
    scopedSlots: { customRender: "refundStatus" },
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      loadList: false,
      userName: '', // 姓名
      mobile: '', // 手机号
      orderCode: '', // 订单编号
      findtime: [], // 时间段
      refundStatus: undefined, // 退款状态 1.退款中 2.已处理
      changeProductFlag: undefined, // 是否换课退款 1是 0否

      // 处理反馈弹框
      loading: false,
      confirmLoading: false,
      visible: false,
      form: {
        // refundsOnly: undefined, // 是否仅退款
        refundVoucher: '',
        refundTime: '',
        orderId: ''
      },
      modelOrderType: null
    }
  },
  created() {
    const Name = 'historyreundList'
    let obj = this.$store.state.historyTokeep[Name]
    if (obj) {
      this.pageNumber = obj.pageNumber;
      this.pageSize = obj.pageSize
      this.orderCode = obj.orderCode
      this.findtime[0] = obj.startTime
      this.findtime[1] = obj.endTime
      this.refundStatus = obj.refundStatus
      this.changeProductFlag = obj.changeProductFlag
      this.mobile = obj.mobile
      this.userName = obj.userName
      this.mobile = obj.mobile
    }
    this.getManageList();
  },
  methods: {
    // ...mapMutations(['setSearchConditions']),
    // 处理反馈弹框
    refundFeedBack(e) {
      this.visible = true;
      this.modelOrderType = e.orderType;
      this.form.orderId = e.orderId;
      this.form.refundVoucher = '';
      this.form.refundTime = '';
      // this.form.refundsOnly = undefined;
    },

    // 处理反馈
    freeback() {
      // if(!this.form.refundsOnly) {
      //   return this.$message.warning('请先选择是否仅退款！')
      // }else 
      if (!this.form.refundTime) {
        return this.$message.warning('请先选择退款时间！')
      } else if (!this.form.refundVoucher) {
        return this.$message.warning('请先上传退款凭证！')
      }
      this.confirmLoading = true;
      this.$ajax({
        url: "/hxclass-management/refund/feedback",
        method: "put",
        params: this.form
      }).then((res) => {
        this.confirmLoading = false;
        this.visible = false;
        if (res.code == 200 && res.success) {
          this.getManageList()
        } else if (res.code == 5000) {
          let _this = this;
          this.$confirm({
            title: res.message,
            okText: "知道了",
            type: 'warning',
            cancelButtonProps: { style: { display: 'none' } },
            okButtonProps: { style: { margin: '0 auto' } },
            onOk() {
              _this.getManageList();
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 跳转详情
    godetail(e) {
      window.open('/admin/OrderConfig/refunDetail?id=' + e)
    },
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 重置文件上传 - 单文件
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'user/transaction/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file, bucket, this.fileProgressCallback, (url, fileName) => {
        // 上传完成
        if (url) {
          this.form.refundVoucher = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 搜索
    search() {
      this.pageNumber = 1;
      this.getManageList();
    },

    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },

    // 获取列表数据
    getManageList() {
      this.loadList = true;
      this.$ajax({
        url: "/hxclass-management/refund/getRefundList",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          orderCode: this.orderCode,
          refundStatus: this.refundStatus,
          changeProductFlag: this.changeProductFlag,
          startTime: this.findtime[0] ? this.findtime[0] : "",
          endTime: this.findtime[1] ? this.findtime[1] : "",
          userName: this.userName,
          mobile: this.mobile,
        },
      }).then((res) => {
        this.loadList = false;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
      // this.setVuex();
    },
    // setVuex() {
    //   // vueX存储查询条件
    //   const obj = {
    //     modulesName: 'reundList',
    //     params: {
    //       pageNumber: this.pageNumber,
    //       pageSize: this.pageSize,
    //       orderCode: this.orderCode,
    //       refundStatus: this.refundStatus,
    //       changeProductFlag: this.changeProductFlag,
    //       startTime: this.findtime[0] ? this.findtime[0] : "",
    //       endTime: this.findtime[1] ? this.findtime[1] : "",
    //       userName: this.userName,
    //       mobile: this.mobile,
    //     }
    //   }
    //   this.setSearchConditions(obj);
    // },
  }
}
</script>

<style scoped lang="less">
.selects {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
}

.findItem {
  padding-bottom: 24px;
  display: flex;
  align-items: center;

  // /deep/.ant-form-item-control-wrapper{
  //   width: 240px !important;
  //   height: 29px !important;
  // }
  /deep/.ant-input {
    width: 200px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }

  .ant-btn {
    margin-right: 24px;
  }

  .ant-btn:last-child {
    margin-right: 0;
  }

  /deep/.ant-select-selection--single {
    height: 29px;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
}

.avatar-uploader {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .ant-upload-text {
    font-size: 12px;
    color: #666666;
    line-height: 14px;
  }
}

.option {
  display: flex;
  margin-bottom: 20px;

  p {
    width: 100px;
    text-align: right;
  }

  .value {
    flex: 1;
  }

  /deep/.ant-select {
    flex: 1;
  }
}

.option:last-child {
  margin-bottom: 0px;
}

.warning {
  color: red;
  padding-left: 100px;
}
</style>