<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        {{$isExamSearch()?'成绩查询':'成绩管理'}}
      </template>
      <template #input>
        <a-date-picker placeholder="请选择考试日期" @change="onDataChange" />
        <a-button type="primary" class="btn" @click="onSearch">搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="" @change="callback">
        <a-tab-pane v-for="(item) in tabData" :key="item.configId" :tab="item.abbreviation"></a-tab-pane>
      </a-tabs>
      <a-table
      class="table-template"
      :rowKey="item=>item.timeId"
      :columns="columns"
      :loading="loading"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.current,  
        defaultPageSize:queryParams.size, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.current - 1) * 10 + i + 1 }}
          </div>
        </template>

        <template slot="operation" slot-scope="text">
          <div class="btn_router_link">
            <router-link to @click.native="goPage(text)">查看考生成绩 </router-link>
            
            <router-link v-if="!$isExamSearch()" to @click.native="onCheckPaper(text)">| 检查试卷</router-link>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新建考生 -->
    <a-modal
      v-model="paperCheckShow"
      title="检查试卷"
      okText="确认"
      width="533px"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="line-item" v-for="(item,index) in examPaperCheckList" :key="index">
          <p class="key">{{item.key}}</p>
          <p class="value">{{item.value}}</p>
        </div>
      </div>
      <a-button type="primary" class="ask-btn" @click="paperCheckShow = false">确认</a-button>
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "考试日期",
    align:'center',
    dataIndex: "examDate"
  },
  {
    title: "考试场次",
    align:'center',
    dataIndex: "examDateTime"
  },
  {
    title: "报名人数",
    align:'center',
    dataIndex: "signupNum"
  },
  {
    title: "考生人数",
    align:'center',
    dataIndex: "examinneNum"
  },
  {
    title: "通过人数",
    align:'center',
    dataIndex: "passNum"
  },
  {
    title: "通过率",
    align:'center',
    dataIndex: "passRate"
  },
  {
    title: "操作",
    align:'center',
    width: "200px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'
// import { mapMutations } from 'vuex';

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      tableData:[], // 列表数据
      loading:false, // 列表加载
      tabName:'', // tab名称
      total: 0, // 总数量
      tabData:[],
      scorePublish:0, // 是否有主观题，1.没有  0有
      queryParams:{
        configId: 0,  //tabID,
        current: 1, // 页码
        size: 10, // 页数
        examDate:'', // 考试日期
      },
      examPaperCheckList: [],
      paperCheckShow:false,
    }
  },
  // 事件处理器
  methods: {
    // ...mapMutations(['setSearchConditions']),
    // 分页
    onPage(e){
      this.queryParams.current = e.current
      this.queryParams.size = e.pageSize
      this.getManageList()
    },
    // 高级检索
    onSearch(){
      this.queryParams.current = 1
      this.getManageList()
    },
    // tab切换
    callback(e){
      this.tabData.forEach(element => {
        if(element.configId == e){
          this.tabName = element.name
          this.scorePublish = element.scorePublish
        }
      })
      this.queryParams.configId = e
      this.getManageList()
    },

    goPage(e){
      let url=this.$isExamSearch()?"/admin/ExamSearch/ExamineeScore":"/admin/Examination/ExamineeScore";
      window.open(url+'?scorePublish='+this.scorePublish+'&signupId='+e.signupId+'&timeId='+e.timeId+'&examName='+this.tabName+'&time='+e.examDate + '&nbsp&nbsp' + e.examDateTime)
    },
    onCheckPaper(e){
      let timeJson = {
        timeId: e.timeId
      }
      this.loading = true
      this.$ajax({
        method: 'POST',
        url:"/hxclass-management/procedure/getProcedure",
        params:{
          inString: JSON.stringify(timeJson),
          pName: 'sb_checkPaperInfo'
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.examPaperCheckList = [] // 初始化
          let resultData = res.data.split('$$') // 自定义返回值
          if(resultData.length){
            let codeValue = resultData[0].split('||')  // 获取数据code
            if(codeValue.length){
              if(codeValue[0] == 0){
                let arr = resultData[1].split('##')
                if(arr.length){
                  arr.forEach(element => {
                    let item = element.split('||')
                    if(item.length){
                      this.examPaperCheckList.push({
                        key: item[0],
                        value: item[1],
                      })
                    }
                  });
                  this.paperCheckShow = true
                }
              } else {
                this.$message.error(codeValue[1]);
              }
            }
          }
        } else{
          this.$message.error(res.message);
        }
        this.loading = false
      })
    },
    onDataChange(e,v){
      this.queryParams.examDate = v
    },
    // 查询tab列表
    getTabList(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/config/exam/tab/list",
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.tabData = res.data
          this.tabName = res.data[0].name
          this.scorePublish = res.data[0].scorePublish
          this.queryParams.configId = res.data[0].configId
          this.getManageList()
        } else {
          this.tabData = []
        }
      })
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/mark/achievem/list",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
      // this.setVuex();
    },
    // setVuex() {
    //   // vueX存储查询条件
    //   const obj = {
    //     modulesName: 'gradeManage',
    //     params: {
    //       configId: this.queryParams.configId,  //tabID,
    //       current: this.queryParams.current, // 页码
    //       size: this.queryParams.size, // 页数
    //       examDate: this.queryParams.examDate, // 考试日期
    //     }
    //   }
    //   this.setSearchConditions(obj);
    // },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // const Name = 'historygradeManage'
    // let obj = this.$store.state.historyTokeep[Name]
    // if (obj) {
    //   this.queryParams.configId = obj.configId;
    //   this.queryParams.current = obj.current;
    //   this.queryParams.size = obj.size;
    //   this.queryParams.examDate = obj.examDate;
    // }
    this.getTabList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.all_content_box{
  padding: 30px 30px 0;
  .line-item{
    display: flex;
    margin-bottom: 10px;
    .key{
      flex: 1;
    }
    .value{
      min-width: 50px;
    }
  }
}

.ask-btn{
  display: block;
  margin: 20px auto 0;
}

/deep/ .ant-modal-body{
  padding: 0;
}
/deep/ .ant-modal-footer{
  text-align: center;
}
.table-template{
  margin-top: 0;
}
</style>
