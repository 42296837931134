<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Certificate/CertificateInformation"
            >新增人员 /
          </router-link>
          <span class="crumbs_item crumbs_last">添加</span>
        </span>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="content_box">
      <!-- 确认信息 -->
      <div style="margin-bottom: 60px">
        <h3 class="big_title">确认信息</h3>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">姓名：</span>
            <!-- <a-input
              v-model="userData.name"
              class="right"
              placeholder="请输入姓名"
              @pressEnter="handleSearch"
          /> -->
            <a-select
              style="width: 100%"
              show-search
              v-model="userData.name"
              mode="combobox"
              placeholder="请输入姓名"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @select="handleSelect"
              @change="handleChange"
              option-label-prop="label"
            >
              <a-select-option
                v-for="item in userList"
                :key="item.userId"
                :value="
                  item.mobile +
                  '/' +
                  item.name +
                  '/' +
                  item.idNo +
                  '/' +
                  item.sex
                "
                :label="item.name"
              >
                <template>{{ item.mobile }} / {{ item.name }}</template>
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">性别：</span>
            <a-radio-group
              v-model="userData.sex"
              name="radioGroup"
              :default-value="1"
            >
              <a-radio :value="1">男</a-radio>
              <a-radio :value="0">女</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">身份证号：</span>
            <a-input
              v-model="userData.idcard"
              class="right"
              placeholder="请输入身份证号"
          />
            <!-- <a-select
              style="width: 100%"
              show-search
              v-model="userData.idcard"
              placeholder="请输入身份证号"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @select="handleSelect"
              option-label-prop="label"
            >
              <a-select-option
                v-for="item in userList"
                :key="item.userId"
                :value="
                  item.mobile +
                  '/' +
                  item.name +
                  '/' +
                  item.idNo +
                  '/' +
                  item.sex
                "
                :label="item.idNo"
              >
                <template>{{ item.mobile }} / {{ item.name }}</template>
              </a-select-option>
            </a-select> -->
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">手机号码：</span>
            <!-- <a-input
              v-model="userData.mobile"
              class="right"
              placeholder="请输入手机号"
          /> -->
            <a-select
              style="width: 100%"
              show-search
              mode="combobox"
              placeholder="请输入手机号码"
              v-model="userData.mobile"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @select="handleSelect"
              @change="handleChange"
              option-label-prop="label"
            >
              <a-select-option
                v-for="item in userList"
                :key="item.userId"
                :value="
                  item.mobile +
                  '/' +
                  item.name +
                  '/' +
                  item.idNo +
                  '/' +
                  item.sex
                "
                :label="item.mobile"
              >
                <template>{{ item.mobile }} / {{ item.name }}</template>
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row>
          <!-- <a-col :span="7" class="book_flex"
                ><span class="left_name">职务/职称：</span
                ><a-input
                  v-model="userData.occupationName"
                  class="right"
                  placeholder="请输入职务/职称"
              /></a-col> -->
          <a-col :span="7" class="book_flex"
            ><span class="left_name">证书编号：</span
            ><a-input v-model="userData.code" class="right" placeholder=""
          /></a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">学历：</span>
            <!-- <a-input
              v-model="userData.degree"
              class="right"
              placeholder="请输入学历"
            /> -->
            <a-select
              v-model="userData.degree"
              placeholder="请选择学历"
              class="right"
            >
              <a-select-option
                :value="item"
                v-for="item in gradeList"
                :key="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">职务/职称：</span>
            <!-- <a-input
              v-model="userData.occupationName"
              class="right"
              placeholder="请输入职务/职称"
          /> -->
            <a-select
              v-model="userData.occupationName"
              placeholder="请选择职务/职称"
              class="right"
            >
              <a-select-option
                :value="item.name"
                v-for="item in jobList"
                :key="item.tempId"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">出生日期：</span
            ><a-date-picker
              format="yyyy-MM-DD"
              value-format="yyyy-MM-DD"
              v-model="userData.birthday"
              class="right"
          /></a-col>
        </a-row>
      </div>
      <!-- 照片提交方式 -->
      <div style="margin-bottom: 60px">
        <h3 class="big_title">照片提交方式</h3>
        <template>
          <a-upload
            name="avatar"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img
              v-if="userData.photo"
              :src="userData.photo"
              alt="avatar"
              width="600"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传照片</div>
            </div>
          </a-upload>
        </template>
      </div>
      <!-- 收货地址 -->
      <div>
        <h3 class="big_title">收货地址</h3>
        <a-row>
          <a-col :span="7" class="book_flex">
            <span class="left_name">收货人：</span>
            <a-input
              v-model="userData.consignee"
              class="right"
              placeholder="请输入收货人"
            />
          </a-col>
          <a-col :span="5" class="book_flex">
            <span class="left_name">手机号：</span>
            <a-input
              v-model="userData.consigneeMobile"
              class="right"
              placeholder="请输入手机号"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" class="book_flex">
            <span class="left_name">收货地址：</span>
            <a-input
              v-model="userData.consigneeAddress"
              class="right"
              placeholder="请输入详细地址"
            />
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="foot_btn">
      <a-button
        type="primary"
        class="btn"
        @click="pushData()"
        :loading="loading"
      >
        确认新增
      </a-button>
      <a-button style="margin-left: 20px" class="btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>
    
<script>
import UE from "@/components/UE.vue";

export default {
  // 可用组件的哈希表
  components: { UE },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      imageUrl: undefined,
      ueInitText: "",
      selectVisible: false, // 选择商品弹窗
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      // 用户数据
      userData: {
        birthday: null,
        degree: undefined,
        dataId: null,
        idcard: undefined,
        mobile: undefined,
        code: null,
        name: undefined,
        photo: null,
        sex: null,
        consigneeAddress: null,
        consigneeMobile: null,
        consignee: null,
      }, //用户数据

      addressValue: [],
      dataId: null, //用户id
      modelkeyword: "",
      userList: [],
      jobList: [], //职务
      gradeList: [
        "小学",
        "初中",
        "中专",
        "高中",
        "大专",
        "本科",
        "硕士研究生",
        "博士研究生",
      ], // 学历
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    // 富文本内容有变化时触发
    getContent(e) {
      this.$set(this, "ueGetText", e);
    },
    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = "user/signup/"; // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(
        fileData.file,
        bucket,
        this.fileProgressCallback,
        (url, fileName) => {
          // 上传完成
          if (url) {
            // console.log("=======" + url);
            this.userData.photo = url;
          } else {
            this.$message.error("上传失败");
          }
          this.loading = false;
        }
      );
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 上传数据
    pushData() {
      let submitData = {
        id: this.userData.id,
        tempId: this.dataId,
        consignee: this.userData.consignee,
        consigneeMobile: this.userData.consigneeMobile,
        consigneeAddress: this.userData.consigneeAddress,
        degree: this.userData.degree,
        examineeId: this.userData.examineeId,
        getcertifTime: this.userData.getcertifTime,
        occupationName: this.userData.occupationName,
        photo: this.userData.photo,
        name: this.userData.name,
        mobile: this.userData.mobile,
        idcard: this.userData.idcard,
        sex: this.userData.sex,
        birthday: this.userData.birthday,
      };
      console.log('======' + JSON.stringify(submitData));
      // 验证非空字段
      if (!submitData.name) {
        this.$message.warning("请正确填写考生姓名");
        return;
      }
      if (!submitData.mobile) {
        this.$message.warning("请正确填写考生手机号");
        return;
      }
      if (!submitData.idcard) {
        this.$message.warning("请正确填写考生身份证号");
        return;
      }
      if (submitData.sex == "null") {
        this.$message.warning("请正确选择考生性别");
        return;
      }
      if (!submitData.degree) {
        this.$message.warning("请正确选择考生学历");
        return;
      }
      if (!submitData.occupationName) {
        this.$message.warning("请正确选择考生职称");
        return;
      }
      if (!submitData.birthday) {
        this.$message.warning("请正确选择考生生日");
        return;
      }
      if (!submitData.photo) {
        this.$message.warning("请上传电子照片");
        return;
      }
      if (!submitData.consignee) {
        this.$message.warning("请正确填写考生收货人名称");
        return;
      }
      if (!submitData.consigneeMobile) {
        this.$message.warning("请正确填写考生收货人电话");
        return;
      }
    
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/certifData/addOneData",
        method: "POST",
        params: submitData,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success("新增成功");
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
          this.$router.go(-1);
        }
      });
    },
    handleSearch(e) {
      this.modelkeyword = e;
      this.userList = [];
      this.getUserList();
    },
    handleSelect(e) {
      let resultArr = e.split("/");
      this.userData.name = resultArr[1];
      this.userData.mobile = resultArr[0];
      this.userData.idcard = resultArr[2];
      this.userData.sex = resultArr[3];
    },
    handleChange(e) {
      // console.log("========" + JSON.stringify(e));
    },
    // 查询用户列表
    getUserList() {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if (!this.modelkeyword) {
          return;
        }
        let params = {
          type: 1,
          name: "",
          mobile: "",
        };
        if (!isNaN(this.modelkeyword)) {
          if (!this.$regular.phone.reg.test(this.modelkeyword)) {
            this.$message.warning(this.$regular.phone.msg);
            return;
          }
          params.mobile = this.modelkeyword;
        } else {
          params.name = this.modelkeyword;
        }
        this.$ajax({
          url: "/hxclass-management/user/info/user/info",
          method: "get",
          params,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.userList = res.data;
            if (!res.data.length) {
              this.$message.error("未搜到相关用户");
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 1000);
    },
    // 获取职务
    getjob() {
      this.$ajax({
        url: "/hxclass-management/job/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 11000,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.jobList = res.data.records;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.dataId = this.$route.query.dataId;
    this.getjob();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 自定义的侦听器
  watch: {},
};
</script>
    
<style lang="less" scoped>
a.crumbs_item {
  color: #333;
}
.content_box {
  padding: 20px 0;
  .big_title {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
.left_name {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  color: #333333;
}
// 必填标识
.required::before {
  content: "*";
  display: inline-block;
  color: red;
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.ant-row {
  margin-bottom: 20px;
}
.ant-col-2 {
  text-align: right;
  margin-right: 10px;
}
.flex_Div {
  display: flex;
  align-items: center;
  .flex_left_Div {
    text-align: center;
    .size {
      color: red;
      font-size: 14px;
    }
  }
}

// 书籍信息
.book_flex {
  display: flex;
  align-items: center;
  > span {
    display: inline-block;
    text-align: right;
    min-width: 122px;
  }
  .right {
    width: 100%;
  }
}

// 系列购买
.series_box {
  margin-top: 50px;
  max-width: 800px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: inline-block;
      width: 140px;
      text-align: right;
      color: #333333;
    }
  }
  .table {
    margin-left: 140px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .series_flex {
    display: flex;
    align-items: center;
    .center {
      width: 80px;
      margin: 0 10px;
    }
    .time {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}
.picture {
  width: 120px;
}
</style>
    