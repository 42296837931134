<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <div>发货查询</div>
      </template>
    </HeaderBox>
    <div class="selects">
      <!-- 订单编号 -->
      <a-input class="input" v-model="paramsQuery.code" placeholder="订单编号" />
      <!-- 商品名称 -->
      <a-input class="input" v-model="paramsQuery.productName" placeholder="商品名称" />
      <!-- 商品编码 -->
      <a-select placeholder="商品编码" class="input" allowClear v-model="paramsQuery.codeType">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 自产 </a-select-option>
        <a-select-option value="2"> 外采 </a-select-option>
      </a-select>
      <!-- 发货状态 -->
      <a-select class="input" mode="multiple" v-model="ShipArry" placeholder="发货状态" allowClear>
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="0"> 无物流 </a-select-option>
        <a-select-option value="1"> 待发货 </a-select-option>
        <a-select-option value="2"> 待收货 </a-select-option>
        <a-select-option value="3"> 已收货 </a-select-option>
      </a-select>

      <a-range-picker class="input" v-model="startTime" :placeholder="['发货开始时间', '发货结束时间']"
        valueFormat="YYYY-MM-DD HH:mm:ss" showTime>
      </a-range-picker>
      <a-range-picker class="input" v-model="payTime" :placeholder="['支付开始时间', '支付结束时间']"
        valueFormat="YYYY-MM-DD HH:mm:ss" showTime>
      </a-range-picker>
      <!-- 结账状态 -->
      <a-select placeholder="结账状态" v-model="paramsQuery.checkoutStatus" class="input" allowClear>
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="0">未结账 </a-select-option>
        <a-select-option value="1">已结账 </a-select-option>
      </a-select>
      <div>
        <a-button type="primary" @click="search()" v-hasPermi="['logistics:list:query']">搜索</a-button>
        <a-button type="primary" class="btnBorder" :loading="loadExport" @click="onExport" title="注：导出的数据是根据筛选条件所筛选出的数据"
          v-hasPermi="['logistics:list:export']">导出</a-button>
      </div>
    </div>

    <!-- 确认结账 -->
    <div class="ask-pay" v-hasPermi="['logistics:pay:updatestatus']" v-if="selectedRowKeys.length">
      已勾选{{ selectedRowKeys.length }}笔商品记录，是否将这{{ selectedRowKeys.length }}笔订更新成已结账，<a @click="onAsk">确认已结账</a>
    </div>

    <!-- 表格列表 -->
    <div class="table-box">
      <a-table class="table-template" :row-selection="{
        onChange: onSelect,
        selectedRowKeys: selectedRowKeys,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.checkoutStatus == 1,
          },
        }),
      }" :rowKey="(item) => item.orderProductId" :loading="loadList" :scroll="{ x: 1500 }" :columns="columns"
        :data-source="tableData" @change="onPage" :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: paramsQuery.pageNum,
          defaultPageSize: paramsQuery.pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }">

        <!-- 序号 -->
        <template slot="index" slot-scope="item, row, index">
          {{ (paramsQuery.pageNum - 1) * paramsQuery.pageSize + index + 1 }}
        </template>

        <!-- 发货日期 -->
        <template slot="deliveryTime" slot-scope="text">
          {{ text ? text : '-' }}
        </template>

        <!-- 物流单号 -->
        <template slot="logisticsNumber" slot-scope="text">
          {{ text ? text : '-' }}
        </template>

        <!-- 收货人地址 -->
        <template slot="address" slot-scope="item">
          {{ item.provinceName }}{{ item.cityName }}{{ item.districtName }}{{ item.address }}
        </template>

        <!-- 发货状态 -->
        <template slot="status" slot-scope="text">
          <span class="state_all"
            :class="text == 0 ? '' : text == 1 ? 'state_color_red' : text == 2 ? 'state_color_yellow' : text == 3 ? 'state_color_green' : ''">
            {{ text == 0 ? '无物流' : text == 1 ? '待发货' : text == 2 ? '待收货' : text == 3 ? '已收货' : '' }}</span>
        </template>

        <!-- 结账状态 -->
        <template slot="checkoutStatus" slot-scope="text">
          <span :class="text ? 'state_color_green' : 'state_color_red'">{{ text == 1 ? '已结账' : '未结账' }}</span>
        </template>

        <!-- 操作 -->
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link" v-hasPermi="['logistics:detail:query']">
            <a @click="godetail(item.orderId, item.userType)">订单详情</a>
          </div>
        </template>

      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    align: "center",
    width: 80,
    fixed: "left",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "账号（手机号）",
    width: 160,
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "收货人",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "收货电话",
    align: "center",
    dataIndex: "phone",
  },
  {
    title: "收货人地址",
    align: "center",
    scopedSlots: {
      customRender: "address",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "数量",
    align: "center",
    dataIndex: "productCount",
  },
  {
    title: "发货日期",
    align: "center",
    dataIndex: "deliveryTime",
    scopedSlots: {
      customRender: "deliveryTime",
    },
  },
  {
    title: "物流单号",
    align: "center",
    dataIndex: "logisticsNumber",
    scopedSlots: {
      customRender: "logisticsNumber",
    },
  },
  {
    title: "发货状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "支付时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "结账状态",
    align: "center",
    dataIndex: "checkoutStatus",
    scopedSlots: {
      customRender: "checkoutStatus",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  },
];

import HeaderBox from "@/components/HeaderBox.vue";
// import { mapMutations } from 'vuex';
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns,
      tableData: [],
      loadList: false,
      loadExport: false,
      startTime: [], // 发货时间
      payTime: [], // 支付时间
      selectedRowKeys: [],
      total: 0,
      ShipArry: [],
      paramsQuery: {  // 查询条件
        pageNum: 1, // 页码
        pageSize: 10,  // 页数
        code: '',  // 	订单编码
        codeType: undefined,  // 	商品编码
        productName: '',  // 商品名称
        status: [],  // 发货状态
        startTime: '',  // 发货开始时间
        endTime: '',  // 发货结束时间
        payStartTime: '', // 支付开始时间
        payEndTime: '', // 支付结束时间
        checkoutStatus: undefined,  // 	结账状态0 未结 1 已结
      }
    };
  },
  // 事件处理器
  methods: {
    // ...mapMutations(['setSearchConditions']),
    // 选择未分布学员
    onSelect(e, v) {
      this.selectedRowKeys = e
    },
    // 分页
    onPage(e) {
      this.paramsQuery.pageNum = e.current;
      this.paramsQuery.pageSize = e.pageSize;
      this.getManageList();
    },
    // 搜索
    search() {
      this.paramsQuery.pageNum = 1;
      this.getManageList();
    },

    // 获取列表数据
    getManageList() {
      this.paramsQuery.startTime = (this.startTime.length ? this.startTime[0] : null) // 发货开始时间
      this.paramsQuery.endTime = (this.startTime.length ? this.startTime[1] : null)  // 发货结束时间
      this.paramsQuery.payStartTime = (this.payTime.length ? this.payTime[0] : null)  // 支付开始时间
      this.paramsQuery.payEndTime = (this.payTime.length ? this.payTime[1] : null)  // 支付结束时间
      this.paramsQuery.status = (this.ShipArry.length ? this.ShipArry : null)  // 支付结束时间
      this.loadList = true;
      this.$ajax({
        url: "/hxclass-management/order/product/realLogistics",
        method: "POST",
        params: this.paramsQuery,
      }).then((res) => {
        this.loadList = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      });
      // this.setVuex();
    },
    // setVuex() {
    //   // vueX存储查询条件
    //   const obj = {
    //     modulesName: 'goodsSearch',
    //     params: {
    //       startTime: (this.startTime.length ? this.startTime[0] : null), // 发货开始时间
    //       endTime: (this.startTime.length ? this.startTime[1] : null),  // 发货结束时间
    //       payStartTime: (this.payTime.length ? this.payTime[0] : null), // 支付开始时间
    //       payEndTime: (this.payTime.length ? this.payTime[1] : null),  // 支付结束时间
    //       status: (this.ShipArry.length ? this.ShipArry : null),  // 支付结束时间
    //       pageNum: this.paramsQuery.pageNum,
    //       pageSize: this.paramsQuery.pageSize,
    //       code: this.paramsQuery.code,
    //       codeType: this.paramsQuery.codeType,
    //       productName: this.paramsQuery.productName,
    //       checkoutStatus: this.paramsQuery.checkoutStatus,

    //     }
    //   }
    //   this.setSearchConditions(obj);
    // },
    // 确认结账
    onAsk() {
      if (!this.selectedRowKeys.length) {
        this.$message.error('请至少勾选一个订单');
        return
      }
      if (this.loadList == true) {
        return
      }
      let _this = this
      this.$confirm({
        title: '确认将该勾选的订单已结账吗?',
        okText: '确认',
        onOk() {
          _this.loadList = true
          _this.$ajax({
            url: "/hxclass-management/order/product/realLogistics/checkout/" + _this.selectedRowKeys,
            method: "post",
          }).then((res) => {
            _this.loadList = false
            if (res.code == 200 && res.success) {
              _this.$message.success('操作成功');
              _this.selectedRowKeys = []
              _this.paramsQuery.pageNum = 1;
              _this.getManageList()
            }
          })
        },
        onCancel() { },
      });
    },

    // 导出功能
    onExport() {
      this.loadList = true;
      this.loadExport = true;
      this.$ajax({
        url: "/hxclass-management/order/product/realLogistics/export",
        method: "POST",
        responseType: "blob",
        params: this.paramsQuery,
      }).then((res) => {
        this.loadList = false;
        this.loadExport = false;
        let objectUrl = window.URL.createObjectURL(new Blob([res]));
        const elink = document.createElement("a");
        elink.download = `发货单.xlsx`; //下载文件名称,
        elink.style.display = "none";
        elink.href = objectUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        window.URL.revokeObjectURL(elink.href);
      });
    },

    // 跳转
    godetail(e, r) {
      if (r == 2) {
        window.open("/admin/OrderConfig/orgOrderDetail?orderId=" + e);
      } else {
        window.open("/admin/OrderConfig/OrderDetail?orderId=" + e + '&flag=2');
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // const Name = 'historygoodsSearch'
    // let obj = this.$store.state.historyTokeep[Name]
    // if (obj) {
    //   this.paramsQuery.pageNum = obj.pageNum;
    //   this.paramsQuery.pageSize = obj.pageSize
    //   this.ShipArry = (obj.status && obj.status.length) ? obj.status : [];
    //   this.startTime[0] = obj.startTime
    //   this.startTime[1] = obj.endTime
    //   this.payTime[0] = obj.payStartTime
    //   this.payTime[1] = obj.payEndTime
    //   this.paramsQuery.code = obj.code
    //   this.paramsQuery.codeType = obj.codeType
    //   this.paramsQuery.productName = obj.productName
    //   this.paramsQuery.checkoutStatus = obj.checkoutStatus
    // }
    
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.selects {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;

  .input {
    width: 240px;
    margin-bottom: 20px;
    margin-right: 30px;
  }
}

.btnBorder {
  margin-left: 15px;
}

.ask-pay {
  margin-bottom: 15px;
  color: #333333;
}

/deep/ .table-template {
  margin-top: 5px;
}
</style>
