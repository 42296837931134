<template>
  <div>
    <h4 class="page-title">学习辅助</h4>
    <a-row class="rows" v-if="!isOffline">
      <a-col class="left required" span="3">开班设置</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.setOpenClass">
          <a-radio :disabled="userStudy == '1'" value="0">无需设置开班时间</a-radio>
          <a-radio :disabled="userStudy == '1'" value="1">需要设置开班时间</a-radio>
        </a-radio-group>
        <a-button type="primary" v-if="formData.setOpenClass == '1'" @click="$refs.Modal.open({
          title: '设置开班时间',
          width: '500px',
          type: 'SelectOpenClass',
          courseId: formData.courseId
        })">新增班级</a-button>
      </a-col>
    </a-row>
    <a-row class="rows" v-if="formData.setOpenClass == '1' && !isOffline" style="margin-top:0;">
      <a-col class="right" span="19" :offset="3">
        <a-table class="table-template" @change="onOpenTimePage" :rowKey="item => item.uid" :columns="columns1"
          :data-source="formData.courseOpenTimeList" :pagination="{
            total: total,
            current: openTimeNumber,
            pageSizeOptions: ['5', '10', '20', '50'],
            defaultPageSize: openTimePageSize,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${total}条`
            }
          }">
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ (openTimeNumber - 1) * openTimePageSize + i + 1 }}
            </div>
          </template>

          <template slot="operation" slot-scope="item, row">
            <div class="btn_router_link" v-if="userStudy != '1' || !row.openTimeId">
              <a @click="$refs.Modal.open({
                title: '设置开班时间',
                width: '500px',
                type: 'SelectOpenClass',
                state: 2,
                editItem: row,
                courseId: formData.courseId
              }, row)">编辑</a>
              <span>|</span>
              <a @click="removeSelectOpenClass(row.uid)">删除</a>
            </div>
            <span v-else>-</span>
          </template>
        </a-table>
      </a-col>
    </a-row>
    <template v-if="isOffline">
      <a-row class="rows">
        <a-col class="left" :class="isOffline ? 'required' : ''" span="3">培训场次</a-col>
        <a-col class="right" span="20">
          <span class="fontSize" style="color:#999;">(该设置仅针对课程类别为线下培训时有效)</span>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="right" span="19" offset="3">
          <a-radio-group v-model="formData.offlineSameAddr">
            <a-radio value="1">所有场次在同一地点</a-radio>
            <template v-if="formData.offlineSameAddr == '1'">
              <Province ref="Province" @regionCity='FunProvince' :value="addressValue"></Province>
              <a-input style="width:240px;margin-left:8px;" v-model="ProvinceAddress" placeholder="请输入详细地址" />
              <a-button type="primary" style="margin-left:8px;" @click="beforeOpenModal">新增场次</a-button>
              <div>
                <a-table class="table-template" :rowKey="item => item.uid" :columns="columns2"
                  :data-source="returnFixedPlaceTrainingList" style="margin-top:10px;">
                  <template slot="operation" slot-scope="item, row">
                    <!-- 此处修改开班之后也可修改名额数量 -->
                    <!-- <template v-if="row.number == row.currentNumber"> -->
                    <a @click="$refs.Modal.open({
                      title: '编辑场次',
                      width: '500px',
                      type: 'FixedPlaceTraining',
                      state: 2,
                      editItem: row,
                      courseId: formData.courseId
                    }, row)">编辑</a>
                    <template v-if="row.number == row.currentNumber">
                      <span> | </span>
                      <a @click="removeFixedPlaceTraining('FixedPlaceTrainingList', row.openTimeId)">删除</a>
                    </template>
                    <!-- </template> -->
                    <!-- <span v-else>-</span> -->
                  </template>
                </a-table>
              </div>
            </template>
            <a-radio value="0">培训场次在不同地点</a-radio>
            <template v-if="formData.offlineSameAddr == '0'">
              <a-button type="primary" style="margin-left:8px;" @click="$refs.Modal.open({
                title: '新增场次',
                width: '500px',
                type: 'NonFixedPlaceTraining',
                courseId: formData.courseId
              })">新增场次</a-button>
              <div>
                <a-table style="margin-top:10px;" class="table-template" :rowKey="item => item.uid" :columns="columns3"
                  :data-source="returnNonFixedPlaceTrainingList">
                  <template slot="operation" slot-scope="item, row">
                    <a @click="$refs.Modal.open({
                      title: '新增场次',
                      width: '500px',
                      type: 'NonFixedPlaceTraining',
                      state: 2,
                      editItem: row,
                      courseId: formData.courseId
                    }, row)">编辑</a>
                    <span> | </span>
                    <a @click="removeFixedPlaceTraining('NonFixedPlaceTrainingList', row.openTimeId)">删除</a>
                  </template>
                </a-table>
              </div>
            </template>
          </a-radio-group>
        </a-col>
      </a-row>
    </template>
    <a-row class="rows">
      <a-col class="left" span="3">课件维护</a-col>
    </a-row>
    <a-row class="rows" v-if="1 == '1'" style="margin-top:0;">
      <a-col class="right" style="margin-top: -30px" span="29" :offset="3">
        <Courseware :courseId="formData.courseId" />
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col span="20" offset="4">
        <a-button v-if="$route.query.status == 1" @click="onStorageClick" :loading="loadingBtn" type="primary"
          style="margin-right: 30px">暂存</a-button>
        <a-button :loading="loadingBtn" @click="onSaveClick">保存</a-button>
      </a-col>
    </a-row>
    <!-- 弹出窗 -->
    <Modal ref="Modal" @ok="onModal" />
  </div>
</template>

<script>
import Courseware from "./Courseware.vue"
import config from '../CourseDetails/config.vue'
import { uuid } from "@/unit/fun.js";
export default {
  mixins: [config],
  // 可用组件的哈希表
  components: { Courseware },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 开班时间分页
      openTimeNumber: 1,
      openTimePageSize: 5, //条数
      isOffline: false,
      loadingBtn: false,
      delSelectOpenClassList: [],

      formData: {
        courseId: 0, // 课程id
        offlineSameAddr: '1', // 线下培训场次是否在同一地点1.是 0.否
        setOpenClass: '0', // 是否设置开班时间1.是 0.否
        courseOpenTimeList: [], // 课程开班时间,示例值([ { "type": 1, "className": "第一期", "startTime": "2022-03-15", "endTime": "2022-04-15", "number": 50, "province": "100000", "city": "100000", "district": "100000", "address": "怡和阳光大厦" } ])	
        saveType: 1, // 保存类型(0-未填写，1-暂存，2-提交)
      },
      saveType: false, // 提交状态  ture: 提交过 false:未提交过
    }
  },
  // 事件处理器
  methods: {
    // 线下培训课程，新建班期前，添加地点是否填写校验
    beforeOpenModal() {
      if (!this.addressValue || !this.ProvinceAddress) {
        this.$message.warning('请先选择培训地点')
        return
      }
      this.$refs.Modal.open({
        title: '新增场次',
        width: '500px',
        type: 'FixedPlaceTraining',
        courseId: this.formData.courseId
      });
    },
    // 切换开班时间
    onOpenTimePage(e) {
      this.openTimeNumber = e.current
      this.openTimePageSize = e.pageSize
    },

    // 暂存检查Form表单并返回object
    fun_StorageVerific() {
      const data = this.formData
      let obj = new Object()
      let arr = []

      // 课程id
      obj.courseId = data.courseId

      // 开班设置
      obj.setOpenClass = data.setOpenClass

      // 开班设置 - 需要设置开班时间
      // arr = []
      // if (this.isOffline) { // 线下开课
      //   if (data.offlineSameAddr == '1') { // 同一地点
      //     this.FixedPlaceTrainingList.forEach(item => {
      //       item.type = 2
      //       item.province = this.addressValue[0]
      //       item.city = this.addressValue[1]
      //       item.district = this.addressValue[2]
      //       item.address = this.ProvinceAddress
      //       arr.push(item)
      //     })
      //   } else if (data.offlineSameAddr == '0') { // 不同地点
      //     this.NonFixedPlaceTrainingList.forEach(item => {
      //       let addressValue = item.addressValue
      //       item.province = addressValue[0]
      //       item.city = addressValue[1]
      //       item.district = addressValue[2]
      //       item.type = 2
      //       arr.push(item)
      //     })
      //   }
      // } else { // 线上开课
      //   data.courseOpenTimeList.forEach(item => {
      //     item.type = 1
      //     arr.push(item)
      //   })
      //   arr = [...arr, ...this.delSelectOpenClassList]
      // }
      // obj.courseOpenTimeList = arr

      // 培训场次地点
      obj.offlineSameAddr = data.offlineSameAddr

      return Promise.resolve(obj)
    },

    // 提交检查Form表单并返回object
    fun_SaveVerific() {
      const data = this.formData
      let obj = new Object()
      let arr = []

      // 课程id
      obj.courseId = data.courseId

      // 开班设置
      obj.setOpenClass = data.setOpenClass

      // 开班设置 - 需要设置开班时间
      // arr = []
      // if (this.isOffline) { // 线下开课
      //   if (data.offlineSameAddr == '1') { // 同一地点
      //     if (!this.addressValue[0]) {
      //       return Promise.reject({ msg: '请选择培训场次地点' })
      //     } else if (!this.ProvinceAddress) {
      //       return Promise.reject({ msg: '请输入培训场次详细地址' })
      //     } else if (this.FixedPlaceTrainingList && !this.FixedPlaceTrainingList.length) {
      //       return Promise.reject({ msg: '请添加培训场次' })
      //     } else {
      //       this.FixedPlaceTrainingList.forEach(item => {
      //         item.type = 2
      //         item.province = this.addressValue[0]
      //         item.city = this.addressValue[1]
      //         item.district = this.addressValue[2]
      //         item.address = this.ProvinceAddress
      //         arr.push(item)
      //       })
      //     }
      //   } else if (data.offlineSameAddr == '0') { // 不同地点
      //     if (this.NonFixedPlaceTrainingList && !this.NonFixedPlaceTrainingList.length) {
      //       return Promise.reject({ msg: '请添加培训场次' })
      //     }
      //     this.NonFixedPlaceTrainingList.forEach(item => {
      //       let addressValue = item.addressValue
      //       item.province = addressValue[0]
      //       item.city = addressValue[1]
      //       item.district = addressValue[2]
      //       item.type = 2
      //       arr.push(item)
      //     })
      //   }
      //   obj.setOpenClass = 1;
      // } else { // 线上开课
      //   if (data.courseOpenTimeList && !data.courseOpenTimeList.length && data.setOpenClass == "1") {
      //     return Promise.reject({ msg: '请添加开班班级' })
      //   }
      //   data.courseOpenTimeList.forEach(item => {
      //     item.type = 1
      //     arr.push(item)
      //   })
      //   arr = [...arr, ...this.delSelectOpenClassList]
      // }
      // obj.courseOpenTimeList = arr

      // 培训场次地点
      obj.offlineSameAddr = data.offlineSameAddr

      return Promise.resolve(obj)
    },

    // 组装返现表单数据
    fun_detail(data) {
      let obj = {};
      let arr = [];

      // 课程id
      obj.courseId = data.courseId

      // 开班设置
      obj.setOpenClass = String(data.setOpenClass);

      // 培训场次地点
      obj.offlineSameAddr = String(data.offlineSameAddr);

      // 开班设置 - 需要设置开班时间
      if (this.isOffline) {

        // 线下开课
        if (data.offlineSameAddr == 1) {
          // 在同一场次地点
          arr = [];
          let addressValue = [];
          if (data.openTimeList.length) {
            addressValue[0] = data.openTimeList[0].province;
            addressValue[1] = data.openTimeList[0].city;
            addressValue[2] = data.openTimeList[0].district;
            this.addressValue = addressValue;
            data.openTimeList.forEach((item) => {
              if (item.type == 2) {
                item.uid = uuid();
                item.addressValue = addressValue;
                arr.push(item);
              }
            });
            this.FixedPlaceTrainingList = arr;

            // 省市区详细地址
            if (obj.offlineSameAddr == 1) {
              let item = this.FixedPlaceTrainingList[0];
              this.addressValue = [item.province, item.city, item.district];
              this.ProvinceAddress = item.address;
            }
          } else {
            this.FixedPlaceTrainingList = []
            this.addressValue = []
            this.ProvinceAddress = '';
          }
        } else {
          // 不在同一场次地点
          arr = [];
          if (data.openTimeList.length) {
            data.openTimeList.forEach((item) => {
              if (item.type == 2) {
                let addressValue = [];
                let Province = '';
                item.uid = uuid();
                addressValue[0] = item.province;
                addressValue[1] = item.city;
                addressValue[2] = item.district;
                Province = item.provinceTxt ? item.provinceTxt : ''
                Province += item.cityTxt ? '/' + item.cityTxt : ''
                Province += item.districtTxt ? '/' + item.districtTxt : ''
                Province += item.address ? ' ' + item.address : ''
                item.Province = Province
                item.addressValue = addressValue;
                arr.push(item);
              }
            });
          
          }
          this.NonFixedPlaceTrainingList = arr;
        }
      } else {
        // 线上开课
        arr = [];
        data.openTimeList.forEach((item) => {
          if (item.type == 1) {
            item.uid = uuid();
            item.disabled = !!Number(item.openTimeId);
            arr.push(item);
          }
        });
        obj.courseOpenTimeList = arr;
      }


      return obj
    },

    // 暂存
    onStorageClick() {
      this.loadingBtn = true
      Promise.all([
        this.fun_StorageVerific()
      ]).then(res => {
        let obj = res[0]
        obj.saveType = 1
        this.onSubmitClick(obj)
      }).catch(err => {
        if (err) {
          this.$message.warning(err.msg)
        } else {
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    // 提交
    onSaveClick() {
      this.loadingBtn = true
      Promise.all([
        this.fun_SaveVerific()
      ]).then(res => {
        let obj = res[0]
        obj.saveType = 2
        this.onSubmitClick(obj)
      }).catch(err => {
        if (err) {
          this.$message.warning(err.msg)
        } else {
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    onSubmitClick(obj) {
      this.loadingBtn = true
      let url = ''
      if (this.saveType) {
        url = "/hxclass-management/course/updateStudyAssist"
      } else {
        url = "/hxclass-management/course/saveStudyAssist"
      }
      this.$message.loading({ content: "课程信息正在存储...", duration: 0 });
      this.$ajax({
        url: url,
        method: "POST",
        params: obj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success((obj.saveType == 1 ? '暂存' : '保存') + '成功!')
          this.loadingBtn = false

          this.saveType = true
          // 向父页面更新目录修改状态
          this.$parent.onChildInit('studyAssist', obj.saveType);
          this.getFormDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询详情
    getFormDetail() {
      this.loadingBtn = true
      this.$message.loading({ content: "正在查询详情...", duration: 0 });
      this.$ajax({
        url: "/hxclass-management/course/getStudyAssist",
        params: {
          courseId: this.formData.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.isOffline = res.data.courseClass == 2  // 是否线下课

          this.saveType = res.data.saveType ? true : false
          if (this.saveType) {
            this.formData = this.fun_detail(res.data);
          }

          this.$message.success("查询成功!");
        } else {
          this.$message.error(res.message);
        }
        this.loadingBtn = false
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() { },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.formData.courseId = Number(this.$route.query.id)
      this.getFormDetail();
    });
  },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>
