<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/ExamSeting"
            >新建考试 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{
            this.configId ? "编辑考试" : "新建考试"
          }}</span>
        </span>
      </div>
    </div>
    <a-form
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      @submit="handleSubmit"
    >
      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="考试名称：">
            <a-input
              autoComplete="off"
              placeholder="请输入考试名称"
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '请输入考试名称' }] },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="考试简称：">
            <a-input
              autoComplete="off"
              placeholder="请输入考试简称"
              v-decorator="[
                'abbreviation',
                { rules: [{ required: true, message: '请输入考试简称' }] },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="关联课程" required>
            <p class="title" @click="selectCoursevisible = true">选择课程</p>
            <div class="selectitem" v-show="enterselectedcourseList.length" v-for="(item,index) in enterselectedcourseList" :key="item.courseId">
              <p class="name">{{ item.courseName }}</p>
              <p
                class="del"
                @click="deleteItem(index)"
              >
                删除
              </p>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="关联试卷：" required>
            <p class="title" @click="selectPapervisible = true">选择试卷</p>
            <div class="selectitem" v-show="paperName">
              <p class="name">{{ paperName }}</p>
              <p
                class="del"
                @click="(paperName = ''), (selectedpaperRowKeys = [])"
              >
                删除
              </p>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <!-- 功能暂隐藏 -->
      <!-- <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="关联考试证书：" required>
            <p class="title" @click="selectcertificatevisible = true">
              选择考试证书
            </p>
            <div class="selectitem" v-show="certificateName">
              <p class="name">{{ certificateName }}</p>
              <p
                class="del"
                @click="
                  (certificateName = ''), (selectedcertificateRowKeys = [])
                "
              >
                删除
              </p>
            </div>
          </a-form-item>
        </a-col>
      </a-row> -->

      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="考试时长">
            <a-time-picker
              style="width: 100%"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="changeTime"
              valueFormat="HH:mm:ss"
              v-decorator="[
                'duration',
                { rules: [{ required: true, message: '请选择时间' }] },
              ]"
            >
              <a-icon slot="suffixIcon" type="smile" />
            </a-time-picker>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="选择收货地址：">
            <div class="radios">
              <a-radio-group
                v-decorator="[
                  'address',
                  { rules: [{ required: true, message: '请选择收货地址' }] },
                ]"
              >
                <a-radio
                  class="radios_xs"
                  :value="item.addressId"
                  v-for="item in addressList"
                  :key="item.addressId"
                >
                  <span>{{ item.pcaName }}{{ item.address }}</span>
                </a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="14" offset="1">
          <a-form-item label="补考设置：">
            <div>
              <a-radio-group
                name="radioGroup"
                v-decorator="[
                  'isMake',
                  { rules: [{ required: true, message: '请选择补考设置' }] },
                ]"
              >
                <a-radio :value="0"> 不可补考 </a-radio>
                <a-radio :value="1">可补考 </a-radio>
              </a-radio-group>
            </div>
            <div
              class="ifbox"
              v-if="form.getFieldsValue(['isMake']).isMake == 1"
            >
              <span
                >免费补考：
                <a-input-number
                  id="inputNumber"
                  v-decorator="['makeFreeNum']"
                  :min="0"
                  :max="100"
                />次</span
              >
              <span>
                收费补考：补考金额
                <a-input-number id="inputNumber" v-decorator="['makePrice']" />
                补考次数
                <a-input-number
                  id="inputNumber"
                  v-decorator="['makeNum']"
                  :min="1"
                  :max="100"
                />
                课程延期天数
                <a-input-number
                  id="inputNumber"
                  v-decorator="['delaysNum']"
                  :min="0"
                />
              </span>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="23" offset="1">
          <a-form-item label="客服电话：" required>
            <p class="title" @click="newbuilt()">新建联系人</p>
            <a-table
              class="table-template"
              :rowKey="(item, index) => index"
              :columns="tellcolumns"
              :data-source="tellJson"
              @change="ontellPage"
              :pagination="{
                total: tell.total,
                current: tell.pageNumber,
                defaultPageSize: tell.pageSize,
                showSizeChanger: true,
                showTotal: function (total, range) {
                  return `共${total}条`;
                },
              }"
            >
              <template slot="index" slot-scope="item, row, i">
                <div style="text-align: center">
                  {{ (tell.pageNumber - 1) * tell.pageSize + i + 1 }}
                </div>
              </template>

              <template slot="operation" slot-scope="text, record, index">
                <div class="btn_router_link">
                  <router-link to @click.native="edittellItem(index)"
                    >编辑</router-link
                  >
                  <span>|</span>
                  <router-link to @click.native="deltell(index)"
                    >删除</router-link
                  >
                  <span
                    v-show="(tell.pageNumber - 1) * tell.pageSize + index !== 0"
                    >|</span
                  >
                  <router-link
                    to
                    v-show="(tell.pageNumber - 1) * tell.pageSize + index !== 0"
                    @click.native="tellUp(index)"
                    >上移</router-link
                  >
                  <span
                    v-show="
                      (tell.pageNumber - 1) * tell.pageSize + index !==
                      tellJson.length - 1
                    "
                    >|</span
                  >
                  <router-link
                    to
                    v-show="
                      (tell.pageNumber - 1) * tell.pageSize + index !==
                      tellJson.length - 1
                    "
                    @click.native="tellDown(index)"
                    >下移</router-link
                  >
                </div>
              </template>
            </a-table>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="提前入场时间：">
            <!-- v-if="form.getFieldsValue(['isShelf']).isShelf == 2" -->
            <a-time-picker
              style="width: 100%"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              valueFormat="HH:mm:ss"
              v-decorator="[
                'advanceTime',
                { rules: [{ required: true, message: '请选择提前入场时间' }] },
              ]"
            >
              <!-- <a-icon slot="suffixIcon" type="smile" /> -->
            </a-time-picker>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="23" offset="1">
          <a-form-item label="考试成绩计算设置：" required>
            <div style="font-size: 10px; color: #ff3838">
              <span class="title" style="margin-right: 20px" @click="addGrade"
                >添加成绩字段</span
              >
              示例：考试成绩 x 80% + 小组成绩 x 10%
            </div>
            <div class="dlex">
              <div v-for="(item, index) in calcurlesJson" :key="index">
                <a-select
                  placeholder="请选择成绩字段"
                  style="width: 140px"
                  v-model="item.markId"
                >
                  <a-select-option
                    :value="item.configId"
                    v-for="item in examMackConfigList"
                    :key="item.markId"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
                X
                <a-input-number
                  id="inputNumber"
                  placeholder="请输入占比"
                  v-model="item.val"
                  :min="1"
                  :max="100"
                />%{{ index == calcurlesJson.length - 1 ? " = 总成绩" : " + " }}
              </div>
              <!-- <a-select
                placeholder="请选择成绩字段"
                style="width: 140px"
                v-decorator="[
                  'markIdone',
                  { rules: [{ required: true, message: '请选择提前入场时间' }] },
                ]"
              >
                <a-select-option :value="item.configId" v-for="(item,index) in examMackConfigList" :key="index">{{item.name}}</a-select-option>
              </a-select>
              X
              <a-input-number
                id="inputNumber"
                placeholder="请输入占比"
                v-decorator="[
                  'valone',
                  { rules: [{ required: true, message: '请输入占比' }] },
                ]"
                :min="1"
                :max="100"
              />% +
              <a-select
                placeholder="请选择成绩字段"
                style="width: 140px"
                v-decorator="[
                  'markIdtwo',
                  { rules: [{ required: true, message: '请选择提前入场时间' }] },
                ]"
              >
                <a-select-option :value="item.configId" v-for="(item,index) in examMackConfigList" :key="index">{{item.name}}</a-select-option>
              </a-select>
              X
              <a-input-number
                id="inputNumber"
                placeholder="请输入占比"
                v-decorator="[
                  'valtwo',
                  { rules: [{ required: true, message: '请输入占比' }] },
                ]"
                :min="1"
                :max="100"
              />% -->
            </div>
            <div></div>
            <div class="dlex">
              <span>终端展示成绩字段：</span>
              <a-checkbox-group @change="onChanges" v-model="selshowarr">
                <a-checkbox :key="99" :value="99">
                  <a-select style="width: 140px" default-value="3" disabled>
                    <span slot="suffixIcon"></span>
                    <a-select-option value="3"> 总成绩 </a-select-option>
                  </a-select>
                </a-checkbox>
                <a-checkbox
                  v-for="item in calcurlesJson"
                  :key="item.markId"
                  :value="item.markId"
                  v-show="item.markId != null"
                >
                  <a-select style="width: 140px" v-model="item.markId" disabled>
                    <span slot="suffixIcon"></span>
                    <a-select-option
                      :value="item.configId"
                      v-for="item in examMackConfigList"
                      :key="item.markId"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-checkbox>
              </a-checkbox-group>
            </div>
            <div>
              设置考试通过成绩
              <a-input-number
                id="inputNumber"
                placeholder=""
                v-decorator="['passScore']"
                :min="1"
                :max="100"
              />
              分
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="14" offset="1">
          <a-form-item label="算分配置：">
            <div>
              <a-radio-group
                name="radioGroup"
                v-decorator="[
                  'scorePublish',
                  { rules: [{ required: true, message: '请配置算分规则' }] },
                ]"
              >
                <a-radio :value="1">客观考试后算分</a-radio>
                <a-radio :value="0">成绩发布后算分 </a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="14" offset="1">
          <a-form-item label="证件照提交方式：">
            <div>
              <a-radio-group
                name="radioGroup"
                v-decorator="[
                  'verifyType',
                  { rules: [{ required: true, message: '请配置证件照提交方式' }] },
                ]"
              >
                <a-radio :value="0">纸质证件照</a-radio>
                <a-radio :value="1">电子证件照 </a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="rows">
        <a-col class="left required" span="2">全年考试安排</a-col>
        <a-col class="right" span="8">
          <div>
            <a-upload
              v-decorator="[
                'yearlyPlanPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="yearlyPlanPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                style="max-height: 100px; max-width: 100px"
                class="uploadImg"
                v-if="yearlyPlanPicture"
                :src="yearlyPlanPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
          <!-- <div class="Tips">建议尺寸：748*262px，最多可传一张</div> -->
        </a-col>
      </a-row>

      <a-row class="rows">
        <a-col class="left required" span="3">考试须知（web端）</a-col>
        <a-col class="right" span="16">
          <UE
            editorId="introduceEditor2"
            :initContent="text.webExamDesc"
            @input="getwebExamDesc"
          />
        </a-col>
      </a-row>

      <a-row class="rows">
        <a-col class="left required" span="3">考试须知（移动端）</a-col>
        <a-col class="right" span="16">
          <UE
            editorId="introduceEditor3"
            :initContent="text.appExamDesc"
            @input="getappExamDesc"
          />
        </a-col>
      </a-row>

      <a-row class="rows">
        <a-col class="left required" span="3">报名须知（移动端）</a-col>
        <a-col class="right" span="16">
          <UE
            editorId="introduceEditor4"
            :initContent="text.appSignupDesc"
            @input="getappSignupDesc"
          />
        </a-col>
      </a-row>

      <a-row class="rows">
        <a-col class="left required" span="3">报名须知（web端）</a-col>
        <a-col class="right" span="16">
          <UE
            editorId="introduceEditor5"
            :initContent="text.webSignupDesc"
            @input="getwebSignupDesc"
          />
        </a-col>
      </a-row>

      <a-row class="rows">
        <a-col :span="15" offset="1">
          <a-form-item label="考试须知（考试端）">
            <a-textarea
              autoComplete="off"
              :rows="6"
              placeholder="考试须知（考试端），输入文案请以#分割"
              v-decorator="[
                'examSystemDesc',
                { rules: [{ required: true, message: '请输入考试须知（考试端）' }] },
              ]"
              allow-clear 
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- <a-row class="rows">
        <a-col class="left required" span="2">报考指南图片</a-col>
        <a-col class="right" span="8">
          <div>
            <a-upload
              v-decorator="[
                'guideBackimgPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="guideBackimgPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                style="max-height: 100px; max-width: 100px"
                class="uploadImg"
                v-if="guideBackimgPicture"
                :src="guideBackimgPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸：748*262px，最多可传一张</div>
        </a-col>
      </a-row> -->

      <a-row>
        <a-col :span="23" offset="1">
          <a-form-item label="报考指南说明：" required>
            <p class="title" @click="addbtn()">新建问题</p>
            <!-- <a-button
              type="primary"
              class="addQuesbtn"
              @click="addQuestionvisible = true"
            >
              
            </a-button> -->
            <a-table
              class="table-template"
              :rowKey="(item, index) => index"
              :columns="columns"
              :data-source="guideJson"
              @change="onPage"
              :pagination="{
                total: total,
                current: pageNumber,
                defaultPageSize: pageSize,
                showSizeChanger: true,
                showTotal: function (total, range) {
                  return `共${total}条`;
                },
              }"
            >
              <template slot="index" slot-scope="item, row, i">
                <div style="text-align: center">
                  {{ (pageNumber - 1) * pageSize + i + 1 }}
                </div>
              </template>

              <template slot="operation" slot-scope="text, record, index">
                <div class="btn_router_link">
                  <router-link to @click.native="editQuestionItem(index)"
                    >编辑</router-link
                  >
                  <span>|</span>
                  <router-link to @click.native="delQuestion(index)"
                    >删除</router-link
                  >
                  <span v-show="(pageNumber - 1) * pageSize + index !== 0"
                    >|</span
                  >
                  <router-link
                    to
                    v-show="(pageNumber - 1) * pageSize + index !== 0"
                    @click.native="questionUp(index)"
                    >上移</router-link
                  >
                  <span
                    v-show="
                      (pageNumber - 1) * pageSize + index !==
                      guideJson.length - 1
                    "
                    >|</span
                  >
                  <router-link
                    to
                    v-show="
                      (pageNumber - 1) * pageSize + index !==
                      guideJson.length - 1
                    "
                    @click.native="questionDown(index)"
                    >下移</router-link
                  >
                </div>
              </template>
            </a-table>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- 报考说明json -->
      <!-- <div v-for="(item,index) in guideJson" :key="index">
        <a-row>
          <a-col :span="11" offset="1">
            <a-form-item label="标题" required>
              <a-input style="width: 511px" v-model="item.name" placeholder="请输入标题" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="11" offset="1">
            <a-form-item label="内容" required>
              <a-textarea
                style="width: 511px"
                v-model="item.context"
                placeholder="请输入内容"
                :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div> -->
      <a-row class="rows">
        <a-col class="left required" span="3">报名审核通过文案</a-col>
        <a-col class="right" span="10">
          <a-select style="width: 300px" :open="false" @click.native="selectSmsCodevisible = true" v-model="smsCode" placeholder="请选择报名审核通过文案">
            <a-select-option :value="item.smsCode" v-for="(item,index) in smsCordList" :key="index">
              {{item.title}}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="14" offset="1">
          <a-form-item label="需学员信息确认：">
            <div>
              <a-radio-group
                name="userConfirmFlag"
                v-decorator="[
                  'userConfirmFlag',
                  { rules: [{ required: true, message: '请选择是否需要学员进行信息确认' }] },
                ]"
              >
                <a-radio :value="1">是 </a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <div style="margin-top: 32px">
          <a-button
            type="primary"
            :loading="buttonloading"
            html-type="submit"
            class="btn"
          >
            确认
          </a-button>
          <a-button @click="$router.go(-1)" class="btn">取消</a-button>
        </div>
      </a-form-item>
    </a-form>
    <div>
      <!-- 新建联系人 -->
      <a-modal
        v-if="addtellvisible"
        :title="tellIndex !== '' ? '编辑联系人' : '新建联系人'"
        okText="确认"
        :visible="addtellvisible"
        :confirm-loading="confirmLoading"
        @ok="addtellOk"
        @cancel="(addtellvisible = false), (tellIndex = '')"
      >
        <a-form-model
          ref="tellruleForm"
          :model="addtellmodelform"
          :rules="tellrules"
        >
          <a-form-model-item
            ref="name"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            label="联系人"
            prop="name"
          >
            <a-input
              placeholder="请输入联系人"
              v-model="addtellmodelform.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="mobile"
            label="联系电话"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            prop="mobile"
          >
            <a-textarea
              v-model="addtellmodelform.mobile"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              @blur="
                () => {
                  $refs.mobile.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!-- 新建问题 -->
      <a-modal
        v-if="addQuestionvisible"
        :title="questionIndex !== '' ? '编辑问题' : '新建问题'"
        okText="确认"
        :visible="addQuestionvisible"
        :confirm-loading="confirmLoading"
        @ok="addQuestionOk"
        @cancel="(addQuestionvisible = false), (questionIndex = '')"
      >
        <a-form-model ref="ruleForm" :model="modelform" :rules="questionrules">
          <a-form-model-item
            ref="name"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            label="标题"
            prop="name"
          >
            <a-input
              placeholder="请输入标题"
              v-model="modelform.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="context"
            label="内容"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            prop="context"
          >
            <a-textarea
              v-model="modelform.context"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              @blur="
                () => {
                  $refs.context.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!-- 选择课程 -->
      <a-modal
        :width="630"
        title="选择课程"
        okText="确认"
        :visible="selectCoursevisible"
        :confirm-loading="confirmLoading"
        @ok="selectCourse"
        @cancel="cancelSelectCouse"
      >
        <div class="findPaper">
          <a-input placeholder="请输入关键字" v-model="course.title" />
          <a-button type="primary" @click="getCourseList">搜索</a-button>
        </div>
        <a-table
          class="table-template"
          :rowKey="(item) => item.courseId"
          :columns="coursecolumns"
          :data-source="courseList"
          @change="onCoursePage"
          :row-selection="{
            selectedRowKeys: selectedcourseRowKeys,
            onChange: selectcourseItem,
            // onSelectAll: selectAllpaper,
            columnTitle: '选择',
          }"
          :pagination="{
            total: course.total,
            current: course.pageNumber,
            defaultPageSize: course.pageSize,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${total}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (course.pageNumber - 1) * 10 + i + 1 }}
            </div>
          </template>
        </a-table>
      </a-modal>
      <!-- 选择考试证书 -->
      <a-modal
        title="选择考试证书"
        okText="确认"
        :visible="selectcertificatevisible"
        :confirm-loading="confirmLoading"
        @ok="selectcertificate"
        @cancel="selectcertificatevisible = false"
      >
        <div class="findPaper">
          <a-input placeholder="请输入关键字" v-model="certificate.title" />
          <a-button type="primary" @click="getCertificateList">搜索</a-button>
        </div>
        <a-table
          class="table-template"
          :rowKey="(item) => item.tempId"
          :columns="certificatecolumns"
          :data-source="certificateList"
          @change="oncertificatePage"
          :row-selection="{
            selectedRowKeys: selectedcertificateRowKeys,
            onChange: selectcertificateItem,
            onSelectAll: selectAllpaper,
            columnTitle: '选择',
          }"
          :pagination="{
            total: certificate.total,
            current: certificate.pageNumber,
            defaultPageSize: certificate.pageSize,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${total}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (certificate.pageNumber - 1) * 10 + i + 1 }}
            </div>
          </template>
        </a-table>
      </a-modal>
      <!-- 选择试卷 -->
      <a-modal
        title="选择试卷"
        okText="确认"
        :visible="selectPapervisible"
        :confirm-loading="confirmLoading"
        @ok="selectPaper"
        @cancel="selectPapervisible = false"
      >
        <div class="findPaper">
          <a-input placeholder="请输入关键字" v-model="paper.title" />
          <a-button type="primary" @click="getManageList">搜索</a-button>
        </div>
        <a-table
          class="table-template"
          :loading="paperLoading"
          :rowKey="(item) => item.paperId"
          :columns="papercolumns"
          :data-source="paperList"
          @change="onPaperPage"
          :row-selection="{
            selectedRowKeys: selectedpaperRowKeys,
            onChange: selectpaperItem,
            onSelectAll: selectAllpaper,
            columnTitle: '选择',
          }"
          :pagination="{
            total: paper.total,
            current: paper.pageNumber,
            defaultPageSize: paper.pageSize,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${total}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (paper.pageNumber - 1) * 10 + i + 1 }}
            </div>
          </template>
        </a-table>
      </a-modal>
      <!-- 选择试卷 -->
      <a-modal
        title="报名审核通过文案"
        width="800px"
        okText="确认"
        :visible="selectSmsCodevisible"
        @ok="selectSmsCode"
        @cancel="selectSmsCodevisible = false"
      >
        <a-table
          class="table-template"
          :rowKey="(item) => item.smsCode"
          :columns="smsCodecolumns"
          :data-source="smsCordList"
          @change="onSmsCodePage"
          :row-selection="{
            selectedRowKeys: selectedSmsCodeRowKeys,
            onChange: selectSmsCodeItem,
            type: 'radio',
            columnTitle: '选择',
          }"
          :pagination="{
            total: smsCodeObj.total,
            current: smsCodeObj.pageNumber,
            defaultPageSize: smsCodeObj.pageSize,
            showSizeChanger: false,
            showTotal: function (total, range) {
              return `共${total}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (smsCodeObj.pageNumber - 1) * 10 + i + 1 }}
            </div>
          </template>
        </a-table>
      </a-modal>
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
const coursecolumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
];

// 短信文案弹框表格
const smsCodecolumns = [
  // {
  //   title: "序号",
  //   width: "80px",
  //   align: "center",
  //   dataIndex: "index",
  //   scopedSlots: { customRender: "index" },
  // },
  {
    title: "场景",
    width: "200px",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "文案",
    align: "center",
    dataIndex: "content",
  },
];

const certificatecolumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "name",
  },
];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const tellcolumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "联系人",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "联系电话",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "标题",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "内容",
    align: "center",
    dataIndex: "context",
  },
  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { UE },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      key:'',
      buttonloading: false,
      // 成绩设置数组
      calcurlesJson: [
        {
          markId: undefined,
          val: "",
        },
      ],
      // 选择展示的成绩
      selshowarr: [],
      addressList: [], // 地址列表
      configId: "",
      certificateList: [], // 证书列表
      courseList: [], // 课程列表
      selectedpaperRowKeys: [],
      selectedcourseRowKeys: [],
      selectedSmsCodeRowKeys: [], // 短信文案选择
      selectedcertificateRowKeys: [1],
      paperList: [], //列表
      smsCordList: [], // 考试设置短信文案列表
      // 标头
      papercolumns: [
        {
          title: "序号",
          width: "80px",
          align: "center",
          dataIndex: "index",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "试卷名称",
          align: "center",
          dataIndex: "paperName",
        },
      ],
      smsCode: undefined, // 报名审核通过文案code码
      // 报考指南说明
      guideJson: [],
      // 联系人
      tellJson: [],
      // 富文本
      text: {
        appExamDesc: "", //	考试须知app端
        appSignupDesc: "", // 报名须知app端
        webExamDesc: "", //	考试须知web端
        webSignupDesc: "", // 报名须知web端
      },
      smsCodeObj: {
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 5, // 页数
      },
      course: {
        title: "", // 关键字
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
      },
      certificate: {
        title: "", // 关键字
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
      },
      paper: {
        title: "", // 关键字
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
      },
      // 新建联系人
      addtellmodelform: {
        name: "", // 联系人
        mobile: "", // 联系电话
      },
      // 新建问题
      modelform: {
        name: "", // 标题
        context: "", // 内容
      },
      questionrules: {
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
        context: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      tellrules: {
        name: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
      },
      tell: {
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
      },
      tellcolumns, // 新建联系人
      columns, // 报考说明table
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      coursecolumns,
      certificatecolumns,
      smsCodecolumns,
      form: this.$form.createForm(this, { name: "coordinated" }),
      loading: false,
      imageUrl: "",
      value: 1,
      confirmLoading: false,
      selectSmsCodevisible: false, // 选择报名审核通过文案
      selectCoursevisible: false, // 选择课程
      addQuestionvisible: false, // 新建问题弹框
      selectPapervisible: false, // 选择试卷
      addtellvisible: false, // 新建联系人弹框
      selectcertificatevisible: false, // 考试证书弹框
      yearlyPlanPicture: "", // 全年考试安排
      guideBackimgPicture: "", // 报考指南背景
      ueInitText: "",
      paperName: "", // 试卷名称
      courseName: "", // 证书名称
      enterselectedcourseList: [], // 已确认的课程列表
      selectedcourseList: [], // 已选课程列表
      certificateName: "证书名称", // 证书名称
      questionIndex: "", // 当前操作下表
      tellIndex: "", // 新建联系人当前操作index
      // 考试成绩设置字段配置列表
      examMackConfigList: [],
      paperLoading: false,
      imageTypeAll: ["jpg", "jpeg", "png"],
    };
  },
  // 事件处理器
  methods: {
    // 多选
    onChanges(checkedValues) {
      console.log("checked = ", checkedValues);
    },
    // 添加成绩字段
    addGrade() {
      this.calcurlesJson.push({
        markId: undefined,
        val: "",
      });
    },
    // 新建联系人功能
    deltell(e) {
      // 删除
      let index = e + (this.tell.pageNumber - 1) * this.tell.pageSize;
      this.tellJson.splice(index, 1);
      if (
        (this.tell.pageNumber - 1) * this.tell.pageSize <=
          this.tellJson.length &&
        this.tell.pageNumber != 1
      ) {
        this.tell.pageNumber -= 1;
      }
    },
    // 新建联系人
    newbuilt() {
      this.addtellvisible = true;
      this.addtellmodelform.name = null;
      this.addtellmodelform.mobile = null;
    },
    edittellItem(e) {
      // 编辑
      let index = e + (this.tell.pageNumber - 1) * this.tell.pageSize;
      this.addtellvisible = true;
      this.tellIndex = index;
      this.addtellmodelform = JSON.parse(JSON.stringify(this.tellJson[index]));
    },
    // 上移下移
    tellUp(e) {
      this.tellJson.splice(e - 1, 0, this.tellJson.splice(e, 1)[0]);
    },
    tellDown(e) {
      this.tellJson.splice(e + 1, 0, this.tellJson.splice(e, 1)[0]);
    },
    // 常见问题功能
    delQuestion(e) {
      // 删除
      let index = e + (this.pageNumber - 1) * this.pageSize;
      this.guideJson.splice(index, 1);
      if (
        (this.pageNumber - 1) * this.pageSize <= this.guideJson.length &&
        this.pageNumber != 1
      ) {
        this.pageNumber -= 1;
      }
    },
    editQuestionItem(e) {
      // 编辑
      let index = e + (this.pageNumber - 1) * this.pageSize;
      this.addQuestionvisible = true;
      this.questionIndex = index;
      this.modelform = JSON.parse(JSON.stringify(this.guideJson[index]));
    },
    // 上移下移
    questionUp(e) {
      this.guideJson.splice(e - 1, 0, this.guideJson.splice(e, 1)[0]);
    },
    questionDown(e) {
      this.guideJson.splice(e + 1, 0, this.guideJson.splice(e, 1)[0]);
    },
    // 添加考试报名说明：
    addGuide() {
      this.guideJson.push({
        name: "",
        context: "",
      });
    },
    moment,
    changeTime(time, timeString) {
      // console.log(time, timeString);
    },
    // 表格选择
    selectpaperItem(selectedRowKeys) {
      let arr = selectedRowKeys.pop();
      this.selectedpaperRowKeys = [arr];
    },
    selectSmsCodeItem(selectedRowKeys) {
      let arr = selectedRowKeys.pop();
      this.selectedSmsCodeRowKeys = [arr];
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        // console.log(values);
        if (!err) {
          if (!this.selectedcourseRowKeys.length) {
            return this.$message.warning("请选择关联课程");
          } else if (!this.selectedpaperRowKeys.length || !this.paperName) {
            return this.$message.warning("请选择关联试卷");
          } 
          // else if (
          //   !this.selectedcertificateRowKeys.length ||
          //   !this.certificateName
          // ) {
          //   return this.$message.warning("请选择关联考试证书");
          // } 
          else if (values.isMake == 1) {
            if (!values.makeFreeNum && values.makeFreeNum != 0) {
              return this.$message.warning("请输入免费补考次数");
            } else if (!values.makePrice) {
              return this.$message.warning("请输入补考金额");
            } else if (!values.makeNum) {
              return this.$message.warning("请输入补考次数");
            } else if (!values.delaysNum && values.delaysNum != 0) {
              return this.$message.warning("请输入课程延期天数");
            }
          }
          if (!this.tellJson.length) {
            return this.$message.warning("请新建联系人");
          } else if(!this.selshowarr.length) {
            return this.$message.warning("请选择终端展示成绩字段");
          } else if (!values.passScore) {
            return this.$message.warning("请输入考试通过成绩");
          } else if (!this.text.appExamDesc) {
            return this.$message.warning("请输入考试须知app端");
          } else if (!this.text.appSignupDesc) {
            return this.$message.warning("请输入报名须知app端	");
          } else if (!this.text.webExamDesc) {
            return this.$message.warning("请输入考试须知web端");
          } else if (!this.text.webSignupDesc) {
            return this.$message.warning("请输入报名须知web端");
          } else if (!this.yearlyPlanPicture) {
            return this.$message.warning("请上传全年考试安排");
          } 
          // else if (!this.guideBackimgPicture) {
          //   return this.$message.warning("请上传报考指南图片");
          // } 
          else if (!this.guideJson.length) {
            return this.$message.warning("请新建报考指南说明");
          }
          else if (!this.smsCode) {
            return this.$message.warning("请选择报名审核通过文案");
          }
          this.buttonloading = true;
          let _params = {};
          _params["configId"] = this.configId; // 考试设置id
          _params["abbreviation"] = values.abbreviation; // 考试简称
          _params["advanceTime"] = values.advanceTime; // 提前入场时间
          _params["delaysNum"] = values.delaysNum; // 课程延期天数
          _params["duration"] = values.duration; // 考试时长
          _params["isMake"] = values.isMake; // 是否补考 1.设置补考 0.不可设置
          _params["scorePublish"] = values.scorePublish; // 算分规则 1.考试结束发放 0.主观题成绩发放
          _params["verifyType"] = values.verifyType; // 证书确认方式 0.邮寄和上传两种方式 1.仅可上传修改一种方式
          _params["makeFreeNum"] = values.makeFreeNum; // 免费补考次数
          _params["makeNum"] = values.makeNum; // 补考次数
          _params["makePrice"] = values.makePrice; // 	补考费用
          _params["name"] = values.name; // 	考试名称
          _params["passScore"] = values.passScore; // 及格分数
          _params["calcurlesJson"] = JSON.stringify(this.calcurlesJson);
          _params["status"] = 1; // 状态 1.上架 0.下架
          _params["appExamDesc"] = this.text.appExamDesc; // 考试须知app端
          _params["appSignupDesc"] = this.text.appSignupDesc; // 报名须知app端
          _params["webExamDesc"] = this.text.webExamDesc; // 考试须知web端
          _params["webSignupDesc"] = this.text.webSignupDesc; // 报名须知web端
          _params["examSystemDesc"] = values.examSystemDesc; // 考试简称
          _params["address"] = values.address; // 收货地址id
          _params["courseIds"] = this.selectedcourseRowKeys.join(','); // 课程id
          _params["certifTempId"] = 1; // 证书id  this.selectedcertificateRowKeys[0]
          _params["yearlyPlanPicture"] = this.yearlyPlanPicture; // 全年考试安排
          _params["guideBackimgPicture"] = this.guideBackimgPicture; // 报考指南背景图
          _params["guideJson"] = JSON.stringify(this.guideJson); // 报考指南json
          _params["paperId"] = this.selectedpaperRowKeys[0]; // 试卷id
          _params["tellJson"] = JSON.stringify(this.tellJson); // 报考指南json
          _params["smsCode"] = this.smsCode; // 报名审核通过文案
          _params["userConfirmFlag"] = values.userConfirmFlag; // 是否需要确认信息  1.是 0.否
          // 新增/编辑
          this.$ajax({
            url: this.configId
              ? "/hxclass-management/exam/config/edit"
              : "/hxclass-management/exam/config/save",
            method: this.configId ? "put" : "post",
            params: _params,
          }).then((res) => {
            if (res.code == 200 && res.success) {
              let configids = this.configId ? this.configId : res.data;
              this.pushselshow(configids);
              // this.$message.success(res.message);
              // this.$router.push("/admin/Examination/ExamSeting");
            } else {
              this.buttonloading = false;
              this.$message.error(res.message);
              this.$router.push("/admin/Examination/ExamSeting");
            }
          });
        }
      });
    },
    // 提供展示的成绩字段
    pushselshow(e) {
      let params = [];
      this.selshowarr.forEach((element) => {
        if (element != null) {
          params.push({
            configId: e,
            markId: element,
          });
        }
      });
      this.$ajax({
        url: "/hxclass-management/manage/exam/config",
        method: "PUT",
        params: params,
      }).then((res) => {
        this.buttonloading = false;
        this.$router.push("/admin/Examination/ExamSeting");
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取所选展示成绩
    getselshow() {
      // console.log(123);
      this.$ajax({
        url: "/hxclass-management/manage/exam/getConfig",
        method: "get",
        params: {
          configId: this.$route.query.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.selshowarr = res.data;
        }
      });
    },
    handleSelectChange(value) {
      // console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    // 富文本内容有变化时触发   获取pc简介
    getwebExamDesc(e) {
      this.$set(this.text, "webExamDesc", e);
      // console.log(this.text.webExamDesc);
    },
    getappExamDesc(e) {
      this.$set(this.text, "appExamDesc", e);
    },
    getappSignupDesc(e) {
      this.$set(this.text, "appSignupDesc", e);
    },
    getwebSignupDesc(e) {
      this.$set(this.text, "webSignupDesc", e);
    },
    // 上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      const type = file.type.split("/")[1];
      const index = this.imageTypeAll.indexOf(type?.toLowerCase());
      if (index == -1) {
        this.$message.error(
          "上传图片格式不正确，请上传为jpg、jpeg、png格式后缀的图片。"
        );
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      const name = fileData.filename // 名称前缀
      let obj = {}

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this[name] = url
          obj[name] = url
          this.form.setFieldsValue(obj);
        } else {
          this.$message.error("上传失败");
        }
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 对比选择内容若为取消选中清除对应内容
    mapCourseList(){
      if(this.selectedcourseList.length > this.selectedcourseRowKeys.length) {
        this.selectedcourseList.map((item,index)=>{
          if(this.selectedcourseRowKeys.indexOf(item.courseId) == -1) {
            this.selectedcourseList.splice(index,1);
          }
        })
      }
    },

    selectcourseItem(selectedRowKeys) {
      this.selectedcourseRowKeys = selectedRowKeys;
      // 对比数组长度 大于删除 小于新增
      if(this.selectedcourseList.length > this.selectedcourseRowKeys.length) {
        this.mapCourseList(); // 同步已选内容
      }else{
        let lastItem = selectedRowKeys.slice(-1); // 获取新增项id
        this.courseList.some(item=>{
          if(item.courseId == lastItem) {
            this.selectedcourseList.push(item);
            return true
          }
        })
      }
    },
    selectcertificateItem(selectedRowKeys) {
      let arr = selectedRowKeys.pop();
      this.selectedcertificateRowKeys = [arr];
    },
    // 课程列表分页
    onCoursePage(e) {
      this.course.pageNumber = e.current;
      this.course.pageSize = e.pageSize;
      this.getCourseList();
    },
    // 考试证书分页
    oncertificatePage(e) {
      this.certificate.pageNumber = e.current;
      this.certificate.pageSize = e.pageSize;
      this.getCertificateList();
    },
    // 试卷列表分页
    onPaperPage(e) {
      this.paper.pageNumber = e.current;
      this.paper.pageSize = e.pageSize;
      this.getManageList();
    },
    // 短信文案分页
    onSmsCodePage(e) {
      this.smsCodeObj.pageNumber = e.current;
      this.smsCodeObj.pageSize = e.pageSize;
    },
    selectAllpaper(selectedRows) {
      this.selectedRows = selectedRows;
    },
    // 新建按钮
    addbtn() {
      this.addQuestionvisible = true;
      this.modelform = {
        name: "",
        context: "",
      };
    },
    // 新建联系人
    addtellOk() {
      this.$refs.tellruleForm.validate((valid) => {
        if (valid) {
          if (this.tellIndex !== "") {
            this.tellJson.splice(this.tellIndex, 1, this.addtellmodelform);
            this.tellIndex = "";
          } else {
            this.tellJson.push(this.addtellmodelform);
          }
          this.addtellmodelform = {
            name: "",
            mobile: "",
          };
          this.addtellvisible = false;
        } else {
          return false;
        }
      });
    },
    // 新建问题
    addQuestionOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.questionIndex !== "") {
            this.guideJson.splice(this.questionIndex, 1, this.modelform);
            this.questionIndex = "";
          } else {
            this.guideJson.push(this.modelform);
          }
          this.modelform = {
            name: "",
            context: "",
          };
          this.addQuestionvisible = false;
        } else {
          return false;
        }
      });
    },
    // 选择短信文案
    selectSmsCode() {
      this.smsCode = this.selectedSmsCodeRowKeys[0];
      this.selectSmsCodevisible = false;
    },
    // 选择试卷
    selectPaper() {
      if (this.selectedpaperRowKeys.length) {
        let arr = this.paperList.filter((item) => {
          return item.paperId === this.selectedpaperRowKeys[0];
        });
        this.paperName = arr[0].paperName;
        this.selectPapervisible = false;
      } else {
        this.$message.error("请先选择试卷");
      }
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    ontellPage(e) {
      this.tell.pageNumber = e.current;
      this.tell.pageSize = e.pageSize;
    },

    // 删除单项
    deleteItem(e) {
      this.selectedcourseList.splice(e,1);
      this.selectedcourseRowKeys.splice(e,1);
      this.enterselectedcourseList.splice(e,1);
      console.log(this.selectedcourseList,this.selectedcourseRowKeys,this.enterselectedcourseList)
    },
    // 取消选择课程
    cancelSelectCouse() {
      this.selectedcourseList = JSON.parse(JSON.stringify(this.enterselectedcourseList));
      this.selectedcourseRowKeys = this.enterselectedcourseList.map(item=>{
        return item.courseId;
      })
      this.selectCoursevisible = false;
    },
    // 选择课程
    selectCourse() {
      if (this.selectedcourseRowKeys.length) {
        this.enterselectedcourseList = JSON.parse(JSON.stringify(this.selectedcourseList));
        this.selectCoursevisible = false;
      } else {
        this.$message.error("请先选择课程");
      }
    },
    // 选择考试证书
    selectcertificate() {
      if (this.selectedcertificateRowKeys.length) {
        let arr = this.certificateList.filter((item) => {
          return item.tempId === this.selectedcertificateRowKeys[0];
        });
        this.certificateName = arr[0].name;
        this.selectcertificatevisible = false;
      } else {
        this.$message.error("请选择考试证书");
      }
    },
    // 获取试卷列表
    getManageList() {
      this.paperLoading = true;
      this.$ajax({
        url: "/hxclass-management/exam/group/list",
        method: "get",
        params: {
          category: 1, // 组卷大类 1.考试 2.模拟考试
          courseId: "", // 课程id   this.selectedcourseRowKeys[0]
          name: this.paper.title, // 组卷名称
          current: this.paper.pageNumber,
          size: this.paper.pageSize,
          status: 1,
        },
      }).then((res) => {
        this.paperLoading = false;
        if (res.code == 200 && res.success) {
          this.paperList = res.data.records;
          this.paper.total = res.data.total;
        }
      });
    },
    // 获取考试证书列表
    getCertificateList() {
      this.$ajax({
        url: "/hxclass-management/certif-temp/name/page",
        method: "get",
        params: {
          // type: 1, // 模版类型 1.考试模版 2.课程模版
          name: this.certificate.title, // 模版名称
          pageNum: this.certificate.pageNumber,
          pageSize: this.certificate.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.certificateList = res.data.records;
          this.certificate.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取考试设置列表
    getExamEditList() {
      this.$ajax({
        url: "/hxclass-management/exam/mark/config/list",
        method: "get",
        params: {
          current: 1,
          size: 100,
          name: "",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examMackConfigList = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/unset-exam-config",
        method: "get",
        params: {
          keyword: this.course.title,
          pageNum: this.course.pageNumber,
          pageSize: this.course.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.course.total = res.data.total;
          this.courseList = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getExamDetail() {
      let key = (this.key = "getExamDetail");
      this.$message.loading({ content: "正在查询...", key, duration: 0 });
      this.$ajax({
        url: "/hxclass-management/exam/config/get?configId=" + this.configId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form.setFieldsValue({ abbreviation: res.data.abbreviation }); // 考试简称
          this.form.setFieldsValue({ advanceTime: res.data.advanceTime }); // 提前入场时间
          this.form.setFieldsValue({ duration: res.data.duration }); // 考试时长
          this.form.setFieldsValue({ isMake: res.data.isMake * 1 }); // 是否补考 1.设置补考 0.不可设置
          this.form.setFieldsValue({ scorePublish: res.data.scorePublish }); // 算分规则 1.考试结束发放 0.主观题成绩发放
          this.form.setFieldsValue({ verifyType: res.data.verifyType }); // 证书确认方式 0.邮寄和上传两种方式 1.仅可上传修改一种方式
          this.form.setFieldsValue({ userConfirmFlag: res.data.userConfirmFlag }); // 是否需要确认信息  1.是 0.否
          this.form.setFieldsValue({ passScore: res.data.passScore }); // 及格分数
          this.form.setFieldsValue({ address: res.data.address * 1 }); // 收货地址id
          this.form.setFieldsValue({ examSystemDesc: res.data.examSystemDesc }); // 考试简称
          setTimeout(() => {
            this.form.setFieldsValue({ makeFreeNum: res.data.makeFreeNum }); // 免费补考次数
            this.form.setFieldsValue({ makeNum: res.data.makeNum }); // 补考次数
            this.form.setFieldsValue({ delaysNum: res.data.delaysNum }); // 课程延期天数
            this.form.setFieldsValue({ makePrice: res.data.makePrice }); // 	补考费用
          }, 0);
          this.form.setFieldsValue({ name: res.data.name }); // 	考试名称
          this.calcurlesJson = res.data.calcurulesList.map((item) => {
            return { val: item.val, markId: item.markId };
          }); // chengjishezhi
          // this.form.setFieldsValue({markIdone:res.data.calcurulesList[0].markId});
          // this.form.setFieldsValue({valone:res.data.calcurulesList[0].val});
          // this.form.setFieldsValue({markIdtwo:res.data.calcurulesList[1].markId});
          // this.form.setFieldsValue({valtwo:res.data.calcurulesList[1].val});
          this.text.appExamDesc = res.data.appExamDesc; // 考试须知app端
          this.text.appSignupDesc = res.data.appSignupDesc; // 报名须知app端
          this.text.webExamDesc = res.data.webExamDesc; // 考试须知web端
          this.text.webSignupDesc = res.data.webSignupDesc; // 报名须知web端

          // this.selectedcourseRowKeys[0] = res.data.courseId; // 课程id
          // this.courseName = res.data.courseName;
          // 课程数据存储
          this.selectedcourseRowKeys = res.data.courseList.map(item=>{
            return item.courseId
          })
          this.selectedcourseList = JSON.parse(JSON.stringify(res.data.courseList));
          this.enterselectedcourseList = JSON.parse(JSON.stringify(res.data.courseList));

          this.selectedcertificateRowKeys[0] = res.data.certifTempId; // 证书id
          // let certificateName = this.certificateList.filter((item) => {
          //   return item.tempId == res.data.certifTempId;
          // })[0].name;
          // this.certificateName = certificateName;
          this.certificateName = res.data.tempName;

          this.selectedpaperRowKeys[0] = res.data.paperId; // 试卷id
          // let paperName = this.paperList.filter((item) => {
          //   return item.paperId == res.data.paperId;
          // })[0].paperName;
          // this.paperName = paperName;
          this.paperName = res.data.paperName;

          this.yearlyPlanPicture = res.data.yearlyPlanPicture; // 全年考试安排
          this.form.setFieldsValue({
            yearlyPlanPicture: res.data.yearlyPlanPicture,
          });

          this.guideBackimgPicture = res.data.guideBackimgPicture; // 报考指南背景图
          this.form.setFieldsValue({
            guideBackimgPicture: res.data.guideBackimgPicture,
          });
          this.guideJson = res.data.guideList.map((item) => {
            return { name: item.name, context: item.context };
          }); // 报考指南json
          this.tellJson = res.data.tellList.map((item) => {
            return { name: item.name, mobile: item.mobile };
          }); // 报考指南json
          this.smsCode = res.data.smsCode;   // 考试通过文案
          this.selectedSmsCodeRowKeys = [res.data.smsCode];  // 考试通过文案表格回显

          setTimeout(() => {
            this.$message.success({ content: "查询成功!", key, duration: 1 });
          }, 1000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取收货地址列表
    getAddressList() {
      this.$ajax({
        url: "/hxclass-management/admin-address/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 100,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.addressList = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询考试设置短信文案列表
    getSmsCordList() {
      this.$ajax({
        url: '/hxclass-management/exam/config/exam-sms/list',
        method: 'get'
      }).then((res) => {
        if(res.code == 200 && res.success) {
          this.smsCodeObj.total = res.data.length;
          this.smsCordList = res.data;
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getAddressList();
    this.getExamEditList();
    this.getCertificateList(); // 获取考试证书列表
    this.getManageList(); // 获取试卷列表
    this.getCourseList(); // 获取课程列表
    this.getSmsCordList(); // 查询考试设置短信文案列表
    if (this.$route.query.configId) {
      this.configId = this.$route.query.configId;
      this.getExamDetail();
      this.getselshow(); //获取选择展示的成绩
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.rows {
  margin-top: 20px;
  .left {
    line-height: 32px;
    &.required::before {
      content: "*";
      color: red;
    }
    &::after {
      content: "：";
    }
  }
}
.selectitem {
  margin-bottom: 10px;
  display: flex;
  .name {
    font-size: 14px;
    color: #999999;
    line-height: 16px;
  }
  .del {
    margin-left: 19px;
    font-size: 14px;
    color: #2b6ed4;
    line-height: 16px;
    cursor: pointer;
  }
}
.selectitem:last-child{
  margin-bottom: 0;
}
.title {
  display: inline-block;
  font-size: 14px;
  font-family: PingFang SC;
  color: #2b6ed4;
  cursor: pointer;
}
.btn {
  margin-right: 24px;
}
.ifbox {
  width: 100%;
  span {
    font-size: 14px;
    font-family: PingFang SC;
    color: #333333;
    display: block;
    margin-top: 18px;
  }
}
.findPaper {
  display: flex;
  justify-content: space-between;
  .ant-input {
    width: 300px;
  }
}
.ant-row {
  position: relative;
  .addQuesbtn {
    position: absolute;
    top: 10px;
    left: 1000px;
  }
}
/deep/.ant-col-offset-1 {
  margin-left: 1px !important;
}
/deep/.ant-col-6 {
  width: 140px;
}
/deep/.ant-checkbox-inner {
  border-radius: 50%;
}
.radios {
  margin-left: 6px;
  margin-top: 8px;
  display: block;
  overflow: hidden;
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }
  .radios_xs {
    display: block;
  }
}
.dlex {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
</style>
