var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 课程证书 ")]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.courseId; },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        showTotal: function (res) { return ("共" + _vm.total + "条"); },
        current: this.pageNumber,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(i + 1)+" ")])]}},{key:"study",fn:function(text, record){return [_c('div',{staticClass:"btn_router_link"},[_c('span',{on:{"click":function($event){return _vm.goLearnPage(record)}}},[_vm._v(_vm._s(text))])])]}},{key:"obtain",fn:function(text, record){return [_c('div',{staticClass:"btn_router_link"},[_c('span',{on:{"click":function($event){return _vm.goObtainPage(record)}}},[_vm._v(" "+_vm._s(text)+" ")])])]}},{key:"template",fn:function(text, record){return [_c('div',{staticClass:"btn_router_link"},[_c('div',{staticStyle:{"position":"relative","overflow":"hidden"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(text),expression:"text"}],staticStyle:{"color":"#3681ef","cursor":"pointer"}},[_vm._v(" "+_vm._s(record.certifName))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!text),expression:"!text"}]},[_vm._v("--")]),_c('viewer',[_c('img',{staticClass:"inneer_img",staticStyle:{"position":"absolute","top":"-10px","left":"0","opacity":"0","cursor":"pointer"},attrs:{"src":record.certifPicture}})])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }