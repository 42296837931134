<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 课程购买审核 </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="keyword" allowClear />
        <a-range-picker @change="changeStartTime" format="YYYY-MM-DD HH:mm:ss" style="width: 200px" />
        <a-select placeholder="请选择审核状态" v-model="status" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">待审核</a-select-option>
          <a-select-option value="2">已通过</a-select-option>
          <!-- <a-select-option value="3">通过审核且上传至证书库</a-select-option> -->
          <a-select-option value="3">已驳回</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="onSearch()" v-hasPermi="['payaudit:list:query']">搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table class="table-template" :rowKey="(item) => item.courseBuyAuditId" :columns="columns"
        :data-source="tableData" @change="onPage" :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="status" slot-scope="item">
          {{ item == 1 ? "待审核" : item == 4 ? "已驳回" : "已通过" }}
        </template>
        <template slot="remark" slot-scope="item">
          <!-- {{ item ? item : '-' }} -->
          <a-popover>
            <template slot="content">
              <p style="max-width: 400px;">{{ item }}</p>
            </template>
            <div class="mallHidden" style="cursor: pointer">
              {{ item }}
            </div>
          </a-popover>
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link" v-hasPermi="['payaudit:detail:query']" style="display: inline-block;">
            <router-link to @click.native="toDetail(item.courseBuyAuditId)">详情</router-link>
          </div>
          <span> | </span>
          <span class="btn_router_link" style="cursor: pointer;" @click="showModal(item)">备注</span>
        </template>
      </a-table>
    </div>

    <a-modal :visible="visible" title="备注信息" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel"
      style="width: 200px;">
      <a-input placeholder="请输入备注信息" v-model="editItem.remark" />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "报名时间",
    align: "center",
    dataIndex: "signupTime",
  },
  {
    title: "审核状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "跟进情况",
    align: "center",
    dataIndex: "remark",
    width: 240,
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    align: "center",
    width: "220px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      keyword: "",
      signupStartTime: null,
      signupEndTime: "",
      status: undefined,
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      visible: false,
      confirmLoading: false,
      editItem: {}, // 当前编辑备注的item
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    changeStartTime(x, s) {
      this.signupStartTime = s[0];
      this.signupEndTime = s[1];
    },
    changeEndTime(x, s) {
      this.signupEndTime = s;
    },
    onSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    toDetail(id) {
      this.$router.push("/admin/AuditManage/CourseReviewDetails?" + "id=" + id);
    },
    getList() {
      this.$ajax({
        url: "/hxclass-management/course/buy-audit",
        params: {
          keyword: this.keyword,
          signupStartTime: this.signupStartTime,
          signupEndTime: this.signupEndTime,
          status: this.status,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 显示modal
    showModal(e) {
      this.visible = true;
      let temp = JSON.stringify(e);
      this.editItem = JSON.parse(temp);
    },
    handleOk() {
      if (this.editItem) {
        this.confirmLoading = true;
        this.$ajax({
          url: "/hxclass-management/course/manage/remark/audit",
          method: "POST",
          params: {
            courseBuyAuditId: this.editItem.courseBuyAuditId,
            remark: this.editItem.remark ? this.editItem.remark : '',
          },
        }).then((res) => {
          this.visible = false;
          this.confirmLoading = false;
          if (res.code == 200 && res.success) {
            this.$message.success('备注添加成功');
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    handleCancel() {
      this.visible = false;
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}

.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

/deep/.ant-modal-content {
  width: 400px;
}

.mallHidden {
  -webkit-line-clamp: 2; //（最多显示 2 行）
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {}
}
</style>
