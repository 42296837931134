<template>
  <div class="login">
    <div class="leftBack">
      <!-- <img class="" src="@/assets/image/loginBack.png" alt="" /> -->
    </div>
    <div class="rightContent">
      <img class="logo" src="@/assets/image/loginLogo.png" alt=""/>
      <p class="title">欢迎来到云课堂后台管理系统</p>
      <div class="formData">
        <p class="loginTitle">登 录</p>
        <a-alert message="非密系统 严禁传输涉密资料" type="info" show-icon />
        <template v-if="isLogin"> <!--账号密码验证通过后 ~ 短信验证 -->
          <div>
            <a-input size="large" v-model="form.phone" :disabled="true" placeholder="请输入登录账号"/>
          </div>
          <div class="sms-inner">
            <a-input size="large" v-model="dxcode" placeholder="请输入短信验证码"/>
            <div class="send-btn">
              <span v-if="Sending">{{second}}秒后重新发送</span>
              <span v-else class="btn" @click="onSendSMS">获取验证码</span>
            </div>
          </div>
        </template>
        <template v-else>  <!--账号密码验证通过前 -->
          <div>
            <a-input size="large" v-model="form.username" placeholder="请输入登录账号"/>
          </div>
          <div>
            <a-input size="large" type="password" v-model="form.password" placeholder="请输入密码"/>
          </div>
          <div class="verificationCode">
            <a-input size="large" v-model="verificationCode" placeholder="请输入验证码"/>
            <img :src="captchaImage" @click="funCode" />
          </div>
        </template>
        <div>
          <a-checkbox :checked="remenberStatus" @change="remenberPass">
            记住密码
          </a-checkbox>
        </div>
        <div>
          <a-button size="large" style="width:100%;" type="primary" :loading="loading || isPower || nextLoad" @click="onSaveCheck()">{{loading ? '登录中...': isPower ? '正在配置权限...': (isLogin ? '登录' : '下一步')}}</a-button>
        </div>
      </div>
    </div>
    <!-- <img class="Backlogin" src="@/assets/image/Backlogin.png" alt=""> -->
    <!-- 表单 -->
    <!-- <div class="center">
      <div class="formData">
        <div class="loginImg">
          <img src="@/assets/image/logo.png"/>
        </div> -->
        <!-- <h4>华夏云课堂后台管理系统</h4> -->
        <!-- <div>
          <a-input size="large" v-model="obj.account" placeholder="请输入手机号或用户名"/>
        </div>
        <div>
          <a-input size="large" type="password" v-model="obj.password" placeholder="请输入密码"/>
        </div>
        <div>
          <a-button size="large" style="width:100%;" type="primary" @click="onSave()">登录</a-button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// 引入base64
const Base64 = require('js-base64').Base64
import {encryptDes} from '@/unit/DESCypher.js' // 引用具体的方法

export default {
  // 可用组件的哈希表
  components: { },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      remenberStatus:false, // 记住密码状态
      code:'', // 验证码
      captchaImage: '', // 图片验证码
      verificationCode:'', // 验证码
      loading:false,
      nextLoad:false,
      isPower:false,
      isLogin:false,
      Sending:false,
      second: 0,
      dxcode: '',
      encyMobile:'',
      type:'',
      form:{
        // username:'admin',
        // password:'Qwe@1234',
        username:'',
        password:'',
        phone: ''
      },
      // 假数据
      obj: {
        // userName: "测试账户张三",
        // account: "root",
        // password: "aa123456",
        // userType: 1,
        // photo: "https://www.longyc.cn/logo.jpg",
        // phone: "17600380046",
        // userName: "测试账户张三",
        // sex: 1,
        // age: 30,
        // birthday: "1990-02-22",
        // address: "北京市朝阳区颐和路怡和阳光大厦C座1304号"
      }
    }
  },
  // 事件处理器
  methods: {
    // 改变记住密码状态
    remenberPass(e){
      this.remenberStatus = e.target.checked;
    },
    // 生成一段验证码
    funCode(){
      // 生成随机的 4 位验证码
      const captcha = Math.random().toString(36).substr(2, 4).toUpperCase();
      this.code = captcha
      // 绘制验证码图片
      const canvas = document.createElement("canvas");
      canvas.width = 80;
      canvas.height = 30;
      const ctx = canvas.getContext("2d");

      ctx.font = "19px Arial";
      ctx.fillStyle = "#000";
      ctx.fillText(captcha, 17, 22);

      // 绘制斜线
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(80, 30);
      ctx.strokeStyle = "#f00";
      ctx.stroke();

      // 绘制删除线
      ctx.beginPath();
      ctx.moveTo(0, 15);
      ctx.lineTo(80, 15);
      ctx.strokeStyle = "#0f0";
      ctx.stroke();

      this.captchaImage = canvas.toDataURL();
    },
    // 登录判断
    onSaveCheck(){
      if(this.isLogin){ // 短信验证
        this.onSmsLogin()
      } else { // 账号密码验证
        this.onSave()
      }
    },
    // 短信登录
    onSmsLogin(){
      if(!this.dxcode){
        this.$message.warning('请输入验证码');
        return
      }
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/sms',
        method: "get",
        params: {
          auth: this.encyMobile,
          captcha	: String(this.dxcode),
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          // 如果已勾选记住密码，将密码存储到cookie
          if(this.remenberStatus){
            this.setCookie("username",this.form.username)
            // base64加密密码
            // let passWord = Base64.encode(this.form.password)
            let passWord = this.$AES.encode_data(JSON.stringify(this.form.password))
            this.setCookie("password",passWord)    
          }else{
            this.setCookie("username","")
            this.setCookie("password","") 
          }
          this.getUserInfo()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 账号密码登录
    onSave () {
      this.isLogin = false
      if(!this.form.username){
        this.$message.warning('请输入登录账号');
        return
      }
      if(!this.form.password){
        this.$message.warning('请输入密码');
        return
      }
      if(!this.verificationCode){
        this.$message.warning('请输入验证码');
        return
      }
      if(this.verificationCode.toLowerCase() != this.code.toLowerCase()){
        this.$message.warning('验证码不正确');
        this.verificationCode = ''
        this.funCode()
        return
      }
      // 密码明文转密
      const encryptedData = encryptDes(this.form.password)
      let password = encodeURIComponent(encryptedData)
      this.nextLoad = true
      this.$ajax({
        method: 'post',
        url: '/hxclass-management/login',
        params: {
          username: this.form.username,
          password: password,
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          // this.getMobile()
          this.form.phone = res.data.mobile // 赋值手机号
          this.encyMobile = res.data.auth // 赋值加密手机号
          this.type = res.data.type // 赋值手机号类型
          this.isLogin = true

          this.$message.success('验证码已发送，请注意查收!');
          this.Sending = true
          this.funCountDown()
        } else {
          this.verificationCode = ''
          this.funCode()
          this.$message.warning(res.message);
        }
        this.nextLoad = false
      })
    },
    // 获取管理员手机号
    getMobile(){
      // 密码明文转密
      const encryptedData = encryptDes(this.form.password)
      let password = encodeURIComponent(encryptedData)
      this.nextLoad = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/user/info/getMobile",
        params: {
          userName: this.form.username,
          passWord: password
        }
      }).then(res => {
        this.nextLoad = false
        if (res.code == 200 && res.success) {
          this.form.phone = res.data.mobile
          this.onSendSMS()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 向管理员手机号发送短信验证码
    onSendSMS(){
      this.isLogin = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/captcha",
        params: {
          mobile: this.encyMobile,
          type: this.type
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.$message.success('验证码已发送，请注意查收!');
          this.Sending = true
          this.funCountDown()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 倒计时
    funCountDown(){
      clearInterval(this.timer)
      this.second = 60
      this.timer = setInterval(()=>{
        this.second --
        if(this.second == 0){
          this.Sending = false
          clearInterval(this.timer)
        }
      },1000)
    },
    // 获取用户信息
    getUserInfo(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/getInfo",
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.isPower = true
          res.data.isLogin = true
          res.data.token = this.$store.state.userInfo.token
          res.data.id = res.data.user.userId
          res.data.name = res.data.user.nickName
          res.data.phone = res.data.user.mobile
          res.data.userName = res.data.user.userName
          res.data.userType = res.data.user.userType

          this.$store.commit("updateUserInfo", res.data);
          this.getMenu()
        }
      })
    },
    // 获取白名单列表
    getWhiteList(arr,whiteList) {
      if(arr.length) {
        arr.map((item,index) => {
          if(item.hasRole && !!item.menuUrl) {
            whiteList.push(item.menuUrl);
          }
          if(item.hasRole && !!item.sysMenuList) {
            this.getWhiteList(item.sysMenuList,whiteList);
          }
        })
      }
    },
    // 查询菜单目录
    getMenu(){
      this.$ajax({
        url: '/hxclass-management/getRouters',
      }).then(res=>{
        if (res.code == 200 && res.success) {
          let obj = this.$store.state.userInfo
          obj.menuList = res.data
          // 获取白名单列表
          let whiteList = [];
          this.getWhiteList(res.data,whiteList);
          localStorage.setItem('whiteList', JSON.stringify(whiteList));
          this.$store.commit("updateUserInfo", obj);  // 将权限菜单 存放起来
          // 找出权限菜单的第一条路径
          let firstMenu = []
          let firstUrl = ''
          for(let i=0; i < res.data.length; i++ ){
            // if(res.data[i].hasRole){
              if(res.data[i].menuType){
                firstUrl = res.data[i].menuUrl
              } else {
                firstMenu = res.data[i].sysMenuList
              }
              break
            // }
          }

          this.$message.success('配置成功，已登录！');
          this.isPower = false
          if(firstMenu.length){
            for(let j=0; j < firstMenu.length; j++ ){
              // if(firstMenu[j].hasRole){
              if(firstMenu[j].menuUrl){
                this.$router.push(firstMenu[j].menuUrl);
              } else {
                this.$router.push(firstMenu[j].sysMenuList[0].menuUrl);
              }
              break
              // }
            }
          } else {
            this.$router.push(firstUrl);
          }
        }
      })
    },
    
    // 监听键盘，按enter进入
    escEvent(e){
      if(e.code == 'Enter' || e.code == 'NumpadEnter'){
        this.onSaveCheck()
      }
    },

    // 获取cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
      var start = document.cookie.indexOf(key + '=')
      if (start !== -1) {
          start = start + key.length + 1
          var end = document.cookie.indexOf(';', start)
          if (end === -1) end = document.cookie.length
          return unescape(document.cookie.substring(start, end))
      }
      }
      return ''
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = cName + '=' + decodeURIComponent(value) +
      ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.$store.commit('clearUserInfo')
    // 在页面加载时从cookie获取登录信息
    let username = this.getCookie("username")
    // let password = Base64.decode(this.getCookie("password"))
    let password = this.getCookie("password") ? JSON.parse(this.$AES.decode_data(this.getCookie("password"))) : ''
    if(username){
      this.form.username = username
      this.form.password = password
      this.remenberStatus = true
    }
    this.funCode();
    document.addEventListener('keyup', this.escEvent)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escEvent)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.login{
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  background: #f6f6fc;
  display: flex;
  .leftBack{
    flex: 1;
    background-image: url('~@/assets/image/loginBack.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    img{
      width: 100%;
    }
  }
  .verificationCode{
    display: flex;
    .ant-input{
      flex: 1;
    }
    p,img{
      background: #F6F6FC;
      border-radius: 3px;
      margin-left: 25px;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      width: 111px;
      text-align: center;
      cursor: pointer;
    }
  }
  .rightContent{
    width: 46%;
    background: #FFFFFF;
    border-radius: 40px 0px 0px 0px;
    padding: 36px;
    position: relative;
    .logo{
      width: 149px;
    }
    .title{
      margin-top: 12px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 19px;
    }
  }
  >.Backlogin{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  >.center{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    >
    .formData{
      width: 338px;
      max-width: 580px;
      background-color: rgba(255, 255, 255, 0.15);
      padding: 0 80px 80px;
      box-sizing: border-box;
      display: block;
      >h4{
        text-align: center;
        color: #fff;
        height: 100px;
        font-size: 24px;
        line-height: 100px;
      }
      >div{
        margin-bottom: 20px;
        &:last-child{
          margin: 0;
        }
      }
    }
  }
}
.formData{
  width: 338px;
  max-width: 580px;
  background-color: rgba(255, 255, 255, 0.15);
  // margin: 0 auto;
  // margin-top: 108px;
  // box-sizing: border-box;
  // display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  >.loginTitle{
    font-size: 23px;
    font-weight: bold;
    color: #333333;
    line-height: 27px;
    margin-bottom: 42px;
    text-align: center;
  }
  >h4{
    text-align: center;
    color: #fff;
    height: 100px;
    font-size: 24px;
    line-height: 100px;
  }
  >div{
    margin-bottom: 20px;
    &:last-child{
      margin: 0;
    }
    .ant-input{
      background: #F6F6FC;
      border: 0;
    }
  }
  .sms-inner{
    display: flex;
    align-items: center;
    .send-btn{
      min-width: 130px;
      text-align: center;
      .btn{
        color: #15b7dd;
        cursor: pointer;
      }
    }
  }
}
.loginImg{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 20px;
  img{
    width: 250px;
    filter: grayscale(100%) brightness(200%);
  }
}
</style>
